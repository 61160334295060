import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useForm, FormProvider, useFormState } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

// components
import Aux from "hoc/auxiliar";
import RoleForm from 'components/RoleForm';
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// helpers
import makeDefaultValues from "helpers/makeDefaultValues";
import { permissionsDefaultValues } from 'helpers/permissions';

// domain
import fields from 'domain/forms/formRoleInitialValues';
import schemaValidation from 'domain/validation/yup/formRoleValidation';

// services
import { getRoleById, updateRole } from 'services/roles';

// model
import RoleModel from 'models/Role';

// style
import useStyles from "./styles";
import "react-datepicker/dist/react-datepicker.css";

const EditRole = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [formData, setFormData] = useState(null)
  const history = useHistory()

  const defaultValues = makeDefaultValues({
    fields,
    data: {
      permissions: permissionsDefaultValues()
    },
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });

  const { isSubmitting } = useFormState({ control: methods.control });

  const onSubmit = async (data) => {
    try {
      const permissionsArray = Object.keys(data.permissions).filter((permission) => {
        if(data.permissions[permission]) {
          return permission.toString();
        }
      });
      const roleModel = new RoleModel(
        data.role,
        permissionsArray,
        data.name,
        data.login_redirect
      );
      const response = await updateRole(roleModel);
      if (response.status !== 200) { 
        throw new Error('Erro ao editar permissões');
      }
      toast.success('Perfil editado com sucesso');
      history.push('/gofurther/role/list-roles');
    } catch (error) {
      console.log('error', error)
      toast.error("Erro ao atualizar perfil. Verifique com o suporte");
    }
  };

  const fetchRoleById = async (roleId) => {
    try {
      const response = await getRoleById(roleId);
      const { data } = response.data;
      // Seta o nome da role para recriá-la com o mesmo nome de criação
      methods.setValue("role", data[0].role);
      methods.setValue("login_redirect", data[0].login_redirect);
      setFormData(data)
    } catch (error) {
      toast.error(
        "Erro ao buscar dados do perfil. Verifique com o suporte",
      );
    };
  };

  useEffect(() => {
    if (hasPermission({ scopes: ['can_edit_user_role'] })) {
      fetchRoleById(id);
    }
  }, [id]);

  if (!defaultValues) {
    return "...carregando";
  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_edit_user_role']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Typography variant='h1' className={classes.title}>Cadastro de perfil</Typography>
                    {formData && 
                      <input type="hidden" {...methods.register('role')} name="role" /> && 
                      <RoleForm formData={formData} />}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent='flex-end' spacing={2}>
                        <Grid item container justifyContent='flex-end' xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <BackButton className={classes.backButton} />
                            </Box>
                          </Grid>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Button
                                fullWidth
                                variant='contained'
                                type='submit'
                                disabled={isSubmitting}
                                className={classes.btConfirm}
                              >
                                Atualizar
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default EditRole;
