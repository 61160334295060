
import * as amplitude from "@amplitude/analytics-browser"

export const handleAmplitudeEvent = async (eventType, eventProperties) => {


  const eventPropertiesWithLocation = {
    ...eventProperties,
    current_url: window.location.href
  }

    amplitude.track(
      eventType,
      eventPropertiesWithLocation
    )

    await amplitude.flush().promise;
  }