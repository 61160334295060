import React from "react";
import { Grid } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

import Table from "../../components/Table";
import { makeColumns } from "./tableColumns";

export default function TooltipChart({ data }) {
  const columns = makeColumns(data);
  const tableInfo = (
    <TableContainer
      component={Paper}
      style={{ width: "100%", boxSizing: "border-box" }}
    >
      <Table
        columns={columns}
        data={[data]}
        options={{
          search: false,
          print: false,
          download: false,
          viewColumns: false,
          filter: false,
          pagination: false,
        }}
        toolbarNone={true}
        paddingBottom="0px"
        tableHeight={"auto"}
        tableName="tooltip-chart"
      />
    </TableContainer>
  );

  return (
    <Grid container spacing={0} justifyContent="space-between">
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        {tableInfo}
      </Grid>
    </Grid>
  );
}
