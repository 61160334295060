import React from "react";
import { Typography, Tooltip } from "@mui/material";
import { styled, makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Rating from '@mui/material/Rating';
import StarIcon from '@material-ui/icons/Star';

import { makeValueStars } from "helpers/functions";

const StyledEmptyStar = styled(StarIcon)(() => ({
  color: '#00000042',
  fontSize: '18px !important'
}));

const makeColumns = (size, isPenalizer) => {
  const classes = makeStyles((theme) => ({
    textBold: {
      fontWeight: "bold",
    },
    primary: {
      color: theme.palette.primary.main,
    },
  }));

  const defaultColumns = [
    {
      name: "driverName",
      label: size.mobile ? " " : "Motorista",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" className={classes.textBold} data-cy="thMotorista">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "achievedMileage",
      label: "Km rodado",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" data-cy="thKmRodado">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "consumption",
      label: "Consumo (l)",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" data-cy="thConsumoL">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "consumptionAvg",
      label: "Média computador de bordo",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" data-cy="thMedia">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "monthScore",
      label: "Nota geral",
      options: {
        customBodyRender: ({ score: value }) => (
          <Typography variant="body2" data-cy="thNotaGeral">{value}</Typography>
        ),
      },
    },
    {
      name: "idleScore",
      label: "Motor ligado parado",
      options: {
        customBodyRender: ({ score: value }) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thMotorLigadoParado">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "extraEconomicScore",
      label: "Início da faixa verde",
      options: {
        customBodyRender: ({ score: value }) => {
          return (
            <Tooltip title={`Nota: ${value}`}data-cy="thInicioFaixaVerde">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "leverageScore",
      label: "Aproveitamento de embalo",
      options: {
        customBodyRender: ({ score: value }) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thAproveitamentoEmbalo">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "powerScore",
      label: "Acelerando acima do verde",
      options: {
        customBodyRender: ({ score: value }) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thAcelerandoAcimaVerde">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "cruiseControlScore",
      label: "Piloto automático",
      options: {
        customBodyRender: ({ score: value }) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thPilotoAutomatico">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "defensiveScore",
      label: "Excesso de velocidade",
      options: {
        customBodyRender: ({ score: value }) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thExcessoVelocidade">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "throttlePressureScore",
      label: "Pressão do acelerador",
      options: {
        filter: isPenalizer.throttlePressureScore,
        customBodyRender: ({ score: value }) => {
          return isPenalizer.throttlePressureScore ? (
            <Tooltip title={`Nota: ${value}`}>
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          ) : (
            <Typography variant="body2" data-cy="thPressaoDoAcelerador">Não avaliado</Typography>
          );
        },
      },
    },
    {
      name: "id",
      label: "driverId",
      options: {
        display: false,
        searchable: false,
        filter: false,
        viewColumns: false,
        hideColumn: true,
      },
    },
    /* {
      name: "id",
      label: "Download",
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      options: {
        customBodyRender: (value, meta) => {
          return (
            <IconButton
              color="primary"
              variant="contained"
              aria-label="Download"
              component="span"
              style={{ alignItems: "center" }}
              onClick={() => {
                handleExportByDriver(value, meta.rowData[0]);
              }}
            >
              <GetAppIcon />
            </IconButton>
          );
        },
      },
    } */
  ];

  return defaultColumns;
};

export default makeColumns;
