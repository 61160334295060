import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { toast } from "react-toastify";
import dayjs from "dayjs";

// store
import { useAppSelector, useAppDispatch } from "store";
import { useSelect } from "context/useSelect";
import { clearDriverRecalculation } from "store/features/recalculationSlice";

// components
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import Calendar from "components/Calendar";
import ModalRecalculationDriver from "components/ModalRecalculationDriver";
import Table from "components/Table";
import Widget from "components/Widget/Widget";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// services
import { getAllRecalculations, getRecalculationById } from "services/recalculationDrivers";
import { getDrivers } from "services/driver";

// styles
import useStyles from "./styles";
import { makeColumns } from "./tableColumns";

const ListRecalculationDriver = () => {
  const dispatch = useAppDispatch();
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const { driversRecalculations } = useAppSelector((state) => state.recalculation);
  const classes = useStyles();
  const history = useHistory();
  const { drivers, setDrivers } = useSelect();
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const reloadTimer = useRef();

  const dbDateTemplateFormat = "YYYY-MM-DDTHH:mm:ssZZ";
  const humanDateTemplateFormat = "DD/MM/YYYY HH:mm";
  const humanPeriodDateTemplateFormat = "DD/MM/YYYY";

  const [selectedDates, setSelectedDates] = useState({
    initialDate: dayjs().format("YYYY-MM-DDT00:00:00ZZ"),
    finalDate: dayjs().format("YYYY-MM-DDT23:59:59ZZ"),
  });

  const [openModal, setOpenModal] = useState(false);
  const [recalculations, setRecalculations] = useState([]);

  const fetchRecalculation = async () => {
    try {
      const response = await getAllRecalculations(selectedDates.initialDate, selectedDates.finalDate, currentCustomer);
      if (response.data.success) {
        let data = response.data.data;
        const responseData = data.map((item) => ({
          ...item,
          user: item.user || '',
          process_date: dayjs(item.process_date).format(humanDateTemplateFormat),
          period: `${dayjs(item.start_date).format(humanPeriodDateTemplateFormat)} até ${dayjs(item.end_date).format(humanPeriodDateTemplateFormat)}`,
        }));
        setRecalculations(responseData);
      } else {
        setRecalculations([]);
      }
    } catch (err) {
      console.log(err);
      setRecalculations([]);
      toast.error(
        "Erro ao carregar lista de motoristas. Entre em contato com o suporte.",
      );
    }
  };

  const fetchDrivers = useCallback(async () => {
    try {
      const response = await await getDrivers(currentCustomer);
      if (response.status !== 200) {
        throw new Error("Error fetching drivers");
      }
      // STC-1618 - removendo motoristas excluidos
      const driversData = response.data?.drivers ? response.data.drivers.filter((item) => item.deletedAt === "1980-01-01T00:00:00Z"): [];
      setDrivers(driversData);
    } catch (err) {
      console.log('err', err);
      setDrivers([]);
      toast.error(
        "Erro ao carregar lista de motoristas. Entre em contato com o suporte.",
      );
    }
  }, [currentCustomer, setDrivers])

  const handleSelectDate = (initialDate, finalDate) => {
    setSelectedDates({
      initialDate: dayjs(initialDate).format(dbDateTemplateFormat),
      finalDate: dayjs(finalDate).format(dbDateTemplateFormat),
    });
  };

  const handleRecalculation = (value) => {
    history.push(`/gofurther/driver/recalculation/${value}`)
  };

  const handleOpenModal = () => {
    setOpenModal(true)
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    fetchRecalculation();
  };

  const columns = makeColumns({
    showRecalculation: handleRecalculation,
  });

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ['can_recalculate_drivers'] })) {
      fetchDrivers();
    }
  }, [currentCustomer, fetchDrivers]);

  useEffect(() => {
    fetchRecalculation();
  }, [selectedDates]);

  useEffect(() => {
    if (driversRecalculations.openRecalcIds?.length > 0) {
      const recalcIds = driversRecalculations.openRecalcIds;
      
      const interval = setInterval(async () => {
        try {
          const response = await getRecalculationById(recalcIds, currentCustomer);

          if (response.data.success) {
            const data = response.data.data;
            let maintainReload = 0;
            let newRecalculations = [];
            
            data.map((item) => {
              if (item.status !== "FINISHED") {
                maintainReload++;
                newRecalculations = recalculations.find(v => v.recalcId === item.recalcId).status = item.status;
              }
            });

            if (newRecalculations.length > 0) {
              setRecalculations(newRecalculations);
            }

            if (maintainReload === 0) {
              clearInterval(interval);
              dispatch(clearDriverRecalculation());
            }
          }
        } catch (error) {
          console.error('Error fetching recalculation data', error);
        }
      }, 5000);
      
      return () => clearInterval(interval);
    }
  }, [driversRecalculations]);

  const renderTable = useCallback(() => (
    <Table
      columns={columns}
      data={recalculations}
      options={{ rowsPerPage }}
      setRowsPerPage={setRowsPerPage}
      tableName='list-recalculations-drivers'
    />
  ), [columns, recalculations, rowsPerPage]);

  return (
    <Aux>
      <PermissionsGate scopes={['can_recalculate_drivers']}>
        <Grid container spacing={4}>
          <ModalRecalculationDriver
            open={openModal}
            handleClose={handleCloseModal}
            drivers={drivers}
          />
          <Grid item xs={12} className={classes.tableContent}>
            <Grid container justifyContent="flex-end" className={classes.toolbar}>
              <Grid item xl={8} lg={8} md={6} sm={12} xs={12} data-cy="divPeriodo">
                <Calendar
                  selectedDates={selectedDates}
                  handleCalendar={handleSelectDate}
                />
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12} className={classes.btItem}>
                <BackButton className={classes.backButton} />
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.btnNew}
                  onClick={handleOpenModal}
                  data-cy="buttonNovoRecalculo"
                >
                  Novo Recálculo
                </Button>
              </Grid>
            </Grid>
            <Widget
              disableWidgetMenu
              title="Recalcular dados de bonificação dos motoristas"
              subtitle="Selecione um período diferente caso queira ver recálculos mais antigos"
              subtitleClass={classes.subtitle}
            >
              {recalculations && renderTable()}
            </Widget>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default ListRecalculationDriver;
