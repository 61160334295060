import React from "react";
import { Grid } from "@material-ui/core";
import DeviceValidationCard from 'components/DeviceValidationCard';
import { translateTitle, translateDescription } from "./validationItems";
import dayjs from "dayjs";

const DeviceValidationCards = ({ validations, deviceStatus }) => {
  const formatDate = "DD/MM/YY HH:mm";

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} md={4}>
        <DeviceValidationCard
          title={deviceStatus?.engine_on ? "Motor ligado" : "Motor desligado"}
          status={deviceStatus?.engine_on}
          description={`Últimos dados recebidos: ${deviceStatus?.last_communication_datetime !== "" ? dayjs(deviceStatus?.last_communication_datetime).format(formatDate) : '-'}`}
        />
      </Grid>
      {Object.entries(validations).map(([key, value]) => (
        <Grid item xs={12} sm={4} md={4} key={key}>
          <DeviceValidationCard
            title={translateTitle(key)}
            status={value.status}
            description={translateDescription(value.description[0])}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DeviceValidationCards; 
