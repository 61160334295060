import {
  mdiAccountStar,
  mdiClipboardAccount,
  mdiCompare,
  mdiHammerScrewdriver,
  mdiMoleculeCo2,
  mdiShieldLinkVariantOutline,
  mdiTableCog,
  mdiTruckFast,
  mdiViewDashboard,
  mdiBadgeAccountOutline,
  mdiMapMarkerRadius,
  mdiChartAreaspline,
  mdiGasStation,
  mdiBellOutline,
} from "@mdi/js";
import { hasPermission } from "components/PermissionsGate";

export function getMenuStructure(permissions) {
  if (!permissions) {
    return [];
  }

  const bonusMenu = [
    {
      permission: "can_view_bonus",
      id: "Bonus",
      label: "Premiação",
      link: "/gofurther/bonus/follow",
      icon: mdiAccountStar,
    },
  ];

  const co2Menu = [
    {
      permission: "can_view_co2",
      id: "CO2",
      label: "Emissão de C0²",
      link: "/gofurther/c02-emission",
      icon: mdiMoleculeCo2,
    },
  ];

  const dashboardMenu = [
    {
      permission: "can_view_dashboard",
      id: "Fleet",
      label: "Frota",
      link: "/gofurther/dashboard/general",
      icon: mdiTruckFast,
      isMain: true,
    },
    {
      permission: "can_view_dashboard",
      id: "Dashboard",
      label: "Análise individual",
      link: "/gofurther/dashboard/general",
      icon: mdiViewDashboard,
    },
    {
      permission: "can_view_consolidated",
      id: "Consolidated",
      label: "Minha frota",
      link: "/gofurther/consolidated",
      icon: mdiTruckFast,
    },
    {
      permission: "can_view_comparison",
      id: "Comparison",
      label: "Comparativo de veículos",
      link: "/gofurther/dashboard/comparison",
      icon: mdiCompare,
    },
    {
      permission: "can_view_evolutive",
      id: "Evolutive",
      label: "Evolutivo",
      link: "/gofurther/dashboard/evolutive",
      icon: mdiChartAreaspline,
    }
  ];

  const operationMenu = [
    {
      permission: "can_view_operations",
      id: "Maintenance",
      label: "Gerenciamento",
      link: "/gofurther/maintenance/operations",
      icon: mdiHammerScrewdriver,
      isMain: true,
    },
    {
      permission: "can_view_drivers",
      id: "Drivers",
      label: "Motoristas",
      link: "/gofurther/driver/all-drivers",
      icon: mdiClipboardAccount,
    },
    {
      permission: "can_view_operations",
      id: "Operations",
      label: "Grupo de veículo",
      link: "/gofurther/maintenance/operations/operationList",
      icon: mdiTableCog,
    },
    {
      permission: "can_view_linkdrivers",
      id: "Linkdrivers",
      label: "Vínculo veículo / motorista",
      link: "/gofurther/maintenance/linkdrivers",
      icon: mdiShieldLinkVariantOutline,
    },
  ];

  const liveMapMenu = [
    {
      permission: "can_view_liveMap",
      id: "LiveMap",
      label: "Mapa ao vivo",
      link: "/gofurther/liveMap",
      icon: mdiMapMarkerRadius,
      isMain: true,
      badge: true,
    }
  ];
  
  const dieselMenu = [
    {
      permission: "can_view_dieselControl_menu",
      id: "DieselControl",
      label: "Gestão do nível de combustível",
      link: "/gofurther/dieselControl",
      icon: mdiGasStation,
      isMain: true,
      badge: true,
    },
    {
      permission: "can_view_dieselActualControl_menu",
      id: "DieselControl",
      label: "Nível do tanque atual",
      link: "/gofurther/currentDieselControl",
      icon: mdiGasStation,
    },
    {
      permission: "can_view_dieselControl_menu",
      id: "DieselControl",
      label: "Histórico",
      link: "/gofurther/dieselControl",
      icon: mdiGasStation,
    },
  ];

  const driversMonitoringMenu = [
    {
      permission: "can_view_drivers_follow",
      id: "DriversMonitoring",
      label: "Motorista",
      link: "/gofurther/driversMonitoring",
      icon: mdiBadgeAccountOutline,
    },
  ];

  const notificationMenu = [
    {
      permission: "can_view_notification_history",
      id: "NotificationHistory",
      label: "Notificações",
      link: "/gofurther/notificationhistory",
      icon: mdiBellOutline,
      isMain: true,

    },
  ];

  const countPermissions = (permissionsArray) => {
    let count = 0;
    permissions.forEach((scope) => {
      if (permissionsArray.includes(scope.permission)) {
        count++;
      }
    });
    return count;
  };

  const getChildrenMenu = (item) => {
    return {
      id: item.id,
      label: item.label,
      link: item.link,
      icon: item.icon,
      isChildren: true,
    };
  };

  const getMainMenu = (item) => {
    return {
      id: item.id,
      label: item.label,
      link: item.link,
      icon: item.icon,
    };
  };

  let structure = [];

  if (hasPermission({ scopes: [bonusMenu[0].permission] })) {
    structure.push(bonusMenu[0]);
  }

  if (hasPermission({ scopes: [co2Menu[0].permission] })) {
    structure.push(co2Menu[0]);
  }

  if (hasPermission({ scopes: [driversMonitoringMenu[0].permission] })) {
    structure.push(driversMonitoringMenu[0]);
  }

  const dashboardPermissions = dashboardMenu.map((obj) => obj.permission);

  if (hasPermission({ scopes: dashboardPermissions })) {
    const total = countPermissions(dashboardPermissions);

    // menu com filhos
    if (total > 1) {
      let structureWithChildren = [];

      dashboardMenu.forEach((item) => {
        if (!item.isMain && hasPermission({ scopes: [item.permission] })) {
          structureWithChildren.push(getChildrenMenu(item));
        }
      });

      let mainItem = dashboardMenu.filter((elm) => elm.isMain)[0];

      mainItem.children = structureWithChildren;

      structure.push(mainItem);
    } else {
      // menu sem filhos
      dashboardMenu.forEach((item) => {
        if (!item.isMain && hasPermission({ scopes: [item.permission] })) {
          structure.push(getMainMenu(item));
        }
      });
    }
  }

  if (hasPermission({ scopes: [liveMapMenu[0].permission] })) {
    structure.push(liveMapMenu[0]);
  }

  const operationsPermissions = operationMenu.map((obj) => obj.permission);

  if (hasPermission({ scopes: operationsPermissions })) {
    let total = countPermissions(operationsPermissions);

    // menu com filhos
    if (total > 1) {
      let structureWithChildren = [];

      operationMenu.forEach((item) => {
        if (!item.isMain && hasPermission({ scopes: [item.permission] })) {
          structureWithChildren.push(getChildrenMenu(item));
        }
      });

      let mainItem = operationMenu.filter((elm) => elm.isMain)[0];

      mainItem.children = structureWithChildren;

      structure.push(mainItem);
    } else {
      // menu sem filhos
      operationMenu.forEach((item) => {
        if (!item.isMain && hasPermission({ scopes: [item.permission] })) {
          structure.push(getMainMenu(item));
        }
      });
    }
  }

  if (hasPermission({ scopes: [dieselMenu[0].permission] })) {
    const dieselPermissions = dieselMenu.map((obj) => obj.permission);
    const total = countPermissions(dieselPermissions);

    if (total > 0) {
      let structureWithChildren = [];
      dieselMenu.forEach((item) => {
        if (!item.isMain && hasPermission({ scopes: [item.permission] })) {
          structureWithChildren.push(getChildrenMenu(item));
        }
      });
      let mainItem = dieselMenu.filter((elm) => elm.isMain)[0];
      mainItem.children = structureWithChildren;
      structure.push(mainItem);
    } else {
      dieselMenu.forEach((item) => {
        if (!item.isMain && hasPermission({ scopes: [item.permission] })) {
          structure.push(getMainMenu(item));
        }
      });
    }
  }

  if (hasPermission({ scopes: [notificationMenu[0].permission] })) {
    structure.push(notificationMenu[0]);
  }

  return structure;
}
