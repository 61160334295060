import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import fields from 'domain/forms/customRewardGroup';
import { rewardTypeOptions } from 'domain/selectOptions';
import schemaValidation from 'domain/validation/yup/customRewardGroup';
import makeDefaultValues from 'helpers/makeDefaultValues';
import { getIndicatorFilterType } from 'helpers/indicators';
import IndicatorModel from 'models/Indicator';
import RewardGroupModel from 'models/RewardGroup';
import { updateOperationType } from 'services/operationType';
import { updateIndicators } from 'services/indicators';
import BackButton from 'components/BackButton';
import Custom from '../components/custom';
import Aux from 'hoc/auxiliar';
import useStyles from './styles';
import { useCallback } from 'react';
import { hasPermission } from 'components/PermissionsGate';
import { checkAndWarnWeights } from 'helpers/checkAndWarnWeights';

const EditCustom = (props) => {
  const classes = useStyles();
  const { id } = props;
  const history = useHistory();
  const { operations } = props;
  const customer = localStorage.getItem('customers')

  const defaultValues = makeDefaultValues({
    fields,
    data: {
      customIndicatorsList: [
        {
          conduction: "",
          idealPercentage: "",
          acceptablePercentage: "",
          weight: "",
          id: "x-s",
        },
      ],
      penalizingCustomIndicatorsList: [
        {
          conduction: "",
          idealPercentage: "",
          acceptablePercentage: "",
          weight: "",
          penalizing: true,
          id: "penalizingCustomIndicatorsList",
        },
      ],
      penalizingCustomControll: [],
      customControll: [],
      penalty_score: ''
    },
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
    mode: 'onChange',
  });

  const resetForm = () => {
    methods.setValue("name", "");
    methods.setValue("customControll", []);
    methods.setValue("penalizingCustomControll", []);
  };

  const onSubmit = async () => {
    const result = await methods.trigger([
      "name",
      "rewardType",
      "customControll",
      "penalizingCustomControll"
    ]);

    if (result) {
      try {
        const data = methods.getValues();
        if (checkAndWarnWeights(data?.customControll)) {
          toast.warning('A soma dos pesos dos indicadores de cada quadro deve ser 100.');

          return;
        } else if (checkAndWarnWeights(data?.penalizingCustomControll)) {
          toast.warning('A soma dos pesos dos penalizadores de cada quadro deve ser 100.');

          return;
        }
        const rewardGroup = new RewardGroupModel({
          name: data.name,
          type: data.rewardType.value,
          description: "",
          penalty_score: Number(data.penalty_score),
          customer_id: Number(customer)
        });
        const responseOperationType = await updateOperationType({ id: id, data: rewardGroup });
        if (responseOperationType?.code !== 200) {
          throw new Error("Erro ao atualizar a operação");
        }
        let indicatorsList = [...data.customControll, ...data.penalizingCustomControll];
        const indicators = indicatorsList.map(
          (ind) =>
            new IndicatorModel({
              id: ind.id,
              lowerLimit: ind.acceptablePercentage,
              upperLimit: ind.idealPercentage,
              weight: ind.weight,
              requirementLevel: ind.requirement_level,
              penalizing: ind.penalizing || false
            }),
        );

        const responseUpdateIndicators = await updateIndicators({
          id: id,
          data: { indicators },
        });

        if (responseUpdateIndicators?.code !== 200) {
          throw new Error("Erro na edição dos indicadores");
        }
        toast.success("Grupo editado com sucesso!");
        history.push("/gofurther/rewardGroup/list-rewardGroup");
        resetForm();
      } catch (err) {
        toast.error(err.message);
        console.log(err);
      }
    }
  };

  const fetchOperationTypeDetails = useCallback(async () => {
    try {
      let { highControll, mediumControll, lowControll, customControll, penalizingHighControll, penalizingMediumControll, penalizingLowControll, penalizingCustomControll } = getIndicatorFilterType(operations.indicators);
      methods.setValue('highControll', highControll);
      methods.setValue('mediumControll', mediumControll);
      methods.setValue('lowControll', lowControll);
      methods.setValue('penalizingHighControll', penalizingHighControll);
      methods.setValue('penalizingMediumControll', penalizingMediumControll);
      methods.setValue('penalizingLowControll', penalizingLowControll);
      methods.setValue('customControll', customControll);
      methods.setValue('penalizingCustomControll', penalizingCustomControll);
      methods.setValue('name', operations.name);
      methods.setValue('penalty_score', operations.penalty_score || '');
      methods.setValue(
        'rewardType',
        rewardTypeOptions.find(
          (opType) => opType.value === operations.type,
        ),
      );
    } catch (err) {
      toast.error("Erro ao carregar grupo de bonificação");
      console.log(err);
    }
  }, [methods, operations]);

  useEffect(() => {
    fetchOperationTypeDetails()
  }, [fetchOperationTypeDetails, operations])

  return (
    <Aux>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ mt: 4 }}>
                  <Typography className={classes.explanation}>
                    * Os campos marcados com o asterisco (*) são aqueles que precisam obrigatoriamente ser preenchidos.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.reward}>
                <Custom />
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <BackButton className={classes.backButton} />
                </Box>
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    className={classes.btConfirm}
                    disabled={hasPermission({ scopes: ['can_view_operations_without_edit'] })}
                  >
                    Salvar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Aux>
  );
};

export default EditCustom;
