import * as yup from "yup";

export default yup.object().shape({
  brand: yup
  .string()
  .nullable()
  .required("Campo obrigatório"),
  identification: yup.string().required("Campo obrigatório"),
  simcard: 
    yup
    .string()
    .transform((value) => {
      return value.replace(/[^0-9]/g, "");
    })
    .required("Campo obrigatório"),
  status: yup
  .number()
  .typeError('Campo obrigatório')
  .nullable()
  .required("Campo obrigatório"),
});
