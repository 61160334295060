import React, { useState } from 'react';
import {
  Card,
  Typography,
  Grid,
  Box
} from '@mui/material';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import { Button, Dialog, DialogContent, DialogContentText } from '@mui/material';
import dayjs from 'dayjs';
import { mdiBorderColor, mdiCalendarCheckOutline, mdiCalendarClockOutline, mdiCalendarRemoveOutline, mdiLaunch, mdiTrashCanOutline } from '@mdi/js';
import Icon from '@mdi/react';

// assets
import GoBraxLogo from "images/gobrax-header.png";

// theme
import colors from 'themes/gobrax';

// services
import { cancelNotificationById, getHistoricNotifications, getNotificationById } from 'services/notifications';

// components
import PermissionsGate from 'components/PermissionsGate';
import HistoryModal from './history-modal';
import Badge from './notification-badge';

// styles
import useStyles from "../styles";

const NotificationCard = ({ id, title, subtitle, body, created_by, username, created_at, publish_at, type, fromGobrax, statusType, status, platform, has_history = false, updated_at, getNotifications }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [historiesToShow, setHistoriesToShow] = useState([]);
  const formatDefault = 'DD/MM/YYYY HH:mm';

  const renderHtml = (htmlString) => {
    return { __html: htmlString };
  }

  const renderLabelStatusType = (statusType, status) => {
    if (statusType === 'created') {
      return 'Criada'
    }
    if (statusType === 'updated' && status === 'canceled') {
      return 'Cancelada'
    }
    if (statusType === 'updated') {
      return 'Editada'
    }
    return 'Criada'
  }

  const Status = () => {
    let statusColor = "";
    let statusText = "";
    let statusIcon;
    if (status === "published" || status === "viewed") {
      statusColor = "#40D81B"
      statusText = "Enviada"
      statusIcon = mdiCalendarCheckOutline;
    } else if (status === "scheduled") {
      statusColor = "#0D69D7"
      statusText = "Agendada"
      statusIcon = mdiCalendarClockOutline
    } else if (status === "canceled") {
      statusColor = "#E2211C"
      statusText = "Cancelada"
      statusIcon = mdiCalendarRemoveOutline
    } else {
      statusColor = "#CCC"
      statusText = "Desconhecido"
      statusIcon = mdiCalendarRemoveOutline
    }

    return (
      <Box sx={{
        padding: '2px 4px',
        color: `${statusColor}`,
        fontWeight: 'medium',
        fontSize: 14,
        alignItems: 'center',
        flexWrap: 'nowrap',
        display: 'flex'
      }}>
        <Icon path={statusIcon} size={1} style={{ marginRight: 4 }} />
        {statusText}
      </Box>
    )
  }


  const handleOpenHistory = async (notificationId) => {
    try {
      const response = await getHistoricNotifications(notificationId);
      if (response.status === 200) {

        const sortedArray = response.data.data.sort((a, b) => {
          const firstDate = new Date(a.updated_at).getTime();
          const secondDate = new Date(b.updated_at).getTime();
          return secondDate - firstDate;
        })

        setHistoriesToShow(sortedArray);


      }
    } catch (error) {
      console.log(error)
    }
    finally {
      setIsHistoryOpen(true);
    }
  }

  const handleContentNotificationById = async (notificationId) => {
    try {
      const response = await getNotificationById(notificationId);

      if (response.status === 200) {
        return response.data.data
      } else {
        return null
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleNavigate = async (id, type) => {
    if (type === 'edit') {
      const publishDate = new Date(publish_at);
      const now = new Date();

      const differenceInMilliseconds = publishDate - now;
      const fiveMinutesInMilliseconds = 5 * 60 * 1000;

      if (differenceInMilliseconds < fiveMinutesInMilliseconds) {
        setModalOpen(true);
        return;
      }
    }
    const content = await handleContentNotificationById(id);
    history.push(`/gofurther/managenotification`, {
      publishAt: publish_at,
      notificationId: id,
      notificationType: type,
      content: content
    })
  }

  const handleCancel = async (notificationId, user, publishAt) => {

    const publishDate = new Date(publishAt);
    const now = new Date();

    const differenceInMilliseconds = publishDate - now;
    const fiveMinutesInMilliseconds = 5 * 60 * 1000;

    if (differenceInMilliseconds < fiveMinutesInMilliseconds) {
      toast.error('Não é possível cancelar uma notificação faltando menos de 5 minutos.')
      return;
    }

    try {
      const response = await cancelNotificationById(notificationId, user);
      if (response.status === 200) {
        getNotifications();
        toast.success('Notificação cancelada com sucesso!')
      } else {
        toast.error('Algo estranho aconteceu, contate o suporte.')
      }
    } catch (error) {
      toast.error('Algo estranho aconteceu, contate o suporte.')
    } finally {
      setIsOpen(false);
    }
  }

  return (
    <>
      <Card sx={{ height: 'auto', width: '100%', border: `2px solid ${colors.notification[type].borderColor}`, boxShadow: '0px 1px 6px 0px #0000000F', marginBottom: 2, padding: '4px 16px', borderRadius: 3, position: 'relative', flexDirection: 'column' }}>
        <Grid container style={{ justifyContent: 'space-between', width: 'full', flexWrap: 'nowrap', alignItems: 'baseline', marginTop: 8 }}>
          <Grid item xs={9} style={{ padding: '12px 4px' }}>
            <Typography style={{
              fontSize: 18, fontWeight: 500, color: '#000000', lineHeight: '1.2em', wordWrap: 'break-word'
            }}>{title}</Typography>
          </Grid>
          <Box style={{ gap: '8px', alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
            <Typography style={{
              fontSize: 14, fontWeight: 400, color: '#A6A6A6'
            }}>{dayjs(publish_at).format(formatDefault)}</Typography>
            <Typography style={{
              fontSize: 14, fontWeight: 400, color: '#A6A6A6', textTransform: 'capitalize'
            }}>
              ({platform})
            </Typography>
            <Badge type={type} />
            <Status />
            <Box
              sx={{
                width: '45px',
                height: '45px',
                backgroundColor: '#ffff',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '50%',
                boxShadow: '0px 1.764705777168274px 4.411764621734619px 0px #00000026',
              }}
            >
              {fromGobrax ?
                <img
                  src={GoBraxLogo}
                  alt="Logo Gobrax"
                  className={classes.logoHeader}
                  data-cy="logoGobrax"
                />
                :
                <BusinessIcon style={{ color: '#595959', marginLeft: 11 }} />
              }
            </Box>
          </Box>
        </Grid>
        <Grid item xs={9} style={{ padding: '12px 4px' }}>
          <Typography style={{
            fontSize: 16, fontWeight: '500', color: colors.palette.primary.grayDark, width: '100%', marginBottom: 4
          }}>{subtitle}</Typography>
          <div style={{ wordWrap: 'break-word' }} dangerouslySetInnerHTML={renderHtml(body)} />
          {has_history
            ? <Typography style={{ fontSize: 14, fontWeight: 400, color: '#A6A6A6', marginBottom: '18px' }}>
              {renderLabelStatusType(statusType, status)} por: {username + " em " + (dayjs(updated_at).format(formatDefault))}
            </Typography>
            : <Typography style={{ fontSize: 14, fontWeight: 400, color: '#A6A6A6', marginBottom: '18px' }}>
              Criado por: {username + " em " + (dayjs(created_at).format(formatDefault))}
            </Typography>}
        </Grid>
        <Box style={{ gap: '16px', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '12px', marginRight: '8px' }}>
          <PermissionsGate scopes={['can_view_notification_history']}>
            {has_history
              ? <Typography style={{ fontSize: 16, color: '#0D69D7', gap: 8, cursor: 'pointer' }} onClick={() => handleOpenHistory(id)} >
                {/* atualizar para handleOpenHistory */}
                <Icon path={mdiLaunch} size={1} />
                Visualizar histórico
              </Typography>
              : <></>
            }
          </PermissionsGate>
          <PermissionsGate scopes={['can_edit_notification']}>
            {status === "scheduled"
              ? <Typography style={{ fontSize: 16, color: '#0D69D7', gap: 8, cursor: 'pointer' }} onClick={() => handleNavigate(id, 'edit')}>
                <Icon path={mdiBorderColor} size={1} />
                Editar
              </Typography>
              : <></>}
          </PermissionsGate>

          <PermissionsGate scopes={['can_resend_notification']}>
            {status === "published" || status === 'viewed' || status === "canceled"
              ? <Typography
                style={{ fontSize: 16, color: '#0D69D7', gap: 8, cursor: 'pointer' }}
                onClick={() => {
                  handleNavigate(id, 'resend')
                }}>
                <Icon path={mdiBorderColor} size={1} />
                Reenviar
              </Typography>
              : <></>}
          </PermissionsGate>

          <PermissionsGate scopes={['can_cancel_notification']}>
            {status === "scheduled"
              ? <Typography style={{ fontSize: 16, color: '#E2211C', gap: 8, cursor: 'pointer' }} onClick={() => {
                setIsOpen(true)
              }}>
                <Icon path={mdiTrashCanOutline} size={1} />
                Cancelar
              </Typography>
              : <></>}
          </PermissionsGate>

        </Box>
      </Card>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        style={{ zIndex: 999 }}
        maxWidth={'xs'}
        PaperProps={{ style: { borderRadius: 24, padding: '0 24px' } }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: '#181814', fontSize: 18, fontWeight: 600, fontFamily: 'Roboto', lineHeight: '140%', marginBottom: 8 }}>
            Você tem certeza que deseja cancelar?
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" style={{ color: '#595959', fontSize: 18, fontFamily: 'Roboto', lineHeight: '140%' }}>
            Ao confirmar essa ação você não poderá desfazer ou recuperar o que foi apagado!
          </DialogContentText>
          <Grid container spacing={2} style={{ padding: 32 }}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <Button style={{ borderRadius: 16, height: 48, borderColor: '#E2211C', backgroundColor: 'white', textTransform: 'none', color: '#595959', fontSize: 16 }} onClick={() => handleCancel(id, created_by, publish_at)} variant={'outlined'} fullWidth >
                Cancelar
              </Button>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <Button style={{ borderRadius: 16, height: 48, textTransform: 'none', fontSize: 16, fontWeight: 700, color: '#181814' }} variant={'contained'} fullWidth onClick={() => setIsOpen(false)} color="secondary">
                Voltar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        style={{ zIndex: 999 }}
        maxWidth={'xs'}
        PaperProps={{ style: { borderRadius: 24, padding: '0 24px' } }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: '#181814', fontSize: 18, fontWeight: 600, fontFamily: 'Roboto', lineHeight: '140%', marginBottom: 8 }}>
            Não é possível editar a notificação
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" style={{ color: '#595959', fontSize: 18, fontFamily: 'Roboto', lineHeight: '140%' }}>
            A notificação está prestes a ser enviada ou já foi enviada e por isso não é possível editá-la.
          </DialogContentText>
          <Grid container spacing={2} style={{ padding: 32 }}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button style={{ borderRadius: 16, height: 48, textTransform: 'none', fontSize: 16, fontWeight: 700, color: '#181814' }} variant={'contained'} fullWidth onClick={() => setModalOpen(false)} color="secondary">
                Voltar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <HistoryModal
        key={id}
        open={isHistoryOpen}
        histories={historiesToShow}
        setOpen={setIsHistoryOpen}
      />
    </>
  );
};

export default NotificationCard
