import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/pt-br';
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  Input,
  Paper,
  Grid,
  Button,
  Typography
} from "@mui/material";
import { TextField } from '@mui/material'
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { daysOfWeek, monthNames } from "constants/calendar";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

// styles
import useStyles from "./styles";

dayjs.locale('pt-br');
dayjs.extend(localizedFormat);

export default function Calendar({selectedDates, handleCalendar, calendarMaxRanges, maxSpanDays}) {
  const classes = useStyles();
  const size = useWindowSize()

  const formatDate = "DD/MM/YYYY HH:mm";

  // Utilizar a Ref é obrigatório para forçar a atualização do componente e calendário (initialSettings), 
  // pois ele não é um componente criado na DOM pelo React (a biblioteca é um wrapper)
  const keyRef = useRef(Date.now());

  const [dates, setDates] = useState({
    initialDate: dayjs().format("DD/MM/YYYY 00:00"),
    finalDate: dayjs().format("DD/MM/YYYY 23:59")
  });
  const [datesMobile, setDatesMobile] = useState({
    initialDate: new Date(),
    initialTime: '00:00',
    finalDate: new Date(),
    finalTime: '23:59'
  });
  const [maxDates, setMaxDates] = useState({});
  const [helperError, setHelperError] = useState(false);
  const [ filtro,setFiltro] = useState(false);
  
  useEffect(()=>{
    const date = new Date()
    const dateFim = new Date()
    date.setHours(0,0,0)
    dateFim.setHours(23,59,59)
    setDatesMobile({
      initialDate: date,
      initialTime: '00:00',
      finalDate: dateFim,
      finalTime: '23:59'
    })
  },[])
  
  const handleSetCalendarDates = (startDate, endDate) => {
    if (startDate && endDate) {
      setDates({
        initialDate: dayjs(startDate).format(formatDate),
        finalDate: dayjs(endDate).format(formatDate)
      });
      handleCalendar(startDate, endDate);
    }
  };
  
  useEffect(() => {
    keyRef.current = Date.now();
    setMaxDates({
      ...(calendarMaxRanges?.maxDate && { maxDate: calendarMaxRanges.maxDate }),
      ...(calendarMaxRanges?.minDate && { minDate: calendarMaxRanges.minDate }),
    });
  }, [calendarMaxRanges]);
  
  function formatTimeInput(value){
    let h = value.getHours()
    if (h<10){
      h = '0' + h.toString()
    }
    let m = value.getMinutes()
    if (m<10){
      m = '0' + m.toString()
    }
    return h + ':' + m
  }

  useEffect(() => {
    setDates({
      initialDate: selectedDates.initialDate
        ? dayjs(selectedDates.initialDate).format(formatDate)
        : dayjs(new Date()).format("DD/MM/YYYY 00:00"),
      finalDate: selectedDates.finalDate
        ? dayjs(selectedDates.finalDate).format(formatDate)
        : dayjs(new Date()).format("DD/MM/YYYY 23:59")
    });
    
    if(selectedDates.initialDate !== undefined){
      setDatesMobile({
        initialDate: new Date(selectedDates.initialDate),
        initialTime: formatTimeInput(new Date(selectedDates.initialDate)),
        finalDate:  new Date(selectedDates.finalDate),
        finalTime: formatTimeInput(new Date(selectedDates.finalDate)),
      })
    }
  }, [selectedDates]);

  return (
      size.mobile?
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <Paper elevation={0} className={classes.paper2}>
          <Grid container style={{display:'flex'}}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{paddingLeft:10}}>
              <MobileDatePicker
                maxDate={dayjs(datesMobile?.finalDate)}
                InputProps={{
                  disableUnderline: true,
                }}
                slotProps={{ textField: { fullWidth: false, size:'small', variant: 'standard',  } }}
                label="Data inicial"
                value={dayjs(datesMobile?.initialDate)}
                onChange={(value)=>{
                  setDatesMobile({
                    ...datesMobile,
                    initialDate: value,
                  });

                  setFiltro(datesMobile.finalDate.valueOf() > value.valueOf())
                  setHelperError(datesMobile.finalDate.valueOf() < value.valueOf())
                }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{marginTop:-3}}>
              <TextField
                type={'time'}
                fullWidth={true}
                variant={'standard'}
                label="Hora inicial"
                value={datesMobile.initialTime}
                onChange={(event)=>{
                  const value = event.target.value;

                  const { 0: hour, 1: minute } = value.split(':');

                  datesMobile.initialDate.setHours(hour, minute);

                  setDatesMobile({
                    ...datesMobile,
                    initialTime: value,
                  });

                  setFiltro(datesMobile.finalDate.valueOf() > datesMobile.initialDate.valueOf());
                  setHelperError(datesMobile.finalDate.valueOf() < datesMobile.initialDate.valueOf());
                }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{paddingLeft:10,paddingTop:10}}>
              <MobileDatePicker
                maxDate={dayjs()}
                minDate={dayjs(datesMobile?.initialDate)}
                slotProps={{ textField: { fullWidth: false, size:'small', variant: 'standard',  } }}
                label="Data final"
                value={dayjs(datesMobile?.finalDate)}
                onChange={(value)=>{
                  setDatesMobile({
                    ...datesMobile,
                    finalDate: value,
                  });
                  setFiltro(value.valueOf() > datesMobile.initialDate.valueOf())
                  setHelperError(value.valueOf() < datesMobile.initialDate.valueOf())
                }}
              />
          </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{marginTop:-3,paddingTop:10}}>
              <TextField
                type={'time'}
                fullWidth={true}
                variant={'standard'}
                label="Hora final"
                value={datesMobile.finalTime}
                onChange={(event)=>{
                  const value = event.target.value;

                  const { 0: hour, 1: minute } = value.split(':');

                  datesMobile.finalDate.setHours(hour, minute);

                  setDatesMobile({
                    ...datesMobile,
                    finalTime: value,
                  });

                  setFiltro(datesMobile.finalDate.valueOf() > datesMobile.initialDate.valueOf());
                  setHelperError(datesMobile.finalDate.valueOf() < datesMobile.initialDate.valueOf());
                }}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{display:'flex',justifyContent:'center'}}>
              <Typography align={'center'} style={{marginTop:5}} color={'error'}>{helperError ? 'O período selecionado é inválido': ''}</Typography>
            </Grid>
          </Grid>
          </Paper>
          {filtro?
            <Grid  style={{paddingTop:10}}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={()=>{
                  handleSetCalendarDates(datesMobile.initialDate,datesMobile.finalDate)
                }}
                className={classes.buttonDetail}
              >
                Filtrar
              </Button>
             
            </Grid>:null}
        </LocalizationProvider>
    : 
    <Paper elevation={0} className={classes.paper}>
      <DateRangePicker
        key={keyRef.current}
        initialSettings={{
          startDate: dates.initialDate,
          endDate: dates.finalDate,
          ...maxDates,
          ...(maxSpanDays && { maxSpan: { days: maxSpanDays } }),
          timePicker24Hour: true,
          timePicker: true,
          locale: {
            format: "DD/MM/YYYY HH:mm",
            daysOfWeek,
            monthNames,
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            customRangeLabel: "Personalizado",
          },
          ranges: {
            'Hoje': [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()],
            'Ontem': [dayjs().subtract(1, 'days').startOf('day').toDate(), dayjs().subtract(1, 'days').endOf('day').toDate()],
            '7 Dias': [dayjs().subtract(6, 'days').startOf('day').toDate(), dayjs().endOf('day').toDate()],
            '1 Mês': [dayjs().subtract(29, 'days').startOf('day').toDate(), dayjs().endOf('day').toDate()],
          },
          alwaysShowCalendars: !size.mobile,
          showCustomRangeLabel: true,
          opens: "center"
        }}
        onApply={(_, picker) => {
          handleSetCalendarDates(picker.startDate.toDate(), picker.endDate.toDate());
        }}
        autoApply={true}
      >
        <Grid>
          <Input data-cy="inputCalendar"
            value={`${dates.initialDate} - ${dates.finalDate}`}
            onChange={(e, picker) => {
              if (picker) {
                handleSetCalendarDates(picker.startDate._d, picker.endDate._d);
              }
            }}
            className={classes.inputCalendar}
          />
        </Grid>
      </DateRangePicker>
    </Paper>
  );
}
