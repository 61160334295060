import React, { useState, useEffect } from "react";
import { useWatch, useFormState, useFormContext, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { Grid, Typography, Paper, FormHelperText, Box, Autocomplete, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

// store
import { useAppSelector } from "store";

// services
import { getCustomersById } from "services/customers";
import { getRolesByContext } from 'services/roles';

// helpers
import { cpfMask, dateMask, phoneMask, removeSpecialCharactersAndNumbersMask } from "helpers/masks";
import { makeSelectOptions } from "helpers/makeSelectOptions";
import { orderByName } from "helpers/functions";
import { getGroupedDriverRoles } from 'helpers/permissions';
import { getFilteredPermissions } from "helpers/getFilteredPermissions";

// components
import Aux from "hoc/auxiliar";
import FormDriverSkeleton from "components/Skeletons/FormDriverSkeleton";
import { Input } from "components/react-hook-form";
import { hasPermission } from "components/PermissionsGate";

// styles
import useStyles from "./styles";

const StyledAutocomplete = styled(Autocomplete)(() => ({
  maxWidth: '540px',
  '.MuiInputBase-root': {
    borderRadius: 20,
  }
}));

const DriverForm = () => {
  const classes = useStyles();

  const { currentCustomer, listCustomers } = useAppSelector((state) => state.global.user);
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });

  const birthDateValue = useWatch({ control, name: "birthDate" });
  const customerOption = useWatch({ control, name: "customerOption" });
  const documentNumber = useWatch({ control, name: "documentNumber" });
  const phoneValue = useWatch({ control, name: "phone" });
  const nameValue = useWatch({ control, name: "name" });
  const role = useWatch({ control, name: "role" });

  const [roles, setRoles] = useState([]);
  const [customers, setCustomers] = useState(null);

  const fetchCustomerList = async () => {
    try {
      const customerSearch = !hasPermission({ scopes: ["can_change_driver_customers"] }) ? currentCustomer : listCustomers;

      const response = await getCustomersById(customerSearch);

      const customerOptions = makeSelectOptions({
        data: response.data?.customers ?? [],
        keyLabel: "name",
        keyValue: "id",
      });

      const orderOptions = orderByName(customerOptions);

      setCustomers(orderOptions);
    } catch (err) {
      console.log(err);

      toast.error(
        "Erro ao carregar lista de clientes. Entre em contato com o suporte.",
      );
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await getRolesByContext('app');
      const permissions = response.status === 200 && getGroupedDriverRoles(response.data.data);
      const hasViewPermission = hasPermission({ scopes: ["view_super_admin_option"] });
      const { accessTypes } = getFilteredPermissions(permissions, hasViewPermission);

      setRoles(accessTypes);
    } catch (err) {
      console.log('err', err);
      toast.error(
        "Erro ao carregar lista de perfis. Entre em contato com o suporte!",
      );
    }
  }

  useEffect(() => {
    if (customers?.length > 0) {
      const initialCustomer = customers.find((clt) => clt.value === customerOption);

      if (initialCustomer) {
        setValue("customer", initialCustomer);
      }
    }
  }, [customers, customerOption]);

  useEffect(() => {
    if (birthDateValue) {
      setValue("birthDate", dateMask(birthDateValue));
    }
  }, [birthDateValue]);

  useEffect(() => {
    if (nameValue) {
      setValue("name", removeSpecialCharactersAndNumbersMask(nameValue));
    }
  }, [nameValue, setValue]);

  useEffect(() => {
    if (documentNumber) {
      setValue("documentNumber", cpfMask(documentNumber));
    }
  }, [documentNumber]);

  useEffect(() => {
    if (phoneValue) {
      setValue("phone", phoneMask(phoneValue));
    }
  }, [phoneValue]);

  useEffect(() => {
    if (currentCustomer) {
      fetchRoles()
      fetchCustomerList();
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (role && roles.length > 0) {
      const initialRole = roles?.find(item => item.value === role);

      if (initialRole) {
        setValue('role', initialRole);
      }
    }
  }, [role, roles]);

  if (!customers) {
    return <FormDriverSkeleton />;
  }

  return (
    <Aux>
      <Grid component={Paper} elevation={2} className={classes.container} padding={{ xs: 2, sm: 5 }}>
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid item lg={12} md={12} sm={12} xs={12} display="flex" flexDirection="column" gap={1}>
            <Typography className={classes.title}>
              Empresa
            </Typography>
            <Grid container>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <Controller
                  control={control}
                  name="customer"
                  render={({ field: { value = '', onChange, ...rest }, fieldState: { error } }) => (
                    <>
                      <StyledAutocomplete
                        {...rest}
                        options={customers}
                        renderInput={(params) => <TextField {...params} label="Selecione uma empresa *" />}
                        value={value}
                        onChange={(_, value) => onChange(value)}
                        isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                        getOptionLabel={(option) => option?.label ?? ''}
                        renderOption={({ key, ...props }, option) => (
                          <Box key={option?.value} component="li" {...props}>
                            <Typography>{option.label}</Typography>
                          </Box>
                        )}
                        disablePortal
                      />
                      <FormHelperText className={classes.formHelperText} error={true}>
                        {error?.message}
                      </FormHelperText>
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} display="flex" flexDirection="column" gap={1}>
            <Typography className={classes.title}>
              Dados do motorista
            </Typography>
            <Grid container spacing={{ xs: 2, sm: 4 }}>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="name"
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      variant="outlined"
                      fullWidth
                      label="Nome *"
                      helperText={fieldState.error?.message}
                      error={!!fieldState.error}
                    />
                  )}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="documentNumber"
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      variant="outlined"
                      fullWidth
                      label="CPF *"
                      helperText={fieldState.error?.message}
                      error={!!fieldState.error}
                    />
                  )}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="birthDate"
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      variant="outlined"
                      fullWidth
                      label="Data de nascimento"
                      placeholder="__/__/____"
                      helperText={fieldState.error?.message}
                      error={!!fieldState.error}
                    />
                  )}
                />
              </Grid>
              {(hasPermission({ scopes: ["can_update_users_roles"] })) &&
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Controller
                    control={control}
                    name="role"
                    render={({ field: { value = '', onChange, ...rest } }) => (
                      <StyledAutocomplete
                        {...rest}
                        options={roles}
                        renderInput={(params) => <TextField {...params} label="Tipo de acesso *" />}
                        value={value}
                        onChange={(_, value) => onChange(value)}
                        isOptionEqualToValue={(option, value) => value ? option.label === value?.label || value === '' : false}
                        getOptionLabel={(option) => option?.label ?? ''}
                        renderOption={({ key, ...props }, option) => (
                          <Box key={option?.value} component="li" {...props}>
                            <Typography>{option.label}</Typography>
                          </Box>
                        )}
                        disablePortal
                      />
                    )}
                  />
                  <FormHelperText className={classes.formHelperText} error={true}>
                    {errors.role?.message}
                  </FormHelperText>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} display="flex" flexDirection="column" gap={1}>
            <Typography className={classes.title}>
              Formatos de acesso ao aplicativo
            </Typography>
            <Grid container spacing={{ xs: 2, sm: 4 }}>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="phone"
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      variant="outlined"
                      fullWidth
                      label="Telefone celular *"
                      helperText={fieldState.error?.message}
                      error={!!fieldState.error}
                    />
                  )}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      variant="outlined"
                      fullWidth
                      label="E-mail"
                      helperText={fieldState.error?.message}
                      error={!!fieldState.error}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} display="flex" flexDirection="column" gap={1}>
            <Typography className={classes.title}>
              Anotações sobre o motorista
            </Typography>
            <Grid container spacing={{ xs: 2, sm: 4 }}>
              <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                <Controller
                  control={control}
                  name="obs"
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={1}
                      label="Observações"
                      helperText={fieldState.error?.message}
                      error={!!fieldState.error}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ mt: 4 }}>
              <Typography className={classes.explanation}>
                *Os campos marcados com o asterisco (*) são aqueles que precisam obrigatoriamente ser preenchidos.
              </Typography>
              <Typography className={classes.explanation}>
                *É possível cadastrar o motorista sem informar o telefone e/ou e-mail, porém, nesse caso, ele não terá acesso ao aplicativo. Essa opção é destinada apenas para o controle do gestor via web. Caso deseje que os motoristas utilizem o aplicativo, por favor, informe o telefone e/ou e-mail.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Aux>
  );
};

export default DriverForm;
