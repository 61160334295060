import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menuSelected: {
    vehicles: null,
    status: null,
  },
  vehicleProfile: null,
  vehicleHistory: null
};

export const liveMapSlice = createSlice({
  name: 'liveMap',
  initialState,
  reducers: {
    setLiveMapMenuSelected: (state, action) => {
      state.menuSelected = action.payload;
    },
    setVehicleProfile: (state, action) => {
      state.vehicleProfile = action.payload;
    },
    setVehicleHistory: (state, action) => {
      state.vehicleHistory = action.payload;
    },
    clearLiveMapMenuSelected: (state) => {
      state.menuSelected = initialState.menuSelected
      state.vehicleProfile = initialState.vehicleProfile
      state.vehicleHistory = initialState.vehicleHistory
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  clearLiveMapMenuSelected,
  setLiveMapMenuSelected,
  setVehicleProfile,
  setVehicleHistory
} = liveMapSlice.actions;

export default liveMapSlice.reducer;
