import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IconButton,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import classNames from "classnames";

// themes
import colors from 'themes/gobrax';

// constants
import { notifications } from 'constants/tabs-notications';

// components
import NotificationCard from './notification-card';

// styles
import useStyles from "../styles";

function NotificationCollapse({ open, handleToggle, notificationsToShow }) {
  const classes = useStyles();
  const [tab, setTab] = useState(notifications.generalTab);
  const history = useHistory();

  const handleNavigate = () => {
    if (history.location.pathname === '/gofurther/communication') {
      handleToggle();
    } else {
      handleToggle();
      history.push('/gofurther/communication');
    }
  }

  return (
    <div>
      <div className={classes.backdrop} style={{ width: open ? '100%' : 0, opacity: open ? '0.7' : 0 }} onClick={handleToggle}></div>
      <Grid container className={classes.notificationCollapse} style={{ right: open ? 0 : -460 }}>
        <Typography className={classes.notificationCollapseTitle}>Notificações</Typography>
        <IconButton
          color="inherit"
          onClick={handleToggle}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
          style={{ position: 'absolute', right: -10, top: 10 }}
          data-cy="iconButtonSiderBar"
          size="large">
          <CloseIcon classes={{ root: classes.closeIcon }} />
        </IconButton>
        <Grid container className={classes.notificationCollapseSubTitle}>
          <button
            style={{ color: tab === notifications.generalTab ? colors.palette.black : colors.palette.text.secondary, background: 'none', border: 'none', padding: 0 }}
            onClick={() => {
              setTab(notifications.generalTab)
            }}
            disabled={tab === notifications.generalTab}
          >
            Todas
          </button>
          <button
            style={{ color: tab === notifications.alertTab ? colors.palette.black : colors.palette.text.secondary, background: 'none', border: 'none', padding: 0 }}
            onClick={() => {
              setTab(notifications.alertTab)

            }}
            disabled={tab === notifications.alertTab}
          >
            Alertas
          </button>
          <button
            style={{ color: tab === notifications.communicationTab ? colors.palette.black : colors.palette.text.secondary, background: 'none', border: 'none', padding: 0 }}
            onClick={() => {
              setTab(notifications.communicationTab)
            }}
            disabled={tab === notifications.communicationTab}
          >
            Comunicados
          </button>
        </Grid>
        <Grid container className={classes.notificationsGrid}>
          {notificationsToShow.length > 0 ? notificationsToShow
            .filter((notification) => {
              if (tab === notifications.alertTab) return notification.type === notifications.alert || notification.type === notifications.urgent;
              if (tab === notifications.communicationTab) return notification.type === notifications.communication || notification.type === notifications.message || notification.type === notifications.tips;
              return notification;
            })
            .map((notification) => (
              <NotificationCard
                onClick={() => {
                  handleNavigate();
                }}
                key={notification.id}
                id={notification.id}
                type={notification.type}
                title={notification.title}
                subtitle={notification.subtitle}
                body={notification.body}
                platform={notification.platform}
                publishAt={notification.publish_at}
                status={notification.status}
                updatedAt={notification.updated_at}
                time={notification.time}
                fromGobrax={notification.from_gobrax}
                hasVideo={notification.hasVideo}
                videoUrl={notification.videoUrl}
                videoTitle={notification.videoTitle}
                videoDescription={notification.videoDescription}
                alreadyRead={notification.read}
                allNotifications={notificationsToShow.allNotifications}
              />
            )) : (
            <Grid style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography className={classes.notificationCollapseEmptyStateTitle} style={{ textAlign: 'center', marginTop: 16 }}>Não há notificações para mostrar</Typography>
              <Typography className={classes.notificationCollapseEmptyStateDescription} style={{ textAlign: 'center', marginTop: 16 }}>
                Nenhuma notificação para mostrar no momento, ao receber uma notificação ela aparecerá aqui.</Typography>
            </Grid>
          )}
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Button
              style={{ color: '#0D69D7', fontWeight: '700' }}
              onClick={() => {
                handleNavigate()
              }}
            >
              Ver todas as notificações
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default NotificationCollapse;
