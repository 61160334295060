import { useState, useEffect } from "react";
import { store } from "store";

const URL = process.env.REACT_APP_LINK_WSS + "/web/v2/location/ws/vehicle/status?refresh=5&session_id=";

export const useWebSocketLocationGroupVehicleStatusIdOnly = (setLoading, id) => {
  const [vehicle, setVehicle] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [endpoint, setEndpoint] = useState("");
  const [currentSocket, setCurrentSocket] = useState();
  const { token } = store.getState().global.user;

  const closeConnection = () => {
    currentSocket.close(1000, 'Fechando conexão normalmente')
  }

  //carregar o sessionID
  useEffect(() => {
    setEndpoint(URL + token);
  }, [token, id]);

  //Chamar WS sempre atualizando a lista
  useEffect(() => {
    if (!!token && !!endpoint && refresh && !!id) {
      !vehicle && setLoading(true);
      const websocket = new WebSocket(endpoint + "&vehicle_id=" + id);
      !currentSocket && setCurrentSocket(websocket);
      websocket.onmessage = (event) => {
        const response = JSON.parse(event.data);
        if (response.data) {
          const newLastUpdate = new Date(response.data.LastUpdate);
          setVehicle(prevVehicle => {
            const currentLastUpdate = prevVehicle?.LastUpdate ? new Date(prevVehicle.LastUpdate) : null;
            if (!currentLastUpdate || currentLastUpdate < newLastUpdate) {
              return response.data;
            }
            setLoading(false);
            return prevVehicle;
          });
        }
      };
      websocket.onerror = (error) => {
        setRefresh(false)
        setTimeout(() => {
          setRefresh(true)
        }, 2000)
      };
      return () => {
        websocket.close(1000, 'Fechando conexão normalmente');
      };
    }
  }, [refresh, endpoint]);

  return {
    vehicle,
    closeConnection
  };
};
