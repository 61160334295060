import api from "./api";

export const arrayUrl = (itens = "") => {
  let partialUrl = "";
  if (itens) {
    partialUrl = Array.isArray(itens) ? itens.join() : itens;
  }
  return partialUrl;
};


export const getUsers = async () => {
  try {
    const response = await api.get(`/users`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getUserById = async (userID) => {
  try {
    const response = await api.get(`/users/${userID}`)
    return response
  } catch (error) {
    return error;
  }
}

export const getUserByEmail = async (email) => {
  try {
    const response = await api.get(`/user/${email}`)
    return response
  } catch (error) {
    return error;
  }
}

export const createUser = async (data) => {
  try {
    const response = await api.post(`/users`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteUser = async (userID) => {
  try {
    const response = await api.delete(`/users/${userID}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteUserEntirely = async (userID) => {
  try {
    const response = await api.delete(`/user/${userID}/entirely`);
    return response;
  } catch (err) {
    return err;
  }
};

export const updateUser = async (userID, data) => {
  try {
    const response = await api.put(`/users/${userID}`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const getUserByCustomers = async (customers) => {
  const partialUrl = arrayUrl(customers);
  let params = `customer_id=${partialUrl}`;
  try {
    const response = await api.get(`/list-users?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
}
