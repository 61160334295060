import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

// components
import DeviceForm from "components/DeviceForm";
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

// helpers
import makeDefaultValues from "helpers/makeDefaultValues";
import { makeSelectOptions } from "helpers/makeSelectOptions";
import { removeCountryCode } from "helpers/masks";

// domain
import fields from "domain/forms/formDeviceInitialValues";
import schemaValidation from "domain/validation/yup/formDeviceValidation";
import { brandListOptions } from "domain/selectOptions";

// services
import { getDevice, updateDevice } from "services/device";

// data
import DeviceModel from "models/Device";

// styles
import useStyles from "./styles";
import { statusOptions } from "helpers/deviceStatus";

const EditDevice = ({ history }) => {
  const classes = useStyles();

  const { deviceId } = useParams();
  const [status, setStatus] = useState()
  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });

  const onSubmit = async (data, event) => {
    try {
      const normalizeSimcard = `+55${data.simcard}`;
      const deviceModel = new DeviceModel(
        data.identification,
        data.brand.value,
        normalizeSimcard,
        data.status.value
      );
      const response = await updateDevice(deviceId, deviceModel);
      if (response.status === 200) {
        toast.success("Unidade eletrônica editada com sucesso!");
        methods.reset(defaultValues);
        history.push(`/gofurther/device/list-devices`);
      } else {
        toast.error("Erro ao editar unidade eletrônica. Verifique com o suporte");
      }
    } catch (error) {
      toast.error("Erro ao editar unidade eletrônica. Verifique com o suporte");
    }
  };

  const fetchDeviceDetail = async (id) => {
    try {
      const response = await getDevice(id);
      setStatus(response.data.status)
      const deviceModel = new DeviceModel(
        response.data.identification,
        response.data.brand,
        removeCountryCode(response.data.simcard),
        response.data.status
      );
      const initialValues = makeDefaultValues({ fields, data: deviceModel });
      methods.reset(initialValues);
      methods.setValue("brand", fetchBrandsList().find((elm) => elm.value === deviceModel.brand));
      methods.setValue("status", statusOptions.allStatus.find((elm) => elm.value === deviceModel.status));
    } catch (error) {
      toast.error("Erro ao buscar dados da unidade eletrônica. Verifique com o suporte");
    }
  };

  const fetchBrandsList = () => {
    return makeSelectOptions({
      data: brandListOptions,
      keyLabel: "name",
      keyValue: "id",
    });
  };

  useEffect(() => {
    if (hasPermission({scopes: ['can_edit_devices']})) {
      fetchDeviceDetail(deviceId);
    }
  }, []);

  if (!defaultValues) {
    return "...carregando";
  }

  return (
    <>
      <PermissionsGate scopes={['can_edit_devices']}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Typography variant="h1" className={classes.title}>Edição de unidade eletrônica</Typography>
            <DeviceForm />
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <BackButton className={classes.backButton} />
                  </Box>
                </Grid>
                <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      className={classes.btConfirm}
                    >
                      Atualizar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </PermissionsGate>
    </>
  );
};

export default EditDevice;