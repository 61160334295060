import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import dayjs from "dayjs";
import { toast } from 'react-toastify';

// services
import { getMapDateLocation } from "services/map";
import { getVehicleProfile, getFleetChartData } from "services/fleetPerformance";
import { getVehicle } from "services/vehicle";

// components
import FleetChart from "components/FleetChart";
import HeaderDetails from "componentsNew/molecules/headerDetails/headerDetails";
import MapDetails from "componentsNew/molecules/mapDetails/mapDetails";
import StatusCar from "componentsNew/molecules/statusCar/statusCar";
import Title from "componentsNew/atoms/title/title";

// helpers
import formatDataForLiveMapDetails from "helpers/formatDataForLiveMapDetails";
import { Dates } from "helpers/dates";
import { parseChartData, normalizeData } from "helpers/fleetChart";
import { TypeStatus } from "helpers/statusVehicle";
import { QueryHref } from "helpers/queryHref";
import { returnNA } from "helpers/returnNA";

// hooks
import { useWebSocketLocationGroupVehicleIdOnly } from "hooks/useWebSocketLocationGroupVehicleIdOnly";
import { useWebSocketLocationGroupVehicleStatusIdOnly } from "hooks/useWebSocketLocationGroupVehicleStatusIdOnly";

// styles
import "./liveMapDetails.css";

const dateFormatTemplateWithGMT = "YYYY-MM-DDTHH:mm:ss-0300";

const LiveMapDetails = ({ location }) => {
  const id = QueryHref(location, 'id')
  const [locationCenter, setLocationCenter] = useState({
    lat: -15.823438,
    lng: -47.727483,
  });
  const [fleetData, setFleetData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [datesData, setDatesData] = useState([]);
  const [map, setMap] = useState([]);
  const [chart, setChart] = useState({});
  const [vehicleProfile, setVehicleProfile] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { vehicle, closeConnection: closeConnectionStatus } = useWebSocketLocationGroupVehicleStatusIdOnly(
    setLoadingData,
    id,
  );

  const { fullData: lastPointFromWebSocket, closeConnection } = useWebSocketLocationGroupVehicleIdOnly(
    id
  );

  const fetchVehicleData = async () => {
    try {
      const historyResponse = await getMapDateLocation(id);
      const profileResponse = await getVehicleProfile(id);
      const vehicleResponse = await getVehicle(id);
      const initialDate = historyResponse?.length ? dayjs(new Date(historyResponse?.[historyResponse?.length - 1]?.date)).format(dateFormatTemplateWithGMT) : dayjs().format(dateFormatTemplateWithGMT);
      const finalDate = dayjs(Dates({}).dateNow).format(dateFormatTemplateWithGMT);
      const fleetResponse = await getFleetChartData(
        vehicleResponse?.data?.currentDevice?.identification,
        initialDate,
        finalDate,
      );

      if (historyResponse?.length && profileResponse.status === 200 && fleetResponse.status === 200) {
        const { map, locationInfo } = formatDataForLiveMapDetails(historyResponse);
        const parsedChartData = parseChartData(
          fleetResponse?.data?.data,
          profileResponse?.data,
          vehicleResponse?.data,
          profileResponse,
          initialDate,
          finalDate
        );

        const modelSetChart = {
          ...parsedChartData,
          vehicle: {
            identification: vehicleResponse?.data?.identification,
            plate: vehicleResponse?.data?.plate,
          }
        }


        setProfileData(profileResponse);
        setFleetData(fleetResponse?.data?.data)
        setDatesData({
          initialDate,
          finalDate,
        })
        setVehicleProfile(historyResponse);
        setLocationCenter(locationInfo);
        setChart(modelSetChart);
        setMap(map);
      }
    } catch (error) {
      toast.warning('Erro ao carregar dados do mapa ao vivo');
      console.error('Error fetching vehicle data:', error);
    }
  };

  useEffect(() => {
    id && fetchVehicleData();
  }, [id]);

  useEffect(() => {
    const checkNewPoints = async (lastDataEntry) => {
      /// logic for update fleet data
      const lastChartDataFromWebSocket = {
        can_fuel_level: lastPointFromWebSocket?.[0].location.graphic?.fuel || lastDataEntry?.can_fuel_level,
        rpm: lastPointFromWebSocket?.[0].location.graphic?.rpm || lastDataEntry?.rpm,
        height: lastPointFromWebSocket?.[0].location.graphic?.altitude || lastDataEntry?.height,
        can_speed: lastPointFromWebSocket?.[0].location.graphic?.can_speed || lastDataEntry?.can_speed,
        time: lastPointFromWebSocket?.[0].location?.Date,
        gps_speed: lastPointFromWebSocket?.[0].location.graphic?.speed || lastDataEntry?.gps_speed,
        total_fuel_consumption_high_resolution: lastPointFromWebSocket?.[0].location.graphic?.total_fuel_consumption_high_resolution || lastDataEntry?.total_fuel_consumption_high_resolution,
        acceleration_pedal_position: lastPointFromWebSocket?.[0].location.graphic?.acceleration_pedal_position || lastDataEntry?.acceleration_pedal_position
      };

      setFleetData(prevFleetData => [...prevFleetData, lastChartDataFromWebSocket]);

      const updatedParseChartData = parseChartData(
        [...fleetData, lastChartDataFromWebSocket],
        profileData?.data,
        vehicle,
        profileData,
        datesData.initialDate,
        datesData.finalDate
      );

      const modelSetChart = {
        ...updatedParseChartData,
        vehicle: {
          identification: chart?.vehicle?.identification,
          plate: chart?.vehicle?.plate,
        }
      }
      setChart(modelSetChart);

      /// logic for update location point
      setLocationCenter({
        lat: lastPointFromWebSocket?.[0].location.point?.lat,
        lng: lastPointFromWebSocket?.[0].location.point?.lon
      });

      /// logic for update map
      const lastMapDataFromWebSocket = {
        date: lastPointFromWebSocket?.[0].location?.Date,
        color: TypeStatus(lastPointFromWebSocket?.[0].location?.status),
        title: lastPointFromWebSocket?.[0].location?.plate,
        lng: lastPointFromWebSocket?.[0].location.point?.lon,
        lat: lastPointFromWebSocket?.[0].location.point?.lat,
        uuid: lastPointFromWebSocket?.[0].vehicleId,
        time: lastPointFromWebSocket?.[0].location?.Date,
      };

      const newMapData = await normalizeData([...map, lastMapDataFromWebSocket]);
      setMap(newMapData);
    }

    if (lastPointFromWebSocket?.length > 0) {
      const lastData = fleetData[fleetData.length - 1];
      const vehicleDate = new Date(lastPointFromWebSocket?.[0]?.location?.Date);
      const lastDate = new Date(lastData?.time);
      vehicleDate > lastDate && checkNewPoints(lastData);
    }
  }, [lastPointFromWebSocket]);

  const getLastUpdate = () => {
    return fleetData?.length ? dayjs(fleetData[fleetData?.length - 1]?.time).format("DD/MM/YYYY HH:mm:ss") : '';
  }

  const MapDetailsMemoized = React.memo(({ points, initLocationCenter, initZoom, height, loading }) => {
    return (
      <MapDetails
        points={points}
        initLocationCenter={initLocationCenter}
        initZoom={initZoom}
        height={height}
        loading={loading}
      />
    );
  }, (prevProps, nextProps) => {
    return prevProps.points === nextProps.points &&
      prevProps.initLocationCenter === nextProps.initLocationCenter;
  });

  MapDetailsMemoized.displayName = 'MapDetailsMemoized';

  return (
    <div className={`LiveMap-container`}>
      <Grid container className={`LiveMap-container-top`}>
        <Grid item className={`LiveMap-title`}>
          <Title value={"Mapa ao vivo"} />
        </Grid>
        <Grid item className={`LiveMap-header`}>
          <HeaderDetails
            vehicle={returnNA(vehicle?.Plate)}
            driver={returnNA(vehicle?.DriverName)}
            date={"Última atualização: " + getLastUpdate()}
            onBack={() => {
              if (closeConnectionStatus) closeConnectionStatus()
              if (closeConnection) closeConnection()
              window.location.href = '/gofurther/liveMap'
            }}
            loading={loadingData}
          />
        </Grid>
      </Grid>
      <Grid container className={`LiveMap-container-medium`}>
        <Grid item className={`LiveMap-map`}>
          <MapDetailsMemoized
            points={map}
            initLocationCenter={locationCenter}
            initZoom={15}
            height={"390px"}
            loading={loadingData}
          />
        </Grid>
        <Grid item className={`LiveMap-description`}>
          <StatusCar
            location={vehicle?.Address || ' - '}
            driven={`${returnNA(vehicle?.DrivenKM)} km`}
            consumption={`${returnNA(vehicle?.Consumption)} litros`}
            average={`${returnNA(vehicle?.Average)} km/l`}
            fuelLevel={`${returnNA(vehicle?.FuelLevel)}% (${returnNA(vehicle?.ActualVolume)} litros)`}
            Autonomy={`${returnNA(vehicle?.Autonomy)} km`}
            averageSpeed={`${returnNA(vehicle?.AverageSpeed)} km/h`}
            highSpeedBraking={`${returnNA(vehicle?.HighSpeedBraking)}`}
            fullBraking={`${returnNA(vehicle?.FullBraking)}`}
            odometer={`${returnNA(vehicle?.Odometer)}`}
            loading={loadingData}
          />
        </Grid>
      </Grid>
      <Grid className={`LiveMap-chart`}>
        <FleetChart
          loading={loadingData}
          data={{
            ...chart,
            limits: {
              maxFuelCapacity: vehicle?.MaxFuelCapacity ?? 0,
              maxSpeedAllowed: vehicleProfile?.maxSpeedAllowed || 0,
            }
          }}
          tooltip={true}
          seconds={true}
          header={{
            vehicle: chart?.vehicle?.identification || '',
            date: chart?.dates ? dayjs(chart?.dates?.initialDate).format("DD/MM/YYYY HH:mm:ss") + ' - ' + dayjs(chart?.dates?.finalDate).format("DD/MM/YYYY HH:mm:ss") : '',
          }}
          chartId="live-map-details-chart"
        />
      </Grid>
    </div>
  );
};

export default LiveMapDetails;

LiveMapDetails.displayName = "LiveMapDetails";
