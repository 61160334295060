/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

// store
import { useAppSelector } from "store";

// context
import { useInputsProfile } from "context/useInputsProfile";

// services
import { createVehicleProfile, getVehicleProfile, updateVehicleProfile } from "services/profiles";

// components
import Aux from "hoc/auxiliar";
import VehicleProfileForm from "components/VehicleProfileForm";
import BackButton from "components/BackButton";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

import makeDefaultValues from "helpers/makeDefaultValues";

import fields from "domain/forms/formVehicleProfileInitialValues";
import schemaValidation from "domain/validation/yup/formVehicleProfileValidation";

import "react-datepicker/dist/react-datepicker.css";
import useStyles from "./styles";

const AddProfile = () => {
  const history = useHistory();
  const classes = useStyles();
  const { email } = useAppSelector((state) => state.global.user);
  const { fetchBrandsList, fetchModelsList } = useInputsProfile();

  const { profileId } = useParams();
  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      ...defaultValues,
      neutralRangeStart: 0,
    },
    resolver: yupResolver(schemaValidation),
  });

  const { setValue, reset, formState } = methods;

  const onSubmit = async (data) => {
    const formattedData = {
      ...data,
      neutralRangeEnd: data.whiteRangeStart - 1,
      whiteRangeEnd: data.extraEconomicRangeStart - 1,
      extraEconomicRangeEnd: data.economicRangeStart - 1,
      powerRangeEnd: data.engineBrakeRangeStart - 1,
      economicRangeEnd: data.powerRangeStart - 1,
      engineBrakeRangeEnd: data.redRangeStart - 1,
      brandId: data.brandId.value,
      modelId: data.modelId.value,
    };

    try {
      const response = profileId ? await updateVehicleProfile(profileId, formattedData) : await createVehicleProfile(formattedData);

      if (response.status === 200) {
        profileId ? toast.success("Perfil atualizado com sucesso!") : toast.success("Perfil criado com sucesso!");
        reset();
        history.push("/gofurther/profile/list-profiles");
      }
    } catch (error) {
      profileId ? toast.error("Erro ao atualizar o perfil. Verifique com o suporte") : toast.error("Erro ao criar Perfil. Verifique com o suporte");
    }
  };

  const getInitialInputsState = (getValues) => {
    const fieldsToFill = [
      { name: 'whiteRangeStart', title: 'Branca' },
      { name: 'extraEconomicRangeStart', title: 'Início faixa verde' },
      { name: 'economicRangeStart', title: 'Final faixa verde' },
      { name: 'powerRangeStart', title: 'Acelerando acima do verde' },
      { name: 'engineBrakeRangeStart', title: 'Freio motor' },
      { name: 'redRangeStart', title: 'Vermelha' },
      { name: 'redRangeEnd', title: 'Final vermelha' },
    ];

    return fieldsToFill.map(field => {
      const value = getValues[field.name] || '';

      return { ...field, value, enabled: true, invalid: false };
    });
  };

  const fetchProfileDetail = async () => {
    try {
      const response = await getVehicleProfile(profileId);

      const fetchedProfile = response.data.data?.profile;

      const profileInputs = getInitialInputsState(fetchedProfile);

      profileInputs.forEach(({ name, value }) => {
        setValue(name, value);
      });

      const fetchedBrands = await fetchBrandsList();
      const selectedBrand = fetchedBrands.find((fetchedBrand) => fetchedBrand.value === fetchedProfile.brandId);

      if(selectedBrand) {
        setValue("brandId", selectedBrand);
      }

      const fetchedModels = await fetchModelsList(fetchedProfile.brandId);
      const selectedModel = fetchedModels.find((fetchedModel) => fetchedModel.value === fetchedProfile.modelId);

      if (selectedModel) {
        setValue("modelId", selectedModel);
      }
    } catch (error) {
      toast.error(
        "Erro ao buscar dados do Perfil. Verifique com o suporte",
      );
    };
  };

  useEffect(() => {
    fetchBrandsList();
  }, []);

  useEffect(() => {
    if (profileId) {
      if (hasPermission({ scopes: ['can_edit_profiles'] })) {
        reset();
        fetchProfileDetail();
      }
    } else {
      reset();
    }
  }, [profileId])

  return (
    <Aux>
      <PermissionsGate scopes={['can_create_profiles', 'can_edit_profiles']}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              <Grid item xl={12} xs={12}>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Typography variant="h1" className={classes.title}>{profileId ? 'Edição de perfil' : 'Cadastro de perfil'}</Typography>
                    <VehicleProfileForm />
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <BackButton className={classes.backButton} />
                            </Box>
                          </Grid>
                          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                            <Box sx={{ m: 2 }}>
                              <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                disabled={!formState.isValid}
                                className={classes.btConfirm}
                              >
                                {profileId ? 'Atualizar' : 'Salvar'}
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default AddProfile;
