import React from "react";
import { Grid, Button, Tooltip, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { convertDateBr } from "helpers/dates";
import makeStyles from "./styles";
import { handleAmplitudeEvent } from "services/amplitude";

export const makeColumns = (size, showDetails) => {
  const classes = makeStyles();
  return [
    {
      name: "plate",
      label: "Placa/frota",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "driverName",
      label: "Motorista",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "actualFuelData.actualVolume",
      label: "Nível do tanque atual",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography noWrap={true}>
              {value.Percentage}% ({value.Value}L)
            </Typography>
          );
        },
      },
    },
    {
      name: "actualFuelData.autonomy",
      label: "Autonomia",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography noWrap={true}>
              {value.toFixed(2)} Km
            </Typography>
          );
        },
      },
    },
    {
      name: "actualFuelData.averageOfTheMonth",
      label: "Média do caminhão (km/l) mês",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography noWrap={true}>
              {value.toFixed(2)} km/l
            </Typography>
          );
        },
      },
    },
    {
      name: "actualFuelData.date",
      label: "Data/hora da última informação",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography noWrap={true}>
              {convertDateBr(value)}
            </Typography>
          );
        },
      },
    },
    {
      name: "vehicleId",
      label: "Ação",
      options: {
        sort: false,
        filter: false,
        print: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta) => {
          const selectedDate = tableMeta.rowData[5];
          const vehicleIdentification = tableMeta.rowData[1];
          return (
            <Grid container direction="column" alignItems="center">
              <Tooltip title="Visualizar local" aria-label="visualizar-local">
                <Button
                  onClick={() => {
                    const amplitudeModel = {
                      vehicle: tableMeta.rowData[0],
                      date: selectedDate,
                      actual_volume: tableMeta.rowData[2]?.Value,
                      autonomy: tableMeta.rowData[3],
                    };
                    handleAmplitudeEvent('View Details Clicked', amplitudeModel);
                    showDetails(selectedDate, value, vehicleIdentification);
                  }}
                  className={classes.btAction}
                  aria-label="visualizar-local"
                >
                  Visualizar Local <OpenInNewIcon />
                </Button>
              </Tooltip>
            </Grid>
          );
        },
      },
    },
  ];
};
