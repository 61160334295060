import React from "react";
import PropTypes from "prop-types";

import Item from "../../../componentsNew/atoms/item/item";

import "./statusCar.css";

const StatusCar = ({
  location = "Rua Engenheiros Rebouças, 184 - Jardim Botânico, Curitiba - PR, 80210-040",
  driven = "83 km",
  consumption = "58 Lts",
  average = "2.8 km/l",
  fuelLevel = "30% (108 Lts)",
  Autonomy = "124 km",
  averageSpeed = "63 km/h",
  highSpeedBraking = "7",
  fullBraking = "43",
  odometer = "55.345.982 ",
  loading,
}) => {
  return (
    <div className={`StatusCar-container`}>
      <span className={`StatusCar-title`}>
        Status diário do veículo selecionado:
      </span>
      <Item icon={'location'} labelStrong={location} loading={loading} />
      <Item
        icon={'speed_velocity'}
        label={"Km rodado(dia)"}
        labelStrong={driven}
        loading={loading}
      />
      <Item
        icon={'supply'}
        label={"Consumo"}
        labelStrong={consumption}
        loading={loading}
      />
      <Item
        icon={'medium_tank'}
        label={"Média computador de bordo"}
        labelStrong={average}
        loading={loading}
      />
      <Item
        icon={'supply'}
        label={"Nivel do combustivel"}
        labelStrong={fuelLevel}
        loading={loading}
      />
      <Item
        icon={'location'}
        label={"Autonomia"}
        labelStrong={Autonomy}
        loading={loading}
      />
      <Item
        icon={'speed_velocity'}
        label={"Velocidade média"}
        labelStrong={averageSpeed}
        loading={loading}
      />
      <Item
        icon={'brake_warning'}
        label={"Freadas alta velocidade"}
        labelStrong={highSpeedBraking}
        loading={loading}
      />
      <Item
        icon={'abs'}
        label={"Freadas totais"}
        labelStrong={fullBraking}
        loading={loading}
      />
      <Item
        icon={'odometro'}
        label={"Odômetro"}
        labelStrong={odometer}
        loading={loading}
      />
    </div>
  );
};

export default StatusCar;

StatusCar.propTypes = {
  /**
   * Localização
   */
  location: PropTypes.string.isRequired,
  /**
   * Km rodado
   */
  driven: PropTypes.string.isRequired,
  /**
   * Consumo
   */
  consumption: PropTypes.string.isRequired,
  /**
   * Média
   */
  average: PropTypes.string.isRequired,
  /**
   * Nivel de combustivel
   */
  fuelLevel: PropTypes.string.isRequired,
  /**
   * Autonomia
   */
  Autonomy: PropTypes.string.isRequired,
  /**
   * Velocidade média
   */
  averageSpeed: PropTypes.string.isRequired,
  /**
   * Fredas alta velocidade
   */
  highSpeedBraking: PropTypes.string.isRequired,
  /**
   * Freadas totais
   */
  fullBraking: PropTypes.string.isRequired,
  /**
   * Odômetro
   */
  odometer: PropTypes.string.isRequired,
  loading: PropTypes.any,
};