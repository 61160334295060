export function StatusVehicle(data) {
  const vehiclesStatus = [];
  // valida se é array
  if(Array.isArray(data)){
    data.map((vehicle, i) => {
      // valida se tem localização
      if (vehicle?.location?.point?.lat !== 0 && vehicle?.location?.point?.lon !== 0 && vehicle?.vehicleId) {
        vehiclesStatus.push({
          vehicleId: vehicle?.vehicleId,
          date: vehicle?.location?.Date,
          color: TypeStatus(vehicle?.location?.status),
          title: vehicle?.location?.plate ?? `Sem identificação ${i}`,
          lng: vehicle?.location?.point?.lon,
          lat: vehicle?.location?.point?.lat,
          uuid: vehicle?.vehicleId,
        });
      }
    });
  }
  return vehiclesStatus;
}

export function TypeStatus(value) {
  switch (value) {
    case "STOPPED_MOTOR_ON":
      return "blue";
    case "IN_MOVEMENT":
      return "green";
    case "DANGER_BREAKDOWN":
      return "yellow";
    case "STOPPED_MOTOR_OFF":
      return "gray";
    case "COMMUNICATION_OFF":
      return "white";
    case "UNAVAILABLE":
      return "red";

    default:
      return "red";
  }
}
