import * as yup from "yup";

export default yup.object().shape({
  customer: yup.array().min(1, "Campo obrigatório").required("Campo obrigatório"),
  name: yup.string().required("Campo obrigatório"),
  email: yup.string().email('O campo deve ser um e-mail válido').required("Campo obrigatório"),
  role: yup
    .string()
    .nullable()
    .required("Campo obrigatório"),
});
