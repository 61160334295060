import React from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EventIcon from "@mui/icons-material/Event";
import HistoryIcon from "@mui/icons-material/History";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

import { useHistory } from "react-router-dom";
import { useAppDispatch } from "store";
import { setVehicleStepDeduct } from "store/features/vehicleDashboardSlice";
import { setStepDeductBonus } from "store/features/bonusFollowSlice";
import { useWindowSize } from "hooks/useWindowsSize";
import { valueToPositiveFloat } from "helpers/functions";

import useStyles from "./dieselControlInfo";

const DieselControlInfo = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const formatDate = "DD/MM/YY HH:mm";
  const size = useWindowSize();

  const { dieselData, filters } = props;
  const { selectedDates, vehicle } = filters;
  const { initialDate, finalDate } = selectedDates;
  const lastTotalFuelPercentage = dieselData?.lastEntry?.actualVolume?.percentage ?? 0;
  const lastTotalFuelValue = dieselData?.lastEntry?.actualVolume?.value ?? 0;
  const lastDate = dieselData?.lastEntry?.date ? dayjs(dieselData?.lastEntry?.date).format(formatDate) : '-'; 

  return (
    <Box sx={{ mb: 2 }}>
      <Paper className={classes.paper} elevation={0}>
        <Grid container>
          <Grid className={classes.wrapperContent} container item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid className={classes.flex}>
              <LocalShippingIcon className={classes.icon} />
              <Typography className={classes.textIconTitle}>{vehicle?.[0]?.[0]?.plate || vehicle?.[0]?.plate || vehicle?.plate}</Typography>
            </Grid>
            <Grid className={classes.flex}>
              <EventIcon className={classes.icon} />
              <Typography className={classes.textNormal}>Período&nbsp;</Typography>
              <Typography className={classes.textHighlight}>{dayjs(initialDate).format(formatDate)}</Typography>
              <Typography className={classes.textHighlight}>&nbsp;a&nbsp;</Typography>{" "}
              <Typography className={classes.textHighlight}>{dayjs(finalDate).format(formatDate)}</Typography>
            </Grid>
            <Grid className={classes.flex}>
              <LocalGasStationIcon className={classes.icon} />
              <Typography className={classes.textNormal}>Nível do tanque atual&nbsp;</Typography>
              <Typography className={classes.textHighlight}>{valueToPositiveFloat(lastTotalFuelPercentage)}%&nbsp;</Typography>
              <Typography className={classes.textHighlight}>({valueToPositiveFloat(lastTotalFuelValue)} litros)</Typography>
            </Grid>
            <Grid className={classes.flex}>
              <HistoryIcon className={classes.icon} />
              <Typography className={classes.textNormal}>Últimos dados recebidos&nbsp;</Typography>
              <Typography className={classes.textHighlight}>
                {lastDate}
              </Typography>
            </Grid>
            {size.mobile ? null :
              <Grid className={classes.flex}>
                <Button
                  onClick={() => {
                    dispatch(setStepDeductBonus())
                    dispatch(setVehicleStepDeduct())
                    history.goBack();
                  }}
                  variant="contained"
                  className={classes.backButton}
                >
                  <ArrowBackIcon fontSize="small" />{" "}Voltar
                </Button>
              </Grid>}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default DieselControlInfo;