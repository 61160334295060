import React, { useCallback, useEffect, useState } from "react";
import { Grid, Button, Box, Typography } from "@mui/material";
import { useAppSelector } from "store";
import { FormatColorReset } from "@mui/icons-material";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";

// services
import { createOperation } from "services/operations";
import { getVehiclesByOperation } from "services/vehicle";

// components
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import FormVehicleGroupComponent from "components/FormVehicleGroupComponent";
import LinkVehicleGroupComponent from "components/LinkVehicleGroupComponent";
import OperationRegisterSkeleton from "components/Skeletons/OperationRegister";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

import operationValidation from "domain/validation/yup/operationValidation";
import operationValidationMinScore from "domain/validation/yup/operationValidationMinScore";
import fields from "domain/forms/operationInitialValues";

import makeDefaultValues from "helpers/makeDefaultValues";

// styles
import useStyles from "./styles";

const CreateOperation = ({ history }) => {
  const { email, currentCustomer } = useAppSelector((state) => state.global.user);

  const [unavailableVehicles,] = useState([])
  const [selectedVehiclesError, setSelectedVehiclesError] = useState(false)
  const [availableVehicles, setAvailableVehicles] = useState([])
  const [selectedVehicles, setSelectedVehicles] = useState([])
  const [loading, setLoading] = useState(true)
  const classes = useStyles();

  const defaultValues = makeDefaultValues({ fields })
  const methods = useForm({
    defaultValues: defaultValues,
    resolver: hasPermission({ scopes: ['can_create_minscore'] }) ? yupResolver(operationValidationMinScore) : yupResolver(operationValidation)
  });

  const fetchAllVehicles = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getVehiclesByOperation(currentCustomer)
      const { customers: operations } = response.data
      const uniqueAvailableVehicles = [...new Set(
        operations
          .filter(op => op.id === 0)
          .flatMap(op => op.vehicles)
      )];
      setAvailableVehicles(uniqueAvailableVehicles);
      if (hasPermission({ scopes: ['can_create_minscore'] })) {
        methods.setValue('minScore', 0)
      }
    } catch (error) {
      throw new Error()
    } finally {
      setLoading(false)
    }
  }, [currentCustomer])

  useEffect(() => {
    if (hasPermission({ scopes: ['can_create_operations'] }) && currentCustomer) {
      fetchAllVehicles()
    }
  }, [currentCustomer, fetchAllVehicles])

  const handleSelectedVehicles = (selected) => setSelectedVehicles(selected);

  const onSubmit = async (data) => {
    if (!selectedVehicles?.length && availableVehicles?.length) {
      return setSelectedVehiclesError(true);
    }
    setLoading(true);

    const submitModel = {
      customerId: Number(currentCustomer),
      name: data.name,
      rewardFormat: data.rewardFormat,
      reward: {
        maxAmount: Number(data.maxAmount),
        targetMileage: Number(data.targetMileage),
      },
      operationType: data.operationType,
      requirementLevel: data.requirementLevel,
      vehicles: selectedVehicles?.map((vehicle) => ({
        vehicleId: vehicle.id,
      })),
    };

    if (data.rewardFormat === "scoreMinimunReward") {
      submitModel.reward.minScore = data.minScore ? Number(data.minScore) : 0;
    } else if (data.rewardFormat === "scoreMediumReward") {
      submitModel.reward.minProportionalScore = data.minProportionalScore ? Number(data.minProportionalScore) : 0;
    } else if (data.rewardFormat === "range") {
      submitModel.reward.rewardRange = data.rewardRange ? data.rewardRange : [];
    }

    try {
      const response = await createOperation(submitModel);
      if (response.status === 201 || response.status === 200) {
        toast.success("Operação cadastrada com sucesso!");
        history.push(`/gofurther/maintenance/operations/operationList`);
      } else {
        toast.error("Erro estranho aconteceu. Contate o suporte");
      }
    } catch (error) {
      toast.error("Erro ao criar operação. Contate o suporte.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <OperationRegisterSkeleton />

  return (
    <Aux>
      <PermissionsGate scopes={['can_create_operations']}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{ m: 1, mt: 4 }}>
                    <Typography variant='h1' className={classes.title} data-cy="h1Title">Grupo de veículos</Typography>
                    <FormVehicleGroupComponent
                      register={methods.register}
                    />
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{ m: 1, mt: 4 }}>
                    <Typography variant="h1" className={classes.title} data-cy="h1TitleAssociate">Associar veículos ao grupo</Typography>
                    <LinkVehicleGroupComponent
                      error={selectedVehiclesError}
                      handleSelectedVehicles={handleSelectedVehicles}
                      availableVehicles={availableVehicles}
                      unavailableVehiclesByOperation={unavailableVehicles}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid container item justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                      <Box sx={{ m: 2 }}>
                        <BackButton className={classes.backButton} />
                      </Box>
                    </Grid>
                    <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                      <Box sx={{ m: 2 }}>
                        <Button
                          fullWidth
                          variant="contained"
                          type="submit"
                          className={classes.btConfirm}
                          data-cy="divSave"
                          disabled={loading}
                        >
                          Salvar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </PermissionsGate>
    </Aux>
  );
};

export default CreateOperation;
