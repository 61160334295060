import React from "react";
import { IconButton, Grid, Typography } from "@mui/material";
import { CheckCircle as CheckIcon, Edit as EditIcon, History as HistoryIcon, RemoveRedEyeOutlined as EyeIcon } from "@mui/icons-material";
import { mdiShieldLinkVariantOutline } from "@mdi/js";
import Icon from "@mdi/react";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

import makeStyles from "./styles";
import { Link } from "react-router-dom";

export const makeColumns = ({ onChangeStatus, onEdit, onAddBond, size }) => {
  const classes = makeStyles();
  let columns = [
    {
      name: "customerName",
      label: size.mobile ? ' ' : "Empresa",
    },
    {
      name: "identification",
      label: "Frota",
    },
    {
      name: "plate",
      label: "Placa",
    },
    {
      name: "model",
      label: "Perfil",
    },
    {
      name: "brand",
      label: "Marca",
    },
    {
      name: "chassi",
      label: "Chassi",
    },
    {
      name: "currentDevice.identification",
      label: "ID do tracker",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Typography variant="body2">{value}</Typography>
          ) : (
            <Typography variant="body2" className={classes.textInfo}>
              Nenhuma Unidade Vinculada
            </Typography>
          );
        },
      },
    },
  ];

  if (!size.mobile && hasPermission({
    scopes: [
      'can_edit_vehicles',
      'can_activate_vehicles',
      'can_edit_vehicledevice',
      'can_view_vehicles_without_edit',
      'can_view_history_vehicle']
  })) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        print: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <Grid container className={classes.actionColumn}>
              <PermissionsGate scopes={['can_edit_vehicles']}>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="Editar"
                    size="large">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_view_history_vehicle']}>
                <Grid item xs={2}>
                  <IconButton
                    component={Link}
                    to={`/gofurther/vehicle/historic-vehicle/${value}`}
                    aria-label="history"
                    title="Histórico"
                    size="large">
                    <HistoryIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_activate_vehicles']}>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => onChangeStatus(value)}
                    aria-label="change-status"
                    title="Ativar/Inativar"
                    size="large">
                    <CheckIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_edit_vehicledevice']}>
                <Grid item xs={2}>
                  <IconButton
                    component={Link}
                    to={`/gofurther/device/vehicle-devices?vehicleId=${value}`}
                    size="large">
                    <Icon
                      path={mdiShieldLinkVariantOutline}
                      size={1}
                      title="Vincular unidade eletrônica"
                    />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_view_vehicles_without_edit']}>
                <Grid item xs={2}>
                  <IconButton
                    aria-label="view-vehicle"
                    title="Visualizar veículo"
                    component={Link}
                    to={`/gofurther/vehicle/view-vehicle/${value}`}
                    size="large">
                    <EyeIcon />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }
  return columns;
};
