import React, { useEffect, useState, Fragment } from 'react';
import { Box, Grid, Typography } from '@mui/material';

// theme
import colors from '../../themes/gobrax';

// constants
import { notifications } from '../../constants/tabs-notications';

// store
import { useAppDispatch, useAppSelector } from 'store';
import { setGlobalUser } from 'store/globalSlice';
import { clearConsolidatedFilters } from 'store/features/consolidatedSlice';

// services
import { getNotifications, updateNotificationsViewed } from 'services/notifications';

// components
import Aux from 'hoc/auxiliar';
import PermissionsGate, { hasPermission } from 'components/PermissionsGate';
import NotificationCard from './components/notification-card';

// styles
import useStyles from './styles';

export default function Communication() {
  const dispatch = useAppDispatch()
  const { currentCustomer, notifications: notificationsToShow, email } = useAppSelector((state) => state.global.user)
  const classes = useStyles()
  const [tab, setTab] = useState(notifications.generalTab);


  const handleNotifications = async () => {
    try {
      const responseUpdateNotifications = await updateNotificationsViewed(email)
      const response = await getNotifications(email)
      const allNotifications = response.data.data.filter(notification => notification.status === 'published' || notification.status === 'viewed')
      const newsNotifications = response.data.data.filter(notification => notification.status === 'published')

      let orderNewsNotifications = [];
        if (newsNotifications.length > 0) {
          orderNewsNotifications = newsNotifications.sort((a, b) => {
            return new Date(b.publish_at) - new Date(a.publish_at);
          })
        }
        
        const notificationModel = {
          notifications: {
            allNotifications,
            newsNotifications: orderNewsNotifications
          }
        }
      dispatch(setGlobalUser({ notifications: notificationModel.notifications }));

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ['can_view_consolidated'] })) {
      dispatch(clearConsolidatedFilters())
    }
  }, [currentCustomer])

  useEffect(() => {
    handleNotifications()
  }, [])

  const sortByUpdatedAt = (a, b) => {
    const firstDate = new Date(a.updated_at).getTime();
    const secondDate = new Date(b.updated_at).getTime();

    return secondDate - firstDate;
  };

  const arrayForSort = [...notificationsToShow.allNotifications];
  const sortedArray = arrayForSort.sort(sortByUpdatedAt);
  return (
    <Aux>
      <PermissionsGate scopes={['can_view_consolidated']}>
        <Grid container item xs={12} xl={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
          <Typography variant="h3" className={classes.title}>Minhas notificações</Typography>
          <Grid container className={classes.notificationCollapseSubTitle}>
            <button
              style={{ color: tab === notifications.generalTab ? colors.palette.black : colors.palette.text.secondary, background: 'none', border: 'none', padding: 0, fontWeight: 700 }}
              onClick={() => {
                setTab(notifications.generalTab)
              }}
            >
              Todas
            </button>
            <button
              style={{ color: tab === notifications.alertTab ? colors.palette.black : colors.palette.text.secondary, background: 'none', border: 'none', padding: 0, fontWeight: 700 }}
              onClick={() => {
                setTab(notifications.alertTab)
              }}
            >
              Alertas
            </button>
            <button
              style={{ color: tab === notifications.communicationTab ? colors.palette.black : colors.palette.text.secondary, background: 'none', border: 'none', padding: 0, fontWeight: 700 }}
              onClick={() => {
                setTab(notifications.communicationTab)
              }}
            >
              Comunicados
            </button>
          </Grid>
          {sortedArray.length > 0 ? sortedArray
            .filter((notification) => {
              if (tab === notifications.alertTab) return notification.type === notifications.alert || notification.type === notifications.urgent;
              if (tab === notifications.communicationTab) return notification.type === notifications.communication || notification.type === notifications.message || notification.type === notifications.tips;
              return notification;
            })
            .map((notification) => {
              return (
                <Fragment key={notification.id}>
                  <NotificationCard
                    key={notification.id}
                    id={notification.id}
                    type={notification.type}
                    title={notification.title}
                    subtitle={notification.subtitle}
                    body={notification.body}
                    platform={notification.platform}
                    publishAt={notification.publish_at}
                    status={notification.status}
                    updatedAt={notification.updated_at}
                    fromGobrax={notification.from_gobrax}
                    allNotifications={notificationsToShow.allNotifications}
                  />
                </Fragment>
              )
            }) : (
            <Grid style={{ paddingTop: 104, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <Box style={{ maxWidth: 518 }}>
                <Typography style={{
                  fontSize: 32, fontWeight: 500, color: '#595959', textAlign: 'center', marginBottom: 16
                }}>
                  Não há notificações para mostrar
                </Typography>
                <Typography style={{
                  fontSize: 18, textAlign: 'center', marginBottom: 32
                }}>
                  Nenhuma notificação para mostrar no momento, ao receber uma notificação ela aparecerá aqui. 
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </PermissionsGate>
    </Aux>
  );
}

