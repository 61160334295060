import api from "./api";
import { convertStringDate } from "helpers/functions";

const vehiclesUrl = (vehicles = "") => {
  return vehicles ?
    Array.isArray(vehicles) ? vehicles.filter(x => x).join() : vehicles
    : "";
};

export const getFleetLocations = async (vehicle, startDate, endDate, zip = true) => {
  const partialUrl = vehiclesUrl(vehicle);
  const convertedDates = convertStringDate(startDate, endDate);
  let params = `vehicle=${partialUrl}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}&zip=${zip}`;
  try {
    const response = await api.get(`/web/v2/fleet-locations?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getCo2Tax = async (vehicles, startDate, endDate) => {
  const partialUrl = vehiclesUrl(vehicles);
  const convertedDates = convertStringDate(startDate, endDate);
  let params = `vehicles=${partialUrl}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}`;
  try {
    const response = await api.get(`/web/v2/performance/tax/co2?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getFleetStatistics = async (vehicles, startDate, endDate) => {
  const partialUrl = vehiclesUrl(vehicles);
  const convertedDates = convertStringDate(startDate, endDate);
  let params = `vehicles=${partialUrl}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}`;
  try {
    const response = await api.get(`/fleet-statistics?${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getFleetPercentages = async (vehicles, startDate, endDate) => {
  const partialUrl = vehiclesUrl(vehicles);
  const convertedDates = convertStringDate(startDate, endDate);
  let params = `vehicle=${partialUrl}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}`;
  try {
    const response = await api.get(`/fleet-percentages?${params}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehiclesPerformancePack = async (vehicles, startDate, endDate) => {
  const partialUrl = vehiclesUrl(vehicles);
  const convertedDates = convertStringDate(startDate, endDate);
  let params = `vehicles=${partialUrl}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}&onDemand=true`
  try {
    const response = await api.get(`/web/v2/performance/vehicles/pack?${params}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehiclesPerformancePackLimitPage = async (vehicles, startDate, endDate, limit, page) => {
  const partialUrl = vehiclesUrl(vehicles);
  const convertedDates = convertStringDate(startDate, endDate);
  let params = `vehicles=${partialUrl}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}&onDemand=true&limit=${limit}&page=${page}`
  try {
    const response = await api.get(`/web/v2/performance/vehicles/pack?${params}`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getFleetChartData = async (vehicleId, startDate, endDate) => {
  try {
    const convertedDates = convertStringDate(startDate, endDate);
    const response = await api.get(`/telemetry-data?vehicleId=${vehicleId}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}&fields=time,height,rpm,ignition_key,total_fuel_consumption_high_resolution,can_speed,can_fuel_level,ignition_key,acceleration_pedal_position,service_brake_pedal_status,gear,gps_speed,imei,lastBondPoint&fix-invalid-points=true&start=0&length=999999999999999`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getVehicleProfile = async (vehicleId) => {
  try {
    const response = await api.get(`/vehicles/vehicleProfile?vehicleId=${vehicleId}`);
    return response;
  } catch (err) {
    return err;
  }
}
