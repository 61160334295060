import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { Grid, Typography } from "@mui/material";

import DieselControlSkeleton from "components/Skeletons/DieselControlSkeleton";
import { ModalDieselControl } from "componentsNew/organisms/modalDieselControl/modalDieselControl";
import PageToolbar from "components/PageToolbar";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import StatusEmpty from "componentsNew/atoms/statusEmpty/statusEmpty";
import TableResponsive from "components/TableResponsive";
import { TitlePage } from "components/TitlePage";

import { orderVehiclesByCrescent } from "helpers/vehicles";
import { getApproximateDate } from "helpers/fleetChart";
import { convertDateStringToDB } from "helpers/dates";

import { useWindowSize } from "hooks/useWindowsSize";
import { useAppDispatch, useAppSelector } from "store";
import { setVehicleFilters, clearVehicleFilters } from "store/features/vehicleDashboardSlice";

import {
  getFleetLocations,
} from "services/fleetPerformance";
import { getVehiclesByOperation } from "services/vehicle";
import { getActualCheckMultiple } from "services/fuel";

import ImageNewFeature from '../../../assets/images/controlDiesel.png'
import { makeColumns } from "./tableColumns";
import NewFeatureModal from "components/ModalNewFeature";
import useStyles from "./styles";

const CurrentDieselControl = () => {
  const dispatch = useAppDispatch();
  const size = useWindowSize();
  const { filters } = useAppSelector((state) => state.vehicleDashboard);
  const [openNewFeature, setNewFeature] = useState(false);
  const [dieselData, setDieselData] = useState(null);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [loadingModalMap, setLoadingModalMap] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dbDateFormat = "yyyy-MM-dd'T'HH:mm:ssXX";
  const [selectedDates, setSelectedDates] = useState({
    initialDate: convertDateStringToDB(new Date().setHours(0, 0, 0, 0)), 
    finalDate: convertDateStringToDB(new Date().setHours(23, 59, 59, 999)), 
  });
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState(null);
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const classes = useStyles();
  
  const maxDate = new Date();
  maxDate.setHours(23, 59, 0, 0);
  const isSideMenuOnDashboard = window.location.pathname.includes('dashboard');

  const handleModal = (open) => {
    setShowModal(open);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const idVehicles = selectedVehicles?.map(item => item?.id) || filters?.vehicles?.map(item => item?.id);
  
      if (idVehicles?.length > 0) {
        const response = await getActualCheckMultiple(
          idVehicles,
          selectedDates?.initialDate ?? filters.selectedDates.initialDate,
          selectedDates.finalDate ?? filters.selectedDates.finalDate,
        );
        if (!response?.data.success) {
          throw new Error("Erro ao carregar dados");
        }
  
        const processedData = response.data?.data?.map(item => ({
          ...item,
          plate: `${item.plate}${item.identification ? ` / ${item.identification}` : ''}`
        })) || [];
        
        const orderedFuelHistory = processedData.sort((a, b) => 
          a.actualFuelData.actualVolume.Percentage - b.actualFuelData.actualVolume.Percentage
        );

  
        setDieselData(orderedFuelHistory);
      }
    } catch (err) {
      setDieselData(null);
      dispatch(clearVehicleFilters());
    } finally {
      setLoading(false);
    }
  };
  

  const fetchLocations = async (selectedDate, vehicleId, vehicleIdentification) => {
    setLoadingModalMap(true);
    try {
      // Subtract 10 min to startDate and add 10 min to endDate
      let startDateLocation = new Date(selectedDate);
      let endDateLocation = new Date(selectedDate);
      startDateLocation.setTime(startDateLocation.getTime() - 10 * 60 * 1000);
      endDateLocation.setTime(endDateLocation.getTime() + 10 * 60 * 1000);
      const response = await getFleetLocations(
        [String(vehicleId)],
        format(startDateLocation, dbDateFormat),
        format(endDateLocation, dbDateFormat),
      );
      if (response.data.locations.length) {
        const dateStringArray = response.data?.locations[0]?.locations?.map(
          (item) => {
            return {
              date: item[0],
              lat: item[1],
              lon: item[2],
            };
          },
        );
        const location = getApproximateDate(dateStringArray, selectedDate);
        setLocations([
          {
            device: vehicleIdentification,
            locations: [location],
          },
        ]);
      } else {
        setLocations([]);
      }
    } catch (err) {
      setLocations([]);
    } finally {
      setLoadingModalMap(false);
    }
  };

  const showDetails = (selectedDate, vehicleId, vehicleIdentification) => {
    fetchLocations(selectedDate, vehicleId, vehicleIdentification);
    handleModal(true);
    if (!isSideMenuOnDashboard) {
      const selectedFilters = {
        vehicle: selectedVehicles,
        selectedDates: selectedDates,
      };
      dispatch(setVehicleFilters(selectedFilters));
    }
  };

  const columns = makeColumns(size, showDetails);

  const handleSelectDate = async (initialDate, finalDate) => {
    const formattedInitialDate = convertDateStringToDB(initialDate);
    const formattedFinalDate = convertDateStringToDB(finalDate);
  
    setSelectedDates({
      initialDate: formattedInitialDate,
      finalDate: formattedFinalDate,
    });
  
    const selectedFilters = {
      vehicles: null,
      selectedDates: {
        initialDate: formattedInitialDate,
        finalDate: formattedFinalDate,
      },
    };
  
    dispatch(setVehicleFilters(selectedFilters));
  };
  const fetchVehicles = async () => {
    setLoadingOptions(true);
    try {
      const response = await getVehiclesByOperation(currentCustomer);
      if ((filters?.vehicles) && response.data.customers.length > 0) {
        const stateVehicleId = filters?.vehicle?.[0]?.id;
        const returnData = response.data.customers;
        returnData.map((o) =>
          o.vehicles.filter((truck) => {
            const vehicleId = stateVehicleId || "";
            if (truck.id === vehicleId) {
              const model = {
                ...truck,
                operation: { id: o.id, name: o.name },
              };
            }
          }),
        );
      }
      let orderedVehiclesByCustomer = response.data.customers.sort(
        (a, b) => (a.name > b.name) - (a.name < b.name)
      );
      orderedVehiclesByCustomer.map((option, i) => {
        const optionVehicles = option.vehicles.filter(item => item.devicesHistory !== null)
        orderedVehiclesByCustomer[i].vehicles = orderVehiclesByCrescent(optionVehicles);
      });
      setVehicles(orderedVehiclesByCustomer);
    } catch (err) {
      setVehicles([]);
      toast.error(
        "Erro ao carregar lista de Veículos. Entre em contato com o suporte.",
      );
    } finally {
      setLoadingOptions(false);
    }
  }

  const handleSelectedVehicles = async (selected) => {
    if (selected.length > 0) {
      try {
        setSelectedVehicles(selected);
        const selectedFilters = {
          vehicles: selected,
          selectedDates: selectedDates,
        };
        dispatch(setVehicleFilters(selectedFilters));
      } catch (error) {
        setDieselData(null);
        toast.error(
          "Erro ao carregar informações do veículo. Por favor, entre em contato com o suporte.",
        );
        setLoading(false);
      }
    } else {
      setSelectedVehicles(null);
      setDieselData(null);
      dispatch(clearVehicleFilters());
    }
  };

  useEffect(() => {
    if (!selectedVehicles && filters.vehicles) {
      setSelectedDates({
        initialDate: filters?.selectedDates.initialDate,
        finalDate: filters?.selectedDates.finalDate,
      });
      const item = filters?.vehicles[0][0]?.id ? [filters?.vehicles[0][0]] : filters?.vehicles;
      setSelectedVehicles(item)
    }
  }, [filters]);

  useEffect(() => {
    if (selectedVehicles?.length > 0 && selectedDates && !loading) {
      fetchData();
    }
  }, [selectedVehicles, selectedDates]);

  useEffect(() => {
    if (!isSideMenuOnDashboard) {
      const getItemNewFeature = localStorage.getItem("@newFeatureDieselControl") !== 'true'
      setNewFeature(getItemNewFeature)
    }
  }, []);

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ["can_view_dashboard"] })) {
      fetchVehicles();
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (vehicles?.length && !selectedVehicles) {
      const allVehicles = vehicles.flatMap(operation => operation.vehicles);
  
      if (allVehicles.length) {
        setSelectedVehicles(allVehicles);
        const selectedFilters = {
          vehicles: null,
          selectedDates: selectedDates,
        };
        dispatch(setVehicleFilters(selectedFilters));
      }
    }
  }, [vehicles, selectedVehicles]);
  

  return (
    <PermissionsGate scopes={["can_view_diesel_control"]}>
      <NewFeatureModal
        title='Explore a nova funcionalidade de Nível do tanque atual'
        description='Essa funcionalidade oferece a você uma visão detalhada dos registros de abastecimento da sua frota dentro de um período específico, permitindo uma análise abrangente e a identificação de possíveis inconsistências no tanque de combustível.'
        image={ImageNewFeature}
        topics={[
          {
            content: 'Fácil análise dos abastecimentos dentro do período que você desejar',
          },
          {
            content: 'Identificação de possíveis inconsistências no tanque de combustível',
          },
          {
            content: 'Informações detalhadas do local, data e hora de cada registro de abastecimento/inconsistência',
          },
          {
            content: 'Design limpo e fácil de usar',
          },
          {
            content: 'Dados e insights já calculados otimizando o seu tempo',
          }
        ]}
        open={openNewFeature}
        handleClose={() => {
          setNewFeature(false)
          localStorage.setItem('@newFeatureDieselControl', true)
        }}
      />
      {showModal && locations && (
        <ModalDieselControl
          showModal={showModal}
          handleModal={handleModal}
          loadingModalMap={loadingModalMap}
          showMapOnly
          locations={locations}
        />
      )}
      <Grid container>
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
          {!isSideMenuOnDashboard ?
            <>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: 4, paddingRight: 24, marginTop: 10 }}
              >
                <Typography
                  variant="h2"
                  className={classes.titlePage}                >
                  Nível do tanque atual
                </Typography>
                <span style={{ fontFamily: 'Roboto', size: 16, fontWeight: 400 }}>
                  Visualize detalhes sobre os bastecimentos e inconsistências dos
                  seus veículos
                </span>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={8}
                sm={12}
                xs={12}
                style={{ padding: 4, marginTop: 10 }}
                justifyContent={'flex-start'}
              >
                {(selectedDates?.initialDate) && (
                  <PageToolbar
                    isDetail={false}
                    handleSelectDate={handleSelectDate}
                    vehicleProps={{}}
                    selectedDates={selectedDates}
                    listData={vehicles}
                    selectedData={selectedVehicles}
                    handleSelectedData={handleSelectedVehicles}
                    vehiclesMenuButton
                    isAllSelected
                  />
                )}
              </Grid>
            </>
            :
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ padding: 4, marginTop: 10 }}
              >
                <TitlePage title={"Nível do tanque atual"} />
              </Grid>
            }
            {loading || loadingOptions ? <DieselControlSkeleton /> : dieselData ? (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <TableResponsive
                  tableBodyMaxHeight={
                    size.mobile
                      ? size.height - 430 + "px"
                      : size.height - 330 + "px"
                  }
                  data={dieselData}
                  options={{
                    rowsPerPage: 10,
                  }}
                  columns={columns}
                  tableName="diesel-details"
                />
              </Grid>
            ) : (
              <StatusEmpty message="Nenhum dado de combustível disponível" />
            )}

        </Grid>
      </Grid>
    </PermissionsGate>
  );
};

export default CurrentDieselControl;
