import React, { useMemo } from "react";
import { Switch, withRouter } from "react-router-dom";
import classnames from "classnames";

// components
import Aux from "hoc/auxiliar";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import { PrivateRoute } from 'components/PrivateRoute';

// pages
import Dashboard from "pages/dashboard/general";
import Comparison from "pages/dashboard/comparison";
import DieselControl from "pages/dashboard/dieselControl";
import CurrentDieselControl from "pages/dashboard/currentDieselControl";

import {
  CreateOperation,
  EditOperation,
  OperationList,
  OperationListHistory,
} from "pages/maintenance/operations";

import AssignDriver from "pages/maintenance/assignDriver";
import LinkDrivers from "pages/maintenance/linkdrivers";
import HistoricLinkDrivers from "pages/maintenance/historicLinkDrivers";
import TelemetryData from "pages/telemetry-data";
import DevicesData from "pages/devices-data";
import Follow from "pages/bonus/follow";
import RewardDetails from "pages/bonus/Details";
import Co2Emission from "pages/co2";

import AddDriver from "pages/driver/addDriver/addDriver";
import AllDrivers from "pages/driver/allDrivers/AllDrivers";
import EditDriver from "pages/driver/addDriver/editDriver";
import MassAddDriver from "pages/driver/addDriver/massAddDriver";
import MassAddLinkDrivers from "pages/maintenance/linkdrivers/MassAddLinkDrivers";
import MassLinkChassisToLogin from "pages/users/formUser/MassLinkChassisToLogin";
import ListRecalculationDriver from "pages/driver/recalculation/ListRecalculationDriver";
import ListRecalculationDriverDetails from "pages/driver/recalculation/ListRecalculationDriverDetails";

import { CustomerList, CreateCustomer, EditCustomer, ViewCustomer } from "pages/customers";

import AddDevice from "pages/device/formDevice/AddDevice";
import EditDevice from "pages/device/formDevice/EditDevice";
import ListDevices from "pages/device/listDevices/ListDevices";
import MassAddDevice from "pages/device/formDevice/MassAddDevice";

import AddVehicle from "pages/vehicle/formVehicle/AddVehicle";
import EditVehicle from "pages/vehicle/formVehicle/EditVehicle";
import ViewVehicle from "pages/vehicle/viewVehicle/ViewVehicle";
import ListVehicles from "pages/vehicle/listVehicles/ListVehicles";
import MassAddVehicle from "pages/vehicle/formVehicle/MassAddVehicle";
import ListRecalculation from "pages/vehicle/recalculation/ListRecalculation";
import ListRecalculationDetails from "pages/vehicle/recalculation/ListRecalculationDetails";

import AddProfile from "pages/profile/formProfile/AddProfile";
import ViewProfile from "pages/profile/viewProfile/ViewProfile";
import ListProfiles from "pages/profile/listProfiles/ListProfiles";

import AddRewardGroup from "pages/rewardGroup/addRewardGroup";
import ListRewardGroup from "pages/rewardGroup/RewardGroupList";
import EditRewardGroup from "pages/rewardGroup/editRewardGroup";
import ViewRewardGroud from "pages/rewardGroup/viewRewardGroup";

import ListUsers from "pages/users/listUsers/ListUsers";
import AddUser from "pages/users/formUser/AddUser";
import EditUser from "pages/users/formUser/EditUser";
import ViewUser from "pages/users/viewUser/ViewUser";
import ChangePassword from "pages/users/changePassword";

import ListRoles from "pages/role/listRoles";
import AddRole from "pages/role/form/AddRole";
import EditRole from "pages/role/form/EditRole";

import ListDriverRoles from "pages/role/listDriverRoles";
import AddDriverRole from "pages/role/formDriver/AddRole";
import EditDriverRole from "pages/role/formDriver/EditRole";

import DriversMonitoring from "pages/driversMonitoring/follow";
import DriversMonitoringDetails from "pages/driversMonitoring/details";
import DriversMonitoringTripList from "pages/driversMonitoring/tripList";

import ManageNotification from "pages/manageNotification";

import VehicleDevices from "pages/vehicle-device";
import HistoricDevices from "pages/device/historicDevices";
import HistoricProfiles from "pages/profile/historicProfiles";
import HistoricVehicles from "pages/vehicle/historicVehicles";
import Evolutive from "pages/dashboard/evolutive";
import Consolidated from "pages/consolidated";
import LiveMap from "pages/liveMap/liveMap";
import LiveMapDetails from "pages/liveMapDetails/liveMapDetails";
import NotificationHistory from "pages/notificationHistory";
import Communication from "pages/communication";
import GeneralInsight from "pages/generalInsight";

// context
import { useLayoutState } from "context/LayoutContext";

// styles
import useStyles from "./styles";

function Layout(props) {
  const classes = useStyles();

  // global
  const layoutState = useLayoutState();

  const headerHeight = useMemo(() => layoutState.headerHeight, [layoutState.headerHeight]);

  return (
    <div className={classes.root}>
      <Aux>
        <Header history={props.history} />
        <Sidebar />
        <div
          id="drawer-container"
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
          style={{ paddingTop: headerHeight ?? 80 }}
        >
          <Switch>
            <PrivateRoute
              path="/gofurther/dashboard/general"
              component={Dashboard}
            />
            <PrivateRoute
              path="/gofurther/dashboard/dieselControl"
              component={DieselControl}
            />
            <PrivateRoute
              path="/gofurther/currentDieselControl"
              component={CurrentDieselControl}
            />
            <PrivateRoute
              path="/gofurther/dashboard/evolutive"
              component={Evolutive}
            />
            <PrivateRoute
              path="/gofurther/liveMap"
              component={LiveMap}
            />
            <PrivateRoute
              path="/gofurther/liveMapDetails"
              component={LiveMapDetails}
            />
            <PrivateRoute
              path="/gofurther/consolidated"
              component={Consolidated}
            />
            <PrivateRoute
              path="/gofurther/c02-emission"
              component={Co2Emission}
            />
            <PrivateRoute
              path="/gofurther/dashboard/comparison"
              component={Comparison}
            />
            <PrivateRoute
              exact
              path="/gofurther/maintenance/operations/operationList"
              component={OperationList}
            />
            <PrivateRoute
              exact
              path="/gofurther/maintenance/operations/operationListHistory"
              component={OperationListHistory}
            />
            <PrivateRoute
              exact
              path="/gofurther/maintenance/operations/createOperation"
              component={CreateOperation}
            />
            <PrivateRoute
              exact
              path="/gofurther/maintenance/operations/editOperation/:id"
              component={EditOperation}
            />
            <PrivateRoute
              exact
              path="/gofurther/maintenance/linkdrivers"
              component={LinkDrivers}
            />
            <PrivateRoute
              exact
              path="/gofurther/vehicle/mass-add-link-drivers"
              component={MassAddLinkDrivers}
            />
            <PrivateRoute
              exact
              path="/gofurther/user/form-user/mass-link-chassis-to-login/:selectedEmail"
              component={MassLinkChassisToLogin}
            />
            <PrivateRoute
              exact
              path="/gofurther/maintenance/historicLinkDrivers"
              component={HistoricLinkDrivers}
            />
            <PrivateRoute
              exact
              path="/gofurther/maintenance/assignDriver"
              component={AssignDriver}
            />
            <PrivateRoute
              exact
              path="/gofurther/bonus/follow"
              component={Follow}
            />
            <PrivateRoute
              exact
              path="/gofurther/bonus/follow/details"
              component={RewardDetails}
            />
            <PrivateRoute
              exact
              path="/gofurther/telemetrydata"
              component={TelemetryData}
            />
            <PrivateRoute
              exact
              path="/gofurther/devices-data"
              component={DevicesData}
            />
            <PrivateRoute
              exact
              path="/gofurther/driver/all-drivers"
              component={AllDrivers}
            />
            <PrivateRoute
              exact
              path="/gofurther/driver/add-driver"
              component={AddDriver}
            />
            <PrivateRoute
              exact
              path="/gofurther/driver/edit/:driverId"
              component={EditDriver}
            />
            <PrivateRoute
              exact
              path="/gofurther/user/changePassword"
              component={ChangePassword}
            />
            <PrivateRoute
              exact
              path="/gofurther/driver/mass-add-driver"
              component={MassAddDriver}
            />

            <PrivateRoute
              exact
              path="/gofurther/driver/recalculation"
              component={ListRecalculationDriver}
            />
            <PrivateRoute
              exact
              path="/gofurther/driver/recalculation/:id"
              component={ListRecalculationDriverDetails}
            />

            <PrivateRoute
              exact
              path="/gofurther/customer/customer-list"
              component={CustomerList}
            />
            <PrivateRoute
              exact
              path="/gofurther/customer/create-customer"
              component={CreateCustomer}
            />
            <PrivateRoute
              exact
              path="/gofurther/customer/edit-customer/:id"
              component={EditCustomer}
            />
            <PrivateRoute
              exact
              path="/gofurther/customers/view-customer/:id"
              component={ViewCustomer}
            />
            <PrivateRoute
              exact
              path="/gofurther/device/form-device"
              component={AddDevice}
            />
            <PrivateRoute
              exact
              path="/gofurther/device/form-device/:deviceId"
              component={EditDevice}
            />
            <PrivateRoute
              exact
              path="/gofurther/device/list-devices"
              component={ListDevices}
            />
            <PrivateRoute
              exact
              path="/gofurther/device/historic-devices/:deviceId"
              component={HistoricDevices}
            />
            <PrivateRoute
              exact
              path="/gofurther/device/vehicle-devices"
              component={VehicleDevices}
            />
            <PrivateRoute
              exact
              path="/gofurther/general-insights"
              component={GeneralInsight}
            />
            <PrivateRoute
              exact
              path="/gofurther/device/mass-add-device"
              component={MassAddDevice}
            />
            <PrivateRoute
              exact
              path="/gofurther/vehicle/form-vehicle"
              component={AddVehicle}
            />
            <PrivateRoute
              exact
              path="/gofurther/vehicle/historic-vehicle/:vehicleId"
              component={HistoricVehicles}
            />
            <PrivateRoute
              exact
              path="/gofurther/vehicle/form-vehicle/:vehicleId"
              component={EditVehicle}
            />
            <PrivateRoute
              exact
              path="/gofurther/vehicle/view-vehicle/:vehicleId"
              component={ViewVehicle}
            />
            <PrivateRoute
              exact
              path="/gofurther/vehicle/list-vehicles"
              component={ListVehicles}
            />
            <PrivateRoute
              exact
              path="/gofurther/vehicle/mass-add-vehicle"
              component={MassAddVehicle}
            />
            <PrivateRoute
              exact
              path="/gofurther/vehicle/recalculation"
              component={ListRecalculation}
            />
            <PrivateRoute
              exact
              path="/gofurther/vehicle/recalculation/:id"
              component={ListRecalculationDetails}
            />
            <PrivateRoute
              exact
              path="/gofurther/profile/form-profile"
              component={AddProfile}
            />
            <PrivateRoute
              exact
              path="/gofurther/profile/form-profile/:profileId"
              component={AddProfile}
            />
            <PrivateRoute
              exact
              path="/gofurther/profile/view-profile/:profileId"
              component={ViewProfile}
            />
            <PrivateRoute
              exact
              path="/gofurther/profile/list-profiles"
              component={ListProfiles}
            />
            <PrivateRoute
              exact
              path="/gofurther/profile/historic-profiles/:modelId"
              component={HistoricProfiles}
            />
            <PrivateRoute
              exact
              path="/gofurther/rewardGroup/add-rewardGroup"
              component={AddRewardGroup}
            />
            <PrivateRoute
              exact
              path="/gofurther/rewardGroup/list-rewardGroup"
              component={ListRewardGroup}
            />
            {/* Route Users */}
            <PrivateRoute
              exact
              path="/gofurther/user/list-users"
              component={ListUsers}
            />
            <PrivateRoute
              exact
              path="/gofurther/user/form-user"
              component={AddUser}
            />
            <PrivateRoute
              exact
              path="/gofurther/user/form-user/:userID"
              component={EditUser}
            />
            <PrivateRoute
              exact
              path="/gofurther/user/view-user/:userID"
              component={ViewUser}
            />
            <PrivateRoute
              exact
              path="/gofurther/rewardGroup/edit/:id"
              component={EditRewardGroup}
            />
            <PrivateRoute
              exact
              path="/gofurther/rewardGroup/view-reward-group/:id"
              component={ViewRewardGroud}
            />

            <PrivateRoute
              exact
              path="/gofurther/role/list-roles"
              component={ListRoles}
            />
            <PrivateRoute
              exact
              path="/gofurther/role/form-role"
              component={AddRole}
            />
            <PrivateRoute
              exact
              path="/gofurther/role/form-role/:id"
              component={EditRole}
            />
            <PrivateRoute
              exact
              path="/gofurther/role/list-roles-app"
              component={ListDriverRoles}
            />
            <PrivateRoute
              exact
              path="/gofurther/role/form-app-role"
              component={AddDriverRole}
            />
            <PrivateRoute
              exact
              path="/gofurther/role/form-app-role/:id"
              component={EditDriverRole}
            />
            <PrivateRoute
              exact
              path="/gofurther/driversMonitoring"
              component={DriversMonitoring}
            />
            <PrivateRoute
              exact
              path="/gofurther/driversMonitoring/details"
              component={DriversMonitoringDetails}
            />
            <PrivateRoute
              exact
              path="/gofurther/driversMonitoring/tripList"
              component={DriversMonitoringTripList}
            />
            <PrivateRoute
              exact
              path="/gofurther/communication"
              component={Communication}
            />
            <PrivateRoute
              exact
              path="/gofurther/manageNotification"
              component={ManageNotification}
            />
            <PrivateRoute
              exact
              path="/gofurther/notificationHistory"
              component={NotificationHistory}
            />
            <PrivateRoute
              exact
              path="/gofurther/dieselControl"
              component={DieselControl}
            />
          </Switch>
        </div>
      </Aux>
    </div>
  );
}

export default withRouter(Layout);
