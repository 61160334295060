import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";

// assets
import ImageNewFeature from 'assets/images/controlDiesel.png';

// store
import { useAppDispatch, useAppSelector } from "store";
import { setVehicleFilters, clearVehicleFilters } from "store/features/vehicleDashboardSlice";

// services
import {
  getFleetLocations,
  getFleetChartData,
  getVehicleProfile,
} from "services/fleetPerformance";
import { getVehiclesByOperation } from "services/vehicle";
import { getFuelCheckMultiple } from "services/fuel";

// helpers
import { orderVehiclesByCrescent } from "helpers/vehicles";
import { getApproximateDate, parseChartData } from "helpers/fleetChart";

// components
import DieselControlInfo from "componentsNew/organisms/dieselControlInfo";
import StatusEmpty from "componentsNew/atoms/statusEmpty/statusEmpty";
import Card from "componentsNew/molecules/card/card";

import DieselControlSkeleton from "components/Skeletons/DieselControlSkeleton";
import { ModalDieselControl } from "componentsNew/organisms/modalDieselControl/modalDieselControl";
import PageToolbar from "components/PageToolbar";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import TableResponsive from "components/TableResponsive";
import { TitlePage } from "components/TitlePage";
import NewFeatureModal from "components/ModalNewFeature";

import { makeColumns } from "./tableColumns";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

// styles
import "./dieselControl.css";


const DieselControl = () => {
  const dispatch = useAppDispatch();
  const size = useWindowSize();
  const { filters } = useAppSelector((state) => state.vehicleDashboard);
  const [chartData, setChartData] = useState([]);
  const [openNewFeature, setNewFeature] = useState(false);
  const [dieselData, setDieselData] = useState(null);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [loadingModalMap, setLoadingModalMap] = useState(false);
  const [loadingModalChart, setLoadingModalChart] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dbDateFormat = "YYYY-MM-DDTHH:mm:ssZZ";
  const [selectedDates, setSelectedDates] = useState({
    initialDate: dayjs().format("YYYY-MM-DDT00:00:00ZZ"),
    finalDate: dayjs().format("YYYY-MM-DDT23:59:59ZZ"),
  });
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState(null);
  const { currentCustomer } = useAppSelector((state) => state.global.user);

  const maxDate = new Date();
  maxDate.setHours(23, 59, 0, 0);
  const dateFormatTemplate = "YYYY-MM-DDTHH:mm:ssZZ";
  const isSideMenuOnDashboard = window.location.pathname.includes('dashboard');

  const noIndicatorsChartToShow = () => {
    toast.error(
      "Algo inexperado aconteceu. Contate o suporte.",
    );
  };

  const handleModal = (open) => {
    setShowModal(open);
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const idVehicles = selectedVehicles?.map(item => item?.id) || filters?.vehicles?.map(item => item?.id);

      if (idVehicles?.length > 0) {
        const response = await getFuelCheckMultiple(
          idVehicles,
          selectedDates?.initialDate ?? filters.selectedDates.initialDate,
          selectedDates.finalDate ?? filters.selectedDates.finalDate,
          currentCustomer
        );

        if (!response?.data.success) {
          throw new Error("Erro ao carregar dados");
        }

        const responseData = response.data?.data;

        let returnData = {
          totalFillEvents: responseData?.totalFillEvents || 0,
          totalInconsistencyEvents: responseData?.totalInconsistencyEvents || 0,
          totalLitersFilled: responseData?.totalLitersFilled || 0,
          multipleFuelHistory: [],
          lastEntry: responseData?.multipleFuelHistory[0]?.fuelSupplyHistories?.lastEntry || {}
        };

        responseData.multipleFuelHistory && responseData.multipleFuelHistory.forEach(item => {
          const fuelSupplyHistories = item.fuelSupplyHistories?.fuelEntries;

          fuelSupplyHistories && fuelSupplyHistories.map(entry => {
            returnData.multipleFuelHistory.push({
              id: item.vehicleID,
              identification: item?.identification || "",
              event: entry?.event,
              date: entry?.date,
              odometer: entry?.odometer || 0,
              previousVolume: entry?.previousVolume || {},
              differenceVolume: entry?.differenceVolume || {},
              actualVolume: entry?.actualVolume || {}
            });
          })
        })

        setDieselData(prevState => ({ ...returnData }));
      }
    } catch (err) {
      console.log('err', err);
      setDieselData(null);
      dispatch(clearVehicleFilters());
    } finally {
      setLoading(false);
    }
  };

  const fetchLocations = async (selectedDate, vehicleId, vehicleIdentification) => {
    setLoadingModalMap(true);

    try {
      // Subtract 10 min to startDate and add 10 min to endDate
      let startDateLocation = new Date(selectedDate);
      let endDateLocation = new Date(selectedDate);

      startDateLocation.setTime(startDateLocation.getTime() - 10 * 60 * 1000);
      endDateLocation.setTime(endDateLocation.getTime() + 10 * 60 * 1000);

      const response = await getFleetLocations(
        [String(vehicleId)],
        dayjs(startDateLocation).format(dbDateFormat),
        dayjs(endDateLocation).format(dbDateFormat),
      );

      if (response.data.locations.length) {
        const dateStringArray = response.data?.locations[0]?.locations?.map(
          (item) => {
            return {
              date: item[0],
              lat: item[1],
              lon: item[2],
            };
          },
        );

        const location = getApproximateDate(dateStringArray, selectedDate);

        setLocations([
          {
            device: vehicleIdentification,
            locations: [location],
          },
        ]);
      } else {
        setLocations([]);
      }
    } catch (err) {
      setLocations([]);
    } finally {
      setLoadingModalMap(false);
    }
  };

  const fetchChart = async (selectedDate, vehicleId) => {
    setLoadingModalChart(true);

    try {
      const vehicle = selectedVehicles ? selectedVehicles.filter(item => item.id === vehicleId)[0] : filters.vehicles.filter(item => item.id === vehicleId)[0];
      const vehicleProfile = await getVehicleProfile(vehicle.id);

      if (!vehicleProfile?.data) {
        throw new Error("failed to fetch chart data");
      }

      let initialDate = new Date(selectedDate);
      let finalDate = new Date(selectedDate);

      initialDate.setTime(initialDate.getTime() - 1 * 60 * 60 * 1000);
      finalDate.setTime(finalDate.getTime() + 1 * 60 * 60 * 1000);
      
      initialDate = dayjs(initialDate).format(dbDateFormat);
      finalDate = dayjs(finalDate).format(dbDateFormat)

      const response = await getFleetChartData(
        vehicle.id,
        initialDate,
        finalDate,
      );

      if (!response?.data || !response?.data?.data) {
        throw new Error("failed to fetch chart data");
      }

      const parsedChartData = parseChartData(
        response.data.data,
        vehicleProfile.data,
        vehicle,
        vehicleProfile,
        initialDate,
        finalDate
      );
      setChartData(parsedChartData);
    } catch (err) {
      setChartData([]);
      noIndicatorsChartToShow();
      console.log(err);
    } finally {
      setLoadingModalChart(false);
    }
  };

  const showDetails = (selectedDate, vehicleId, vehicleIdentification) => {
    fetchChart(selectedDate, vehicleId);
    fetchLocations(selectedDate, vehicleId, vehicleIdentification);
    handleModal(true);

    if (!isSideMenuOnDashboard) {
      const selectedFilters = {
        vehicle: selectedVehicles,
        selectedDates: selectedDates,
      };

      dispatch(setVehicleFilters(selectedFilters));
    }
  };

  const columns = makeColumns(size, showDetails);

  const handleSelectDate = async (initialDate, finalDate) => {
    setSelectedDates({
      initialDate: dayjs(initialDate).format(dateFormatTemplate),
      finalDate: dayjs(finalDate).format(dateFormatTemplate),
    });

    const selectedFilters = {
      vehicle: filters.vehicles,
      selectedDates: {
        initialDate: dayjs(initialDate).format(dateFormatTemplate),
        finalDate: dayjs(finalDate).format(dateFormatTemplate),
      },
    };

    dispatch(setVehicleFilters(selectedFilters));
  };

  const fetchVehicles = async () => {
    setLoadingOptions(true);

    try {
      const response = await getVehiclesByOperation(currentCustomer);

      if ((filters?.vehicles) && response.data.customers.length > 0) {
        const stateVehicleId = filters?.vehicle?.[0]?.id;
        const returnData = response.data.customers;

        returnData.map((o) =>
          o.vehicles.filter((truck) => {
            const vehicleId = stateVehicleId || "";

            if (truck.id === vehicleId) {
              const model = {
                ...truck,
                operation: { id: o.id, name: o.name },
              };
            }
          }),
        );
      }

      let orderedVehiclesByCustomer = response.data.customers.sort(
        (a, b) => (a.name > b.name) - (a.name < b.name)
      );

      orderedVehiclesByCustomer.map((option, i) => {
        const optionVehicles = option.vehicles.filter(item => item.devicesHistory !== null);
        
        orderedVehiclesByCustomer[i].vehicles = orderVehiclesByCrescent(optionVehicles);
      });

      setVehicles(orderedVehiclesByCustomer);
    } catch (err) {
      setVehicles([]);

      toast.error(
        "Erro ao carregar lista de Veículos. Entre em contato com o suporte.",
      );
    } finally {
      setLoadingOptions(false);
    }
  }

  const handleSelectedVehicles = async (selected) => {
    if (selected.length > 0) {
      try {
        setSelectedVehicles(selected);

        const selectedFilters = {
          vehicles: selected,
          selectedDates: selectedDates,
        };

        dispatch(setVehicleFilters(selectedFilters));
      } catch (error) {
        setDieselData(null);

        toast.error(
          "Erro ao carregar informações do veículo. Por favor, entre em contato com o suporte.",
        );

        setLoading(false);
      }
    } else {
      setSelectedVehicles(null);
      setDieselData(null);
      dispatch(clearVehicleFilters());
    }
  };

  useEffect(() => {
    if (!selectedVehicles && filters.vehicles) {
      setSelectedDates({
        initialDate: filters?.selectedDates.initialDate,
        finalDate: filters?.selectedDates.finalDate,
      });

      const item = filters?.vehicles[0][0]?.id ? [filters?.vehicles[0][0]] : filters?.vehicles;

      setSelectedVehicles(item)
    }
  }, [filters]);

  useEffect(() => {
    if (selectedVehicles?.length > 0 && selectedDates && !loading) {
      fetchData();
    }
  }, [selectedVehicles, selectedDates]);

  useEffect(() => {
    if (!isSideMenuOnDashboard) {
      const getItemNewFeature = localStorage.getItem("@newFeatureDieselControl") !== 'true'
      setNewFeature(getItemNewFeature)
    }
  }, []);

  useEffect(() => {
    if (currentCustomer && hasPermission({ scopes: ["can_view_dashboard"] })) {
      fetchVehicles();
    }
  }, [currentCustomer]);

  return (
    <PermissionsGate scopes={["can_view_diesel_control"]}>
      <NewFeatureModal
        title='Explore a nova funcionalidade de Gestão do nível de combustível'
        description='Essa funcionalidade oferece a você uma visão detalhada dos registros de abastecimento da sua frota dentro de um período específico, permitindo uma análise abrangente e a identificação de possíveis inconsistências no tanque de combustível.'
        image={ImageNewFeature}
        topics={[
          {
            content: 'Fácil análise dos abastecimentos dentro do período que você desejar',
          },
          {
            content: 'Identificação de possíveis inconsistências no tanque de combustível',
          },
          {
            content: 'Informações detalhadas do local, data e hora de cada registro de abastecimento/inconsistência',
          },
          {
            content: 'Design limpo e fácil de usar',
          },
          {
            content: 'Dados e insights já calculados otimizando o seu tempo',
          }
        ]}
        open={openNewFeature}
        handleClose={() => {
          setNewFeature(false)
          localStorage.setItem('@newFeatureDieselControl', true)
        }}
      />
      {showModal && locations && (
        <ModalDieselControl
          showModal={showModal}
          handleModal={handleModal}
          loadingModalMap={loadingModalMap}
          loadingModalChart={loadingModalChart}
          chartData={chartData}
          locations={locations}
        />
      )}
      <Grid container>
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
          {!isSideMenuOnDashboard ?
            <>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: 4, paddingRight: 24, marginTop: 10 }}
              >
                <TitlePage title={"Gestão do nível de combustível"} />
                <span style={{ fontFamily: 'Roboto', fontSize: 13, fontWeight: 400 }}>
                  Visualize detalhes sobre os bastecimentos e inconsistências dos
                  seus veículos
                </span>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={8}
                sm={12}
                xs={12}
                style={{ padding: 4, marginTop: 10 }}
              >
                {(selectedDates?.initialDate) && (
                  <PageToolbar
                    isDetail={false}
                    handleSelectDate={handleSelectDate}
                    vehicleProps={{}}
                    selectedDates={selectedDates}
                    listData={vehicles}
                    selectedData={selectedVehicles}
                    handleSelectedData={handleSelectedVehicles}
                    menuCalendar
                    calendarMaxRanges={{
                      ...(selectedVehicles?.[0]?.devicesHistory && {
                        minDate: new Date(
                          selectedVehicles[0].devicesHistory.at(-1).startDate,
                        ),
                      }),
                      maxDate: maxDate,
                    }}
                    vehiclesMenuButton
                    isSingleMode={false}
                    initialDate={
                      selectedDates.initialDate
                    }
                    finalDate={
                      selectedDates.finalDate
                    }
                  />
                )}
              </Grid>
            </>
            :
            <>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ padding: 4, marginTop: 10 }}
              >
                <TitlePage title={"Gestão do nível de combustível"} />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {dieselData && (
                  <DieselControlInfo dieselData={dieselData} filters={filters} />
                )}
              </Grid>
            </>}
          {loading || loadingOptions ? <DieselControlSkeleton /> : dieselData ?
            <>
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                spacing={2}
                py={2}
              >
                <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                  {dieselData && (
                    <Card
                      value={dieselData?.totalFillEvents ?? 0}
                      dataCy={"card-total-fuel"}
                      title={"Abastecimento"}
                      description={
                        "Quantidade de abastecimentos realizados no período que você selecionou"
                      }
                    />
                  )}
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                  {dieselData && (
                    <Card
                      value={dieselData?.totalLitersFilled ?? 0}
                      dataCy={"card-total-fuel-full"}
                      title={"Total abastecido"}
                      description={
                        "Quantidade em litros de abastecimentos realizados no período que você selecionou"
                      }
                    />
                  )}
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                  {dieselData && (
                    <Card
                      style={dieselData?.totalInconsistencyEvents > 0 ? { border: '2px solid var(--cores-v-3-amarelo-primaria, #E2211C)' } : {}}
                      value={dieselData?.totalInconsistencyEvents ?? 0}
                      dataCy={"card-total-failed"}
                      title={"Inconsistências"}
                      description={
                        "Quantidade de quedas de combustivel com o veículo parado"
                      }
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {dieselData && (
                  <TableResponsive
                    tableBodyMaxHeight={
                      size.mobile
                        ? size.height - 430 + "px"
                        : size.height - 330 + "px"
                    }
                    data={dieselData?.multipleFuelHistory ?? []}
                    options={{
                      rowsPerPage: 10,
                    }}
                    columns={columns}
                    tableName="diesel-details"
                  />
                )}
              </Grid>
            </> :
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <StatusEmpty title={'Selecione os filtros para gerar informações'}
                description="Selecione um veículo clicando no filtro localizado no menu acima e em seguida o período desejado." />
            </Grid>}
        </Grid>
      </Grid>
    </PermissionsGate>
  );
};

export default DieselControl;