import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  paper: {
    padding: 16, 
    borderRadius: 16,
    marginTop: 16,
    height: 82,
    display: 'flex',
    justifyContent: 'center'
  },
  wrapperContent: {
    justifyContent: 'space-between', 
    alignItems: 'center',
  },
  textNormal: {
    color: theme.palette.text.title2,
    fontSize: '16px',
    fontWeight: 400
  },
  textHighlight: {
    color: theme.palette.text.dark,
    fontSize: '16px',
    fontWeight: 700
  },
  textIconTitle: {
    color: theme.palette.text.title2,
    fontSize: '16px',
    fontWeight: 700
  },
  icon: {
    marginRight: 4,
    color: theme.palette.text.title2
  },
  backButton: {
    borderRadius: 20,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  }
}));
