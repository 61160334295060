import React, { useState } from "react";
import { ArrowDropDown, ArrowDropUp, InfoOutlined } from "@mui/icons-material";
import PropTypes from "prop-types";

import "./popoverInfo.css";

const PopoverInfo = ({ children = null, title = "Clique e saiba como utilizar o gráfico" }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="PopoverInfo-container">
      <div
        onClick={() => {
          setOpen(!open);
        }}
        className={[`PopoverInfo-button`, `PopoverInfo-button-${open}`].join(
          " ",
        )}
      >
        <span className={`PopoverInfo-button-item`}>
          <InfoOutlined className={`PopoverInfo-button-icon-info`} />
        </span>
        <span className={`PopoverInfo-button-item`}>{title}</span>
        <span className={`PopoverInfo-button-item`}>
          {!open ? <ArrowDropDown /> : <ArrowDropUp />}
        </span>
      </div>
      <div className={[`PopoverInfo-modal-${open}`].join(" ")}>{children}</div>
    </div>
  );
};

export default PopoverInfo;

PopoverInfo.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
