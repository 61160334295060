import { useState } from "react";
import { Box, Dialog, DialogTitle, IconButton, Typography, Button, DialogContent } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import Icon from "@mdi/react";
import { mdiChevronLeft } from "@mdi/js";

import { useWindowSize } from "hooks/useWindowsSize";

import useStyles from "./styles";

export default function ModalCreateOperations({
  onClose,
  open,
  onSubmit,
  loading,
}) {
  const classes = useStyles();
  const size = useWindowSize();
  
  const [saveWithRecalc, setSaveWithRecalc] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="new-feature-dialog-title"
      aria-describedby="new-feature-dialog-description"
      maxWidth={"md"}
      fullWidth
      fullScreen={size.mobile}
      PaperProps={{ classes: { root: classes.dialogBase } }}
    >
      <div className={classes.upperButtons}>
        <Button
          onClick={() => {
            if (saveWithRecalc) {
              setSaveWithRecalc(false);
            } else {
              onClose();
            }
          }}
          className={classes.backButton}
        >
          <Icon path={mdiChevronLeft} className={classes.icon} size={1} />{" "}Voltar
        </Button>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Box className={classes.contentBox}>
        <DialogTitle>
          <Typography className={classes.textBold}>
            {saveWithRecalc
              ? "Escolha o período que deseja realizar o recálculo:"
              : "Escolha corretamente uma das opções de salvamento abaixo:"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className={classes.recalcOptionContainer}>
            {saveWithRecalc ? (
              <div className={classes.recalcTextContainer}>
                <Typography className={classes.description}>
                  Esta opção salva suas alterações e recalcula os dados do período selecionado. Recomendamos que utilize esta opção se o mês ainda não foi fechado. Lembre-se que após iniciar o recalculo, esta ação não pode ser desfeita.
                </Typography>
                <Typography className={classes.description}>
                  Caso você precise recalcular períodos ainda maiores, entre em contato com nosso suporte.
                </Typography>
              </div>
            ) : (
              <div style={{ marginBottom: 32 }}>
                <div className={classes.option}>
                  <Typography variant="h5" className={classes.optionTitle}>
                    Salvar sem Recalcular:
                  </Typography>
                  <Typography className={classes.description}>
                    Esta opção permite que você salve as alterações sem recalcular os dados existentes. Use esta opção se não deseja alterar os dados do passado de seus motoristas. As alterações poderão ser visualizadas somente no dia posterior.
                  </Typography>
                </div>
                <div className={classes.option}>
                  <Typography variant="h5" className={classes.optionTitle}>
                    Salvar e Recalcular dados:
                  </Typography>
                  <Typography className={classes.description}>
                    Esta opção salva suas alterações e recalcula os dados do período selecionado. Recomendamos que utilize esta opção se o mês de pagamento ainda não foi fechado. Lembre-se que após iniciar o recalculo, esta ação não pode ser desfeita.
                  </Typography>
                </div>
              </div>
            )}
            <>
              {saveWithRecalc ? (
                <div className={classes.actionButtons}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    className={classes.actionButton}
                    disabled={loading}
                    onClick={() => onSubmit({ recalculate_past: true, startDate: true })}
                  >
                    Recalcular mês passado e atual
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    className={classes.actionButton}
                    onClick={() => onSubmit({ recalculate_past: true })}
                  >
                    Recalcular mês atual
                  </Button>
                </div>
              ) : (
                <div className={classes.actionButtons}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    disabled={loading}
                    className={classes.actionButton}
                    onClick={() => setSaveWithRecalc(true)}
                  >
                    Salvar e recalcular
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    className={classes.actionButton}
                    onClick={() => onSubmit({ recalculate_past: false })}
                  >
                    Salvar sem recalcular
                  </Button>
                </div>
              )}
            </>
          </div>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
