import React from "react";
import { Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Rating from '@mui/material/Rating';
import StarIcon from '@material-ui/icons/Star';
import dayjs from "dayjs";

// helpers
import { makeValueStars } from "helpers/functions";

// styles
import makeStyles from "./styles";

const StyledEmptyStar = styled(StarIcon)(() => ({
  color: '#00000042',
  fontSize: '18px !important'
}));

export const makeColumns = ({ size }) => {
  const classes = makeStyles();

  const arranjo = [
    {
      name: "origin",
      label: "Origem",
      options: {
        customBodyRender: (value) => {
          if (!!value) {
            return (
              <Typography
                variant="body2"
                className={classes.textBold}
                noWrap={true}
                data-cy="thOrigem"
              >
                {value}
              </Typography>
            );
          }
        },
      },
    },
    {
      name: "destination",
      label: "Destino",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography
              variant="body2"
              className={classes.textBold}
              noWrap={true}
              data-cy="thDestino"
            >
              {value}
            </Typography>
          );
        },
      },
    },
    /*  {
       name: "consumptionAvg",
       label: "Média"
     }, */
    {
      name: "achievedMileage",
      label: "Dist. percorrida (km)",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" data-cy="thDistanciaPercorridaKM">{value}</Typography>;
        },
      },
    },
    /* {
      name: "consumption",
      label: "Consumo (L)"
    }, */
    {
      name: "score",
      label: "Nota geral",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" data-cy="thNotaGeral">{value}</Typography>;
        },
      },
    },
    {
      name: "idleScore",
      label: "Motor ligado parado",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thMotorLigadoParado">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "extraEconomicDrivingScore",
      label: "Início da faixa verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thInicioFaixaVerde">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "leveragingScore",
      label: "Aproveitamento de embalo",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thAproveitamentoEmbalo">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "powerScore",
      label: "Acelerando acima do verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thAcelerandoAcimaVerde">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "cruiseControlScore",
      label: "Piloto automático",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thPilotoAutomatico">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "defensiveDrivingScore",
      label: "Excesso de velocidade",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thExcessoVelocidade">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        searchable: false,
        filter: false,
        viewColumns: false,
        hideColumn: true,
      },
    },
  ];
  if (size.mobile) {
    arranjo.unshift({
      name: "header",
      label: " ",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography
              variant="body2"
              className={classes.textBold}
              noWrap={true}
            >
              Data {dayjs(value.dateOrigin).format("DD/MM/YYYY")} -{" "}
              {dayjs(value.dateDestination).format("DD/MM/YYYY")}
            </Typography>
          );
        }
      },
    })

  }
  return arranjo
};
