import React, { useState, useRef, useEffect, useCallback } from "react";
import { GoogleMap, useJsApiLoader, OverlayView } from '@react-google-maps/api';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";
import useCluster from "hooks/useCluster";

// components
import ClusterSingle from "componentsNew/atoms/clusterSingle/clusterSingle";
import ClusterGroup from "componentsNew/atoms/clusterGroup/clusterGroup";
import Lottie from "componentsNew/atoms/lottie/lottie";

import LoadingLottie from "assets/lottieFiles/mapLoading.json";

import "./map.css";

const containerStyle = {
  borderRadius: 16,
  width: "100%",
  height: "100%",
};

const LiveMap = ({
  points = [
    {
      color: "blue",
      title: "GOBRAX",
      lat: -25.43291049648609,
      lng: -49.279102471163874,
    },
  ],
  initLocationCenter = {
    lat: -25.43291049648609,
    lng: -49.279102471163874,
  },
  initZoom = 15,
  loading = true,
  height,
  width,
  closeConnection,
  modalDetails,
  handleModalDetails
}) => {
  const uuid = uuidv4();

  const size = useWindowSize();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: 'pt-BR'
  });

  const mapRef = useRef();
  const dataPoints = useRef([]);

  const [map, setMap] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(initZoom);

  const { clusters, supercluster } = useCluster({
    points: dataPoints.current,
    bounds,
    zoom,
    options: { radius: 200, maxZoom: 50 },
  });

  const onLoad = useCallback(function callback(map) {
    mapRef.current = map;

    const bounds = new window.google.maps.LatLngBounds();

    points.forEach((point) => {
      bounds.extend(point);
    });

    map.fitBounds(bounds);

    if(!zoom) {
      map.setZoom(16);
    }

    const northEast = bounds?.getNorthEast();
    const southWest = bounds?.getSouthWest();

    const updatedBounds = [
      southWest.lng(),
      southWest.lat(),
      northEast.lng(),
      northEast.lat()
    ];

    setMap(map);
    setBounds(updatedBounds);
    setZoom(map?.getZoom());
  }, [points, zoom]);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const handleClusterGroupClick = useCallback((cluster, location) => {
    const { latitude, longitude } = location;

    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(cluster?.id),
      20,
    );
    
    map.setZoom(expansionZoom);
    map.panTo({
      lat: latitude,
      lng: longitude,
    });
  }, [map, supercluster]);

  //criando objetos do cluster
  useEffect(() => {
    if (JSON.stringify(dataPoints.current) !== JSON.stringify(points)) {
      dataPoints.current = points.map((elem) => {
        return {
          vehicleId: elem.vehicleId,
          type: "Feature",
          ...elem,
          properties: {
            cluster: false,
          },
          geometry: {
            type: "Point",
            ...elem,
            coordinates: [elem.lng, elem.lat],
          },
        };
      });
    }
  }, [points]);

  return (
    <div className={"Map-container"}>
      {loading ? (
        <Lottie
          animationData={LoadingLottie}
          height={size.mobile ? 80 : 300}
          width={size.mobile ? 80 : 300}
        />
      ) : (
        <>
        {isLoaded && (
          <div
            className={"Map-container-item"}
            style={{
              height: height || "50vh",
              width: width || "auto",
            }}
          >
            <GoogleMap
              onLoad={onLoad}
              onUnmount={onUnmount}
              zoom={initZoom}
              center={initLocationCenter}
              mapContainerStyle={containerStyle}
              options={{
                streetViewControl: false,
                mapTypeControl: false
              }}
              onZoomChanged={() => {
                if(!map) return;

                const mapBounds = map?.getBounds();

                const northEast = mapBounds?.getNorthEast();
                const southWest = mapBounds?.getSouthWest();

                const updatedBounds = [
                  southWest.lng(),
                  southWest.lat(),
                  northEast.lng(),
                  northEast.lat()
                ];

                setBounds(updatedBounds);
                setZoom(map?.getZoom());
              }}
            >
              {clusters?.map((cluster, index) => {
                  // pegando latitude e longitude do ponto
                  const [longitude, latitude] = cluster.geometry.coordinates;

                  // pegando variavel se for group e o total do grupo
                  const { cluster: isCluster, point_count: pointCount } = cluster.properties;

                  if (pointCount === undefined) {
                    clusters.splice(index, 1);
                  }

                  // se for grupo
                  if (isCluster && cluster.id) {
                    // convertendo o cluster para pegar os pontos filhos
                    const clusterTrue = supercluster.getChildren(cluster?.id);

                    // fazendo um loop para achar todos os pontos dentro daquele grupo
                    for (let i = 0; i < pointCount; i++) {
                      clusterTrue.map((point, i) => {
                        // validando se tem um grupo dentro do grupo pai e buscando os filhos
                        if (point && point.id) {
                          try {
                            // convertendo o cluster para pegar os pontos filhos
                            const clusterSecond = supercluster.getChildren(point.id);

                            if (clusterSecond) {
                              // //removendo o cluster convertido para não ter dados duplicados
                              clusterTrue.splice(i, 1);

                              // //adicionando os pontos no grupo pai
                              clusterTrue.push(...clusterSecond);
                            }
                          } catch {
                            console.log('Not found ID cluster')
                          }
                        }
                      });
                    }

                    //cadastrando objeto inicial do ClusterGroup
                    const colors = {
                      blue: 0,
                      green: 0,
                      yellow: 0,
                      gray: 0,
                      white: 0,
                      red: 0,
                    };

                    // cadastrando as cores de cada ponto
                    clusterTrue.map((op) => {
                      colors[op?.color] = colors[op?.color] + 1;
                    });

                    return (
                      <OverlayView
                        key={cluster?.id + "-" + uuid + "group"}
                        position={{ lat: latitude, lng: longitude }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} 
                      >
                        <ClusterGroup
                          lat={latitude}
                          lng={longitude}
                          {...colors}
                          onClick={() => handleClusterGroupClick(cluster, { latitude, longitude })}
                        />
                      </OverlayView>
                    );
                  } else {
                    //se for apenas o ponto
                    return (
                      <OverlayView
                        key={cluster?.title + "-" + uuid + "single"}
                        position={{ lat: latitude, lng: longitude }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} 
                      >
                        <ClusterSingle
                          
                          closeConnection={closeConnection}
                          vehicle={cluster}
                          modalDetails={modalDetails}
                          handleModalDetails={handleModalDetails}
                          {...cluster}
                        />
                      </OverlayView>
                    );
                  }
                })}
            </GoogleMap>
          </div>
        )}
        </>
      )}
    </div>
  );
};

export default LiveMap;

LiveMap.propTypes = {
  /**
   * Zoom inicial
   */
  initZoom: PropTypes.number.isRequired,
  /**
   * Altura do mapa
   */
  height: PropTypes.any,
  /**
   * Pontos no mapa
   */
  width: PropTypes.any,
  /**
   * Pontos no mapa
   */
  points: PropTypes.array,
  /**
   * Carregamento
   */
  loading: PropTypes.bool,
  /**
   * Ponto central inicial
   */
  initLocationCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  closeConnection: PropTypes.any,
  modalDetails: PropTypes.any,
  handleModalDetails: PropTypes.any,
};