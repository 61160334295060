import React from "react";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import Icon from "@mdi/react";
import colors from "themes/gobrax";
import useStyles from "./styles";
import { useWindowSize } from "hooks/useWindowsSize";
import { clearText } from "helpers/functions";

const StatisticsCard = (props) => {
  const classes = useStyles();
  const size = useWindowSize();
  const dataCy = clearText(props.title.replace(/\s/g, ''))

  return (
    <Paper
      elevation={0}
      style={size.mobile ? { display: "flex", minHeight: 120 } : {}}
      className={classes.container}
      data-cy={`div${dataCy}`}
    >
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={2} className={classes.contentIcon}>
          <Icon
            path={props.icon}
            color={colors.palette.secondary.contrastText}
            size={1}
          />
        </Grid>
        <Grid item xs={10}>
          <Grid container direction={"column"} alignItems="flex-end">
            <Grid item className={classes.title} xl={12} lg={12} md={12} sm={12} xs={12}>
              {props.title}
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="flex-end">
                <Grid className={classes.label}>
                  {props.label} {props.unit}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StatisticsCard;
