import { useRef, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { FaMapMarker } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import L from 'leaflet';

import 'leaflet/dist/leaflet.css';

const createCustomMarkerIcon = (label) => {
  const iconHtml = `
    <div style="position: relative; width: 32px; height: 32px;">
      <div style="font-size: 32px; color: #ea4335;">
        ${ReactDOMServer.renderToString(<FaMapMarker />)}
      </div>
      <span style="
        position: absolute;
        top: 12px;
        left: 50%;
        transform: translateX(-50%);
        color: #000;
        font-size: 14px;
        font-weight: semibold;
      ">
        ${label}
      </span>
    </div>
  `;

  return L.divIcon({
    className: 'custom-icon',
    html: iconHtml,
    iconSize: [32, 32],
    iconAnchor: [12, 41],
  });
};

export const StaticMap = ({ routes, singlePoint = false }) => {
  const mapRef = useRef(null);

  const [points, setPoints] = useState([]);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    const map = mapRef.current;

    if(map !== null && points.length) {
      const bounds = L.latLngBounds(points);
      map.fitBounds(bounds);
    }
  }, [mapRef.current, points]);

  useEffect(() => {
    if(routes.length) {
      const routesPoints = routes.reduce((acc, route) => [...acc, ...route.locations.map((location) => ({ lat: location.lat, lng: location.lon }))], []);

      const routesMarkers = routes.reduce((acc, route) => {
        // Gets only first and last array items
        const filteredLocations = route.locations.filter((_, index) => !index || index === route.locations.length - 1);

        const locations = filteredLocations.map(({ lat, lon }, index) => ({
          content: route.device,
          label: index ? 'B' : 'A',
          lat,
          lng: lon
        }));

        const result = singlePoint ? locations.slice(0, 1) : locations;

        return [...acc, ...result];
      }, []);

      setPoints(routesPoints);
      setMarkers(routesMarkers);
    }
  }, [routes]);
  
  return (mapRef !== null && points.length) ? (
    <div>
      <h2>
        <a href="https://leafletjs.com/" target="_blank" rel="noreferrer">Leaflet</a> (Free) 
      </h2>
      <MapContainer
        ref={mapRef}
        zoom={13}
        style={{ width: '100%', height: '400px' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {markers?.map((marker) => (
            <Marker
              key={`marker-${marker.lat}-${marker.lng}`}
              position={[marker.lat, marker.lng]}
              icon={createCustomMarkerIcon(marker.label)}
            />
          ))}
        <Polyline positions={points} color="blue" />
      </MapContainer>
    </div>
  ) : <></>;
}
