import React, { useState, useEffect, useCallback, useRef } from "react";
import { GoogleMap, useJsApiLoader, Marker, Polyline } from '@react-google-maps/api';
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/styles";
import { v4 as uuidv4 } from 'uuid';

// styles
import googleMapStyle from "./mapStyle";
import useStyles from "./styles";

const containerStyle = {
  borderRadius: 28,
  width: "100%",
  height: "100%",
};

const MapContainer = (props) => {
  const { data: routes, style, zoom, singlePoint = false } = props;

  const theme = useTheme();

  const classes = useStyles();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: 'pt-BR'
  });

  const currentMap = useRef();

  const [map, setMap] = useState(null);
  const [points, setPoints] = useState([]);
  const [markers, setMarkers] = useState([]);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();

    points.forEach((point) => {
      bounds.extend(point);
    });

    map.setOptions({
      styles: googleMapStyle
    });
    map.fitBounds(bounds);

    if(!zoom) {
      map.setZoom(16);
    }

    setMap(map);

    if (props.onReady) {
      props.onReady();
    }
  }, [points]);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, [points]);

  const onMarkerClick = useCallback(() => {
    const bounds = new window.google.maps.LatLngBounds();

    markers.forEach((point) => {
      bounds.extend(point);
    });

    map.fitBounds(bounds);
  }, [map]);

  useEffect(() => {
    if(map) {
      const bounds = new window.google.maps.LatLngBounds();
  
      points.forEach((point) => {
        bounds.extend(point);
      });
  
      map.setOptions({
        styles: googleMapStyle
      });
      map.fitBounds(bounds);
      
      if(!zoom) {
        map.setZoom(16);
      }
  
      setMap(map);
  
      if (props.onReady) {
        props.onReady();
      }
    }
  }, [points]);

  useEffect(() => {
    if(!!routes.length) {
      const routesPoints = routes.reduce((acc, route) => [...acc, ...route.locations.map((location) => ({ lat: location.lat, lng: location.lon }))], []);

      const routesMarkers = routes.reduce((acc, route) => {
        // Gets only first and last array items
        const filteredLocations = route.locations.filter((_, index) => !index || index === route.locations.length - 1);

        const locations = filteredLocations.map(({ lat, lon }, index) => ({
          content: route.device,
          label: !!index ? 'B' : 'A',
          lat,
          lng: lon
        }));

        const result = singlePoint ? locations.slice(0, 1) : locations;

        return [...acc, ...result];
      }, []);

      setPoints(routesPoints);
      setMarkers(routesMarkers);
    }
  }, [routes]);

  return isLoaded ? (
    <Paper elevation={0} className={classes.container} style={{...style}}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          zoomControl: props.zoomControl,
          streetViewControl: props.streetViewControl,
          mapTypeControl: props.mapTypeControl,
          disableDefaultUI: props.print,
        }}
        google={props.google}
        zoom={zoom}
      >
        {markers?.map((marker, index) => {
          const uuid = uuidv4();

          return (
            <Marker
              key={`marker-${uuid}-${index}`}
              ref={currentMap}
              title={marker.content}
              name={marker.content}
              label={marker.label}
              position={{ lat: marker.lat, lng: marker.lng }}
              onClick={onMarkerClick}
            />
          )
        })}
        {!!points.length && (
          <Polyline
            path={points}
            options={{
              strokeColor: theme.palette.primary.main,
              strokeOpacity: 1.0,
              strokeWeight: 3
            }}
          />
        )}
      </GoogleMap>
    </Paper>
  ) : <></>
}

export default MapContainer;
