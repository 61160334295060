import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';

// store
import { useAppDispatch, useAppSelector } from 'store';
import { setStepAdd, setStepDeductBonus } from "store/features/bonusFollowSlice";

// components
import Aux from 'hoc/auxiliar';
import TableResponsive from 'components/TableResponsive';
import { makeColumns } from './tableColumns';

// hooks
import { useWindowSize } from "hooks/useWindowsSize";
import ConsolidatedIndicators from '../components/ConsolidatedIndicators';
import { getDriversMonitoring } from 'services/drivers';
import { createIndicators } from './utils';
import { hasPermission } from 'components/PermissionsGate';
import { toast } from "react-toastify";

// styles
import useStyles from './styles';

const RewardDetails = () => {
  const { filters: { calendar, cutoffDate } } = useAppSelector((state) => state.bonusFollow);
  const dispatch = useAppDispatch()
  const { state } = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowSize();
  const [loading, setLoading] = useState(true)
  const [generalScore, setGeneralScore] = useState(false)
  const [indicators, setIndicators] = useState([])
  const parsedCalendar = calendar ? JSON.parse(calendar) : null;
  const parsedCutoffDate = cutoffDate ? JSON.parse(cutoffDate) : null;
  
  const startDate = parsedCalendar ? parsedCalendar.startDate : (parsedCutoffDate ? parsedCutoffDate.startDate : null);
  const finishDate = parsedCalendar ? parsedCalendar.finishDate : (parsedCutoffDate ? parsedCutoffDate.finishDate : null);
  
  const initialDate = startDate ? new Date(startDate) : new Date();
  const finalDate = finishDate ? new Date(finishDate) : new Date();
  
  const formattedInitialDate = dayjs(initialDate).format("YYYY-MM-DDTHH:mm:ss-0300");
  const formattedFinalDate = dayjs(finalDate).format("YYYY-MM-DDTHH:mm:ss-0300");
  

  const [disabledButton, setDisabledButton] = useState(false);
  const download = {
    link: "/web/v2/performance/xls/singledriver",
    fileName: `relatorio-viagens_${dayjs().format("dd-MM-yyyy_HH-mm")}.xlsx`,
    params: {
      driverId: state.driverId,
      startDate: formattedInitialDate,
      endDate: formattedFinalDate,
      limit: 10000
    }
  }

  const [selectedRowData, setSelectedRowData] = useState();
  const [id, setId] = useState();
  const [isPenalizer, setIsPenalizer] = useState({
    engineBrakeScore: false,
    throttlePressureScore: false
  });

  const columns = makeColumns(size, isPenalizer, state?.data);

  const handleClickDetail = () => {
    dispatch(setStepAdd());

    history.push({
      pathname: "/gofurther/dashboard/general/bonus",
      state: {
        vehicleId: selectedRowData,
        screen: 'bonus',
        detail: state,
        startDate: selectedRowData.origin.date,
        finishDate: selectedRowData.destination.date,
      },
    });
  };

  function getVehicleIds() {
    return state?.data?.map(item => item.id) || [];
  }
  
  async function fetchDriverMonitoringData() {
    const vehiclesIds = getVehicleIds()
    const driverId = [state?.driverId]
    const response = await getDriversMonitoring(driverId, vehiclesIds, formattedInitialDate, formattedFinalDate, 1, 1, true);
    return response?.data?.data?.driverMonitoring?.drivers?.[0] || null;
  }
  
  function processDriverData(driverData) {
    if (driverData) {
      const indicatorsDriver = createIndicators(driverData);
      const monthScore = driverData.monthScore ?? null
      setIndicators(indicatorsDriver);
      setGeneralScore(monthScore?.score);
    } else {
      clearDriverData();
    }
  }
  
  function clearDriverData() {
    setIndicators(null);
    setGeneralScore(null);
  }
  
  function handleFetchError(error) {
    clearDriverData();
    toast.error(
      "Erro ao buscar os indicadores. Entre em contato com o suporte.",
    );
  }

  const fetchVehiclePerformances = async () => {
    try {
      setLoading(true);
      if (hasPermission({scopes: ['can_view_report_trip_indicators']})) {
        const responseScores = await fetchDriverMonitoringData();
        processDriverData(responseScores)
      }
    } catch (error) {
      handleFetchError(error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (state?.data?.length && cutoffDate) {
      fetchVehiclePerformances()
    }
  }, [state, cutoffDate, hasPermission])

  const handleBack = (e) => {
    setDisabledButton(true);

    history.goBack();

    dispatch(setStepDeductBonus());

    setTimeout(() => {
      setDisabledButton(false)
    }, 5000);
  }

  const handleRowClick = (data, data2) => {
    const newSelectedRowData = state.data.find(
      (_info, index) => index === data2.dataIndex,
    );

    setId(data2.dataIndex);

    setSelectedRowData(newSelectedRowData);
  }

  useEffect(() => {
    if (state?.data) {
      setIsPenalizer({
        engineBrakeScore: state?.data[0]?.hasEngineBrake,        
        throttlePressureScore: state?.data[0]?.hasThrottlePenalizer
      });
    }
  }, [state]);

  return (
    <Aux>
      <Grid container alignItems="center" justifyContent="center" style={{ paddingTop: 16 }}>
        <Grid item lg={11} md={11} sm={12} xs={12}>
          <Box>
            {/* title */}
            <Grid item xl={12} xs={12}>
              <Typography variant="h3" data-cy="h3RelatorioViagens">Relatório de viagens</Typography>
            </Grid>
            {/* motorista */}
            <Grid item xl={12} xs={12}>
              <Grid container>
                <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
                  <Grid component={Paper} marginTop={.5} padding={1.5} className={classes.infoDriverContainer}>
                    <Grid data-cy="divMotorista" className={classes.contentDriver} container item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography className={classes.label}>
                        Motorista:{" "}
                      </Typography>
                      <strong className={classes.driverName}>
                        {state.name}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {hasPermission({scopes: ['can_view_report_trip_indicators']}) && (
              <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.marginTopBigger}>
                <ConsolidatedIndicators generalScore={generalScore} indicators={indicators} smaller loading={loading} />
              </Box>
            )} 

            {/* table */}
            {hasPermission({scopes: ['can_view_report_trip_table']}) && (
              <>
                <Grid item xl={12} xs={12} className={classes.marginTop} data-cy="tableViagens">
                  {state?.data && <TableResponsive
                    tableBodyMaxHeight={size.mobile ? size.height - 430 + 'px' : size.height - 330 + 'px'}
                    data={state.data}
                    columns={columns}
                    download={download}
                    options={{
                      rowsPerPage: 100,
                      setRowProps: (row, index) => {
                        return index === id ? { style: { backgroundColor: '#FFF5CB', cursor: 'pointer', width: '100%' } } : null
                      },
                      onRowClick: (data, data2) => handleRowClick(data, data2),
                    }}
                    pointer={true}
                    tableName="bonus-details"
                  />}
                </Grid>
                {/* botoes */}
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                      <Box>
                        <Button
                          fullWidth
                          disabled={disabledButton}
                          variant="contained"
                          className={classes.buttonBack}
                          onClick={(e) => (disabledButton ? null : handleBack(e))}
                          data-cy="buttonVoltar"
                        >
                          {disabledButton ? "Aguarde..." : "Voltar"}
                        </Button>
                      </Box>
                    </Grid>
                    {hasPermission({scopes: ['can_view_report_details_button']}) && (
                      <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                      <Box marginTop={size.mobile ? "25px" : 0}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={handleClickDetail}
                          disabled={!selectedRowData}
                          className={classes.buttonDetail}
                          data-cy="buttonVerDetalhes"
                        >
                          Ver detalhes
                        </Button>
                      </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Aux>
  );
};

export default RewardDetails;
