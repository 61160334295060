import colors from '../../../themes/gobrax'

const getColorForScore = (score) => {
  if (score <= 40) {
    return colors.palette.error.main; 
  } else if (score <= 80) {
    return colors.palette.warning.main; 
  } else {
    return colors.palette.success.main;
  }
};

export const createIndicators = (driverData) => {
  if (!driverData) {
    return null; 
  }

  const keyHasValue = (value, defaultValue = 0) => value !== null && value !== undefined ? value : defaultValue;

  return {
    extraEconomicRange: {
      color: getColorForScore(keyHasValue(driverData.extraEconomicScore)),
      duration: keyHasValue(driverData.achievedMileage) / keyHasValue(driverData.consumptionAvg, 1),
      percentage: keyHasValue(driverData.extraEconomicScore),
      score: keyHasValue(driverData.extraEconomicScore),
      totalKm: keyHasValue(driverData.achievedMileage),
      name: "extraEconomicRange",
      title: "Início faixa verde",
      label: "Início faixa verde"
    },
    leverage: {
      color: getColorForScore(keyHasValue(driverData.leverageScore)),
      percentage: keyHasValue(driverData.leverageScore),
      score: keyHasValue(driverData.leverageScore),
      name: "leverage",
      title: "Aproveitamento de embalo",
      label: "Aproveitamento de embalo"
    },
    throttlePressure: {
      color: getColorForScore(keyHasValue(driverData.highPressureScore)),
      percentage: keyHasValue(driverData.highPressureScore),
      score: keyHasValue(driverData.highPressureScore),
      name: "throttlePressure",
      title: "Pressão do acelerador",
      label: "Pressão do acelerador"
    },
    idle: {
      color: getColorForScore(keyHasValue(driverData.idleScore)),
      percentage: keyHasValue(driverData.idleScore),
      score: keyHasValue(driverData.idleScore),
      name: "idle",
      title: "Motor ligado parado",
      label: "Motor ligado parado"
    },
    powerRange: {
      color: getColorForScore(keyHasValue(driverData.powerScore)),
      percentage: keyHasValue(driverData.powerScore),
      score: keyHasValue(driverData.powerScore),
      name: "powerRange",
      title: "Acelerando acima do verde",
      label: "Acelerando acima do verde"
    },
    engineBrake: {
      color: getColorForScore(keyHasValue(driverData.engineBrakeScore)),
      percentage: keyHasValue(driverData.engineBrakeScore),
      score: keyHasValue(driverData.engineBrakeScore),
      name: "engineBrake",
      title: "Freio motor",
      label: "Freio motor"
    },
    cruiseControl: {
      color: getColorForScore(keyHasValue(driverData.cruiseControlScore)),
      percentage: keyHasValue(driverData.cruiseControlScore),
      score: keyHasValue(driverData.cruiseControlScore),
      name: "cruiseControl",
      title: "Piloto automático",
      label: "Piloto automático"
    },
    defensiveDriving: {
      color: getColorForScore(keyHasValue(driverData.defensiveScore)),
      percentage: keyHasValue(driverData.defensiveScore),
      score: keyHasValue(driverData.defensiveScore),
      name: "defensiveDriving",
      title: "Excesso de velocidade",
      label: "Excesso de velocidade"
    },
    economicRange: {
      color: getColorForScore(keyHasValue(driverData.economicRangeScore)),
      percentage: keyHasValue(driverData.economicRangeScore),
      score: keyHasValue(driverData.economicRangeScore),
      name: "economicRange",
      title: "Faixa econômica",
      label: "Faixa econômica"
    },
    ecoRoll: {
      color: getColorForScore(keyHasValue(driverData.ecoRollScore)),
      percentage: keyHasValue(driverData.ecoRollScore),
      score: keyHasValue(driverData.ecoRollScore),
      name: "ecoRoll",
      title: "Roda livre",
      label: "Roda livre"
    },
    lowPressure: {
      color: getColorForScore(keyHasValue(driverData.lowPressureScore)),
      percentage: keyHasValue(driverData.lowPressureScore),
      score: keyHasValue(driverData.lowPressureScore),
      name: "lowPressure",
      title: "Pressão baixa",
      label: "Pressão baixa"
    },
    medPressure: {
      color: getColorForScore(keyHasValue(driverData.medPressureScore)),
      percentage: keyHasValue(driverData.medPressureScore),
      score: keyHasValue(driverData.medPressureScore),
      name: "medPressure",
      title: "Pressão média",
      label: "Pressão média"
    },
    highPressure: {
      color: getColorForScore(keyHasValue(driverData.highPressureScore)),
      percentage: keyHasValue(driverData.highPressureScore),
      score: keyHasValue(driverData.highPressureScore),
      name: "highPressure",
      title: "Pressão alta",
      label: "Pressão alta"
    }
  };
};

