import { useRef, useState } from "react";
import Supercluster from "supercluster";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { dequal } from "dequal";

function useCluster({ points, bounds, zoom, options }) {
  const superclusterRef = useRef(null);
  const pointsRef = useRef(null);
  const [clusters, setClusters] = useState([]);

  const zoomInt = Math.round(zoom);

  useDeepCompareEffectNoCheck(() => {
    // Initialize or update Supercluster instance if points or options have changed
    if (
      !superclusterRef.current ||
      !dequal(pointsRef.current, points) ||
      !dequal(superclusterRef.current.options, options)
    ) {
      superclusterRef.current = new Supercluster(options);
      superclusterRef.current.load(points);
      pointsRef.current = points; // Update the points reference
    }

    // Update clusters if bounds are available
    if (bounds && superclusterRef.current) {      
      const clusters = superclusterRef.current.getClusters(bounds, zoomInt);

      setClusters(clusters);
    }
  }, [points, bounds, zoomInt, options]);

  // Return clusters and the Supercluster instance
  return {
    clusters,
    supercluster: superclusterRef.current,
  };
}

export default useCluster;
