import React from "react";
import { Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Rating from '@mui/material/Rating';
import StarIcon from '@material-ui/icons/Star';

import { makeValueStars, valueToStringFloat } from "helpers/functions";

const StyledEmptyStar = styled(StarIcon)(() => ({
  color: '#00000042',
  fontSize: '18px !important'
}));

export const makeColumns = ({ size }) => {
  return [
    {
      name: "vehicleIdentification",
      label: size.mobile ? " " : "Veículo",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" data-cy="thVehicle">{value}</Typography>;
        },
      },
    },
    {
      name: "achievedMileage",
      label: "Dist. percorrida (Km)",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" data-cy="thDistTravelKM">{valueToStringFloat(value)}</Typography>;
        },
      },
    },
    {
      name: "consumption",
      label: "Consumo (l)",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" data-cy="thConsumptionL">{valueToStringFloat(value)}</Typography>;
        },
      },
    },
    {
      name: "consumptionAvg",
      label: "Média computador de bordo",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" data-cy="thAverageOnboardComputer">{valueToStringFloat(value)}</Typography>;
        },
      },
    },
    {
      name: "score",
      label: "Nota geral",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" data-cy="thGeneralScore">{value}</Typography>;
        },
      },
    },
    {
      name: "idleScore",
      label: "Motor ligado parado",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box data-cy="thEngineOnStopped">
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "extraEconomicScore",
      label: "Início da faixa verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box data-cy="thStartGreenBand">
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "leverageScore",
      label: "Aproveitamento de embalo",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box data-cy="thMomentumUtilization">
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "powerScore",
      label: "Acelerando acima do verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box data-cy="thSpeedingUpGreen">
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "cruiseControlScore",
      label: "Piloto automático",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box data-cy="thAutopilot">
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "defensiveScore",
      label: "Excesso de velocidade",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`}>
              <Box data-cy="thExcessSpeed">
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                  emptyIcon={<StyledEmptyStar />}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        searchable: false,
        filter: false,
        viewColumns: false,
        hideColumn: true,
      },
    },
  ];
};
