import React from "react";
import Typography from "@mui/material/Typography";
import dayjs from 'dayjs';

import { valueToPositiveFloat } from "helpers/functions";

export const tableColumns = (size, hasGNV) => {
  let columns = [
    {
      name: "time",
      label: size.mobile ? ' ' : "Data",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" noWrap>
              {value ? dayjs(value).format("DD/MM/YYYY HH:mm:ss") : value}
            </Typography>
          );
        },
      },
    },
    {
      name: "imei",
      label: "IMEI",
      options: {
        align: "center",
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value}</Typography>;
        },
      },
    },
    {
      name: "lastBondPoint",
      label: "Último ponto",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value ? 'Sim' : 'Não'}</Typography>;
        },
      },
    },
    {
      name: "satellite_quatity",
      label: "Qtde. satélites",
      options: {
        align: "center",
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value}</Typography>;
        },
      },
    },
    {
      name: "latitude",
      label: "Latitude",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value}</Typography>;
        },
      },
    },
    {
      name: "longitude",
      label: "Longitude",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value}</Typography>;
        },
      },
    },
    {
      name: "gps_course",
      label: "Direção GPS",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value}</Typography>;
        },
      },
    },
    {
      name: "height",
      label: "Altitude",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value}</Typography>;
        },
      },
    },
    {
      name: "gps_speed",
      label: "Velocidade GPS",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value}</Typography>;
        },
      },
    },
    {
      name: "mileage",
      label: "Milhagem",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value}</Typography>;
        },
      },
    },
    {
      name: "rpm",
      label: "RPM",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" noWrap>
              {valueToPositiveFloat(value, 2)}
            </Typography>
          );
        },
      },
    },
    {
      name: "acceleration_pedal_position",
      label: "Posição do pedal de aceleração",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" noWrap>
              {" "}
              {valueToPositiveFloat(value, 2)}
            </Typography>
          );
        },
      },
    },
    {
      name: "total_mileage",
      label: "Odômetro total",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" noWrap>
              {" "}
              {valueToPositiveFloat(value, 2)}
            </Typography>
          );
        },
      },
    },
    {
      name: "total_mileage_filled",
      label: "Odômetro preenchido",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" noWrap>
              {" "}
              {valueToPositiveFloat(value, 2)}
            </Typography>
          );
        },
      },
    },
    {
      name: "mileage_virt",
      label: "Odômetro virtual",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" noWrap>
              {valueToPositiveFloat(value, 2)}
            </Typography>
          );
        },
      },
    },
    {
      name: "total_fuel_consumption",
      label: "Consumo total de combustível",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" noWrap>
              {" "}
              {!hasGNV ? valueToPositiveFloat(value, 2) : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "total_fuel_consumption_high_resolution",
      label: "Consumo total de combustível - alta resolução",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" noWrap>
              {" "}
              {!hasGNV ? valueToPositiveFloat(value, 2) : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "service_brake_pedal_position",
      label: "Posição do pedal de freio",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value}</Typography>;
        },
      },
    },
    {
      name: "service_brake_pedal_status",
      label: "Status do pedal de freio",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value}</Typography>;
        },
      },
    },
    {
      name: "cruise_control_status",
      label: "Status do cruise control",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value}</Typography>;
        },
      },
    },
    {
      name: "adblue",
      label: "Arla",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" noWrap>
              {valueToPositiveFloat(value, 2)}
            </Typography>
          );
        },
      },
    },
    {
      name: "gear",
      label: "Marcha",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value}</Typography>;
        },
      },
    },
    {
      name: "can_fuel_level",
      label: "Nível comb. CAN(%)",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{!hasGNV ? value : "-"}</Typography>;
        },
      },
    },
    {
      name: "can_speed",
      label: "Velocidade CAN",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value}</Typography>;
        },
      },
    },
    {
      name: "ignition_key",
      label: "Chave",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value}</Typography>;
        },
      },
    },
  ];

  if (hasGNV) {
    columns.push({
      name: "total_fuel_consumption_gaseous",
      label: "Consumo de gás",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" noWrap>{value} Kg</Typography>;
        },
      },
    });
  }

  return columns;
};
