import { Button } from "@mui/material";
import React from 'react';
import { useHistory } from 'react-router';

//styles
import useStyles from './styles';

function BackButton() {
  const classes = useStyles();
  const history = useHistory()
  return (
    <Button
      fullWidth
      color="primary"
      variant="contained"
      className={classes.btnBack}
      onClick={() => {
        history.goBack() 
      }}
      data-cy="buttonBack"
    >
      Voltar
    </Button>
  )
}

export default BackButton;
