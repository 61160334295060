import React from "react";
import { IconButton, Grid } from "@mui/material";
import { EditOutlined, NotInterested, DeleteOutline } from "@mui/icons-material";
import { cpfMask } from "helpers/masks";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";

export const makeColumns = ({ onEdit, onDisable, onDelete, size }) => {
  let columns = [
    {
      name: "customerName",
      label: size.mobile ? ' ' : "Empresa",
      options: {
        customBodyRender: (value) => (
          <div data-cy="thEmpresa">{value}</div>
        ),
      },
    },
    {
      name: "name",
      label: "Motorista",
      options: {
        customBodyRender: (value) => (
          <div data-cy="thMotorista">{value}</div>
        )
      }
    },
    {
      name: "documentNumber",
      label: "CPF",
      options: {
        customBodyRender: (value) => (
          <div data-cy="thCPF">{cpfMask(value)}</div>
        ),
      },
    },
    {
      name: "activatedAt",
      label: "Status",
      options: {
        customBodyRender: (value) => (
          <div data-cy="thStatus">
            {!value ? 'Senha não cadastrada' : 'Senha cadastrada'}
          </div>
        ),
        filterOptions: {
          names: ['Senha não cadastrada', 'Senha cadastrada'],
          logic: (activatedAt, filters) => {
            const status = !activatedAt ? 'Senha não cadastrada' : 'Senha cadastrada'
            return !filters.includes(status);
          }
        },
      },
    }
  ];

  if (!size.mobile && hasPermission({ scopes: ['can_edit_drivers', 'can_remove_drivers'] })) {
    columns.push({
      name: "id",
      label: "Ações",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        print: false,
        customBodyRender: (value) => {
          return (
            <Grid container style={{ minWidth: "130px" }}>
              <PermissionsGate scopes={['can_edit_drivers']}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={6}
                >
                  <IconButton
                    onClick={() => onEdit(value)}
                    aria-label="edit"
                    title="Editar"
                    size="large"
                    data-cy="buttonEditar"
                  >
                    <EditOutlined />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_disable_drivers']}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={6}
                >
                  <IconButton
                    onClick={() => onDisable(value)}
                    aria-label="disable"
                    title="Desabilitar"
                    size="large"
                    data-cy="buttonDesabilitar"
                  >
                    <NotInterested />
                  </IconButton>
                </Grid>
              </PermissionsGate>
              <PermissionsGate scopes={['can_remove_drivers']}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={6}
                >
                  <IconButton
                    onClick={() => onDelete(value)}
                    aria-label="disable"
                    title="Excluir"
                    size="large"
                    data-cy="buttonExcluir"
                  >
                    <DeleteOutline />
                  </IconButton>
                </Grid>
              </PermissionsGate>
            </Grid>
          );
        },
      },
    });
  }

  return columns;
};
