import { color_blue_500, color_green_500, color_neutral_0, color_neutral_500, color_red_500, color_yellow_500 } from "@gobrax-ui/react";

export const optionsClusterGroup = {
  chart: {
    type: "donut",
    height: "56px",
    width: "56px",
    animations: {
      enabled: false,
    },
  },
  legend: {
    show: false,
  },
  tooltip: {
    enabled: false,
  },
  stroke: {
    show: false,
  },
  states: {
    normal: {
      filter: {
        type: "none",
      },
    },
    hover: {
      filter: {
        type: "none",
      },
    },
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: "none",
      },
    },
  },
  colors: [color_blue_500, color_green_500, color_yellow_500, color_neutral_500, color_neutral_0, color_red_500],
  plotOptions: {
    pie: {
      expandOnClick: false,
      dataLabels: {
        offset: 0,
        minAngleToShowLabel: 100000000000000000000000,
      },
      donut: {
        background: "#fff",
        labels: {
          show: true,
          name: {
            show: false,
          },
          value: {
            show: true,
            fontSize: "30px",
            fontFamily: "Roboto",
            fontWeight: 600,
          },
          total: { show: true, showAlways: true },
        },
      },
    },
  },
};
