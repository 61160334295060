import React from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { convertDateBr } from "helpers/dates";

import EventIcon from '@mui/icons-material/Event';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';

import useStyles from './styles';
import { setStepDeduct } from 'store/features/driversMonitoringSlice';
import { setStepDeductBonus } from 'store/features/bonusFollowSlice';
import { useAppDispatch } from 'store';
import { useWindowSize } from "../../hooks/useWindowsSize";

function DriverPerformanceDetailComponent({ vehicleProps }) {
  const dispatch = useAppDispatch()
  const { detail, vehicleId, startDate, finishDate, screen } = vehicleProps
  const classes = useStyles()
  const history = useHistory()
  const size = useWindowSize()
  const origin = vehicleId.origin.name ? convertDateBr(startDate) + ' (' + vehicleId.origin.name + ')' : convertDateBr(startDate);
  const destination = vehicleId.destination.name ? convertDateBr(finishDate) + ' (' + vehicleId.destination.name + ')' : convertDateBr(finishDate);
  
  return (
    <Box>
      <Typography className={classes.title} data-cy="pTitulo" textAlign={{ xs: 'center', md: 'left' }}>
        Performance do motorista selecionado
      </Typography>
      <Paper className={classes.paper} data-cy="divGrupoSelects">
        <Grid className={classes.wrapperContent} container item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid className={classes.flex} data-cy="divSelectOperacao">
            <LocalShippingIcon className={classes.icon} />
            <Typography>{vehicleId.vehicleIdentification} / {vehicleId.plate}</Typography>
          </Grid>
          <Grid className={classes.flex} data-cy="divSelectMotorista">
            <PersonIcon className={classes.icon} />
            <Typography>{detail.name}</Typography>
          </Grid>
          <Grid className={classes.flex} data-cy="divSelectCalendario">
            <EventIcon className={classes.icon}/>
            <Typography className={classes.icon}>{origin} - </Typography>
            <Typography className={classes.icon}>{destination}</Typography>
          </Grid>
          {size.mobile ? null :
            <Grid className={classes.flex}>
              <Button
                onClick={() => {
                  if (screen !== 'bonus') {
                    dispatch(setStepDeduct())
                  } else {
                    dispatch(setStepDeductBonus())
                  }
                  history.goBack();

                }}
                variant="contained"
                className={classes.backButton}
                data-cy="buttonVoltar"
              >
                <ArrowBackIcon fontSize="small" />{" "}Voltar
              </Button>
            </Grid>
          }
        </Grid>
      </Paper>
    </Box>
  )
}

export default DriverPerformanceDetailComponent;
