import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";

// helpers
import { valueToPositiveFloat } from "helpers/functions";

export const infoRpmRange = {
  extra: {
    title: "Início da faixa verde",
    color: "#81BC02",
  },
  economy: {
    title: "Final da faixa verde",
    color: "#5E8900",
  },
  engine_brake: {
    title: "Faixa freio motor",
    color: "#E3E3E3",
  },
  neutral: {
    title: "Faixa lenta",
    color: "#fff",
  },
  power: {
    title: "Acelerando acima do verde",
    color: "#F7A801",
  },
  red: {
    title: "Faixa vermelha",
    color: "#CC0D07",
  },
  white: {
    title: "Faixa branca",
    color: "#e5e5e5",
  },
};

export const getColor = (theme) => {
  return {
    altitude: "#1D71BE",
    fuel: "#E0A024",
    rpm: "#8E3FCB",
    speed: "#3F932B",
  };
};

// Normalização da ordenação de data
export const normalizeData = (data, index = 'time') => {
  let dataNormalized = data.sort((a, b) => new Date(a[index]) - new Date(b[index]));
  dataNormalized.shift();
  return dataNormalized;
};

export const getApproximateDate = (dateStringArray, referenceDateString) => {
  const referenceDate = new Date(referenceDateString);

  let closestDifference = Infinity;
  let closestIndex = -1;
  
  for (let i = 0; i < dateStringArray.length; i++) {
    let actualDate = new Date(dateStringArray[i].date);
    let difference = Math.abs(referenceDate - actualDate);
    if (difference < closestDifference) {
      closestDifference = difference;
      closestIndex = i;
    }
  }

  return dateStringArray[closestIndex];
};

const isBetween = (num1, num2, value) => value > num1 && value < num2;

export const getRpmStatus = (rpmValue, rpmRange) => {
  const rpmStatus = Object.entries(rpmRange).find((element) =>
    isBetween(element[1][0], element[1][1], rpmValue),
  );
  
  return rpmStatus ? infoRpmRange[rpmStatus[0]] : null;
};

export const parseRPMRange = (rangeData) => {
  return {
    extra: [
      rangeData.extra_economic_range_start,
      rangeData.extra_economic_range_end,
      "Início da faixa verde",
      "Essa é a faixa ideal em que o motorista deve manter o RPM para ter uma melhor performance.",
    ],
    economy: [
      rangeData.economic_range_start,
      rangeData.economic_range_end,
      "Final da faixa verde",
      "O final da faixa verde é uma posição boa, porém menos que o início, em que o motorista deve manter o RPM para um bom desempenho.",
    ],
    engine_brake: [
      rangeData.power_range_start,
      rangeData.engine_brake_range_end,
      "Faixa de freio motor",
      "A faixa de freio motor é positiva somente caso o motorista não esteja acelerando, o motorista não deve acelerar enquanto o RPM estiver dentro dessa faixa.",
    ],
  };
};

export const parseChartData = (response, rangeData, vehicle, vehicleProfile, initialDate, finalDate) => {
  let chartData = [];
  let altitudeSeriesData = [];
  let rpmSeriesData = [];
  let speedSeriesData = [];
  let fuelSeriesData = [];
  let imei = null;
  let datesLastBondPoint = []

  const rpmRanges = parseRPMRange(rangeData) || [];
  const responseData = normalizeData(response);
  const maxFuelCapacity = vehicle.maxFuelCapacity || 0;
  const distanceCalcMethod = vehicleProfile.data?.distance_calc_method ?? "can";

  responseData.map((data) => {

    const totalFuel = data.can_fuel_level ? maxFuelCapacity * (data.can_fuel_level / 100) : 0;
    const altitude = valueToPositiveFloat(data.height, 2);
    const rpm = valueToPositiveFloat(data.rpm, 2);
    const speed = valueToPositiveFloat(data[`${distanceCalcMethod}_speed`], 2);
    const fuel_level = valueToPositiveFloat(totalFuel, 2) ? valueToPositiveFloat(totalFuel, 2) : data?.can_fuel_level ?? 0;
    
    chartData = [
      ...chartData,
      {
        acceleration_pedal: valueToPositiveFloat(data?.acceleration_pedal_position, 2),
        altitude: altitude,
        brake_pedal: data?.service_brake_pedal_status ?? 0,
        date: dayjs(data.time).format("YYYY-MM-DD HH:mm:ss"),
        fuel: valueToPositiveFloat(totalFuel, 2),
        fuel_level: data?.can_fuel_level,
        gear: data?.gear ?? 0,
        id: uuidv4(),
        ignition_key: data.ignition_key,
        rpm: rpm,
        rpmStatus: getRpmStatus(rpm, rpmRanges) ?? 0,
        speed: speed
      },
    ];

    altitudeSeriesData.push({ x: data.time, y: altitude });
    fuelSeriesData.push({ x: data.time, y: fuel_level });
    rpmSeriesData.push({ x: data.time, y: rpm });
    speedSeriesData.push({ x: data.time, y: speed });
    data?.lastBondPoint && datesWithLastBondPoint.push(data.time);

    if (!imei) {
      imei = data?.imei;
    };
    if (data?.lastBondPoint) {
      datesLastBondPoint.push(data.time);
    }
  });

  return {
    chartData: chartData,
    dates: {
      initialDate: initialDate,
      finalDate: finalDate,
    },
    limits: {
      maxFuelCapacity: maxFuelCapacity,
      maxSpeedAllowed: vehicle?.maxSpeedAllowed || 0,
      hasGNV: vehicle?.fuelTypeId === 6,
    },
    imei,
    datesLastBondPoint,
    rpmrange: rpmRanges,
    series: [{
      name: "RPM",
      type: "line",
      data: rpmSeriesData,
    },
    {
      name: "Altitude",
      type: "area",
      data: altitudeSeriesData,
    },
    {
      name: "Combustível",
      type: "line",
      data: fuelSeriesData,
    },
    {
      name: "Velocidade",
      type: "line",
      data: speedSeriesData,
    }]
  };
};