import React, { useEffect, useState, useCallback } from "react";
import { Grid, Typography, Paper, Box, FormHelperText, Accordion, AccordionSummary, AccordionDetails, } from "@mui/material";
import { useFormContext, useFormState, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import useStyles from "./styles";
import Aux from "hoc/auxiliar";
import PermissionsGate from "components/PermissionsGate";
import { allRequirementLevelOptions } from "domain/selectOptions";
import { Input, Select } from "components/react-hook-form";
import { makeSelectOptions } from "helpers/makeSelectOptions";
import { getOperationTypesByCustomers } from "services/operationType";
import { useAppSelector } from "store";
import RangeComponent from "components/RangeComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const rewardFormatOptions = [
  {
    value: 'mileageReward',
    label: 'Premiação por Km e Nota',
    description: 'Esse é o nosso cálculo original, com base na nota, o motorista irá receber de maneira proporcional a meta de km inserida.'
  },
  {
    value: 'scoreMinimunReward',
    label: 'Premiação por Nota mínima',
    description: 'Adicione uma nota mínima para o motorista começar a ganhar, defina o valor máximo e km percorrida. Exemplo: O motorista se chegar a nota mínima (Exemplo: 70) com premiação cadastrada a 1000 reais, então ele já ganha 700 reais por atingir o mínimo.'
  },
  {
    value: 'scoreMediumReward',
    label: 'Premiação por Nota mínima proporcional',
    description: 'Adicione uma nota mínima para o motorista começar a ganhar, defina o valor máximo e km percorrida. O motorista irá receber aos poucos com base na nota mínima proporcional e o km rodado. Exemplo: Ao cadastrar 70 de nota, essa nota será o “Novo zero” para eles e será iniciado a porcentagem da premiação a partir disso.'
  },
  {
    value: 'range',
    label: 'Premiação por Faixa/Range',
    description: 'O motorista irá receber o valor que você inserir enquanto estiver na faixa de nota indicada, tendo sua premiação alterada apenas quanto atingir uma nota que esteja em outra faixa.'
  }
];


const FormVehicleGroupComponent = () => {
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const classes = useStyles();
  const { control, setValue } = useFormContext();
  const { errors } = useFormState({ control });
  const [isDisabledRequirementLevel, setIsDisabledRequirementLevel] = useState(true)
  const [operationTypeAllData, setOperationTypeAllData] = useState([])
  const [operationTypeOptions, setOperationTypeOptions] = useState([]);

  const operationType = useWatch({ control, name: "operationType" });
  const operationTypeOption = useWatch({ control, name: "operationTypeOption" });
  const requirementLevelOption = useWatch({ control, name: "requirementLevelOption" });
  const rewardFormat = useWatch({ control, name: "rewardFormat" });
  const [option, setOption] = useState(rewardFormatOptions.find(it => it.value === rewardFormat) ?? rewardFormatOptions[0]);
  const [expanded, setExpanded] = useState(false);

  const fetchOperationTypes = useCallback(async () => {
    try {
      const response = await getOperationTypesByCustomers(currentCustomer);
      const options = makeSelectOptions({
        data: response.data.operationTypes,
        keyValue: "id",
        keyLabel: "name",
      });

      setOperationTypeOptions(options);
      setOperationTypeAllData(response.data.operationTypes)
    } catch (error) {
      toast.error(
        "Erro ao buscar tipos de operações! Entre em contato com o suporte.",
      );
    }
  }, [currentCustomer])

  useEffect(() => {
    if (operationType) {
      const findType = operationTypeAllData.find(
        (op) => op.id === operationType,
      );

      if (findType?.type === 2) {
        setValue("requirementLevel", 4);
        setIsDisabledRequirementLevel(true);
      } else {
        if (requirementLevelOption === "" || requirementLevelOption === 4) {
          setValue("requirementLevel", "");
        } else {
          // fix editing and control requirementLevel
          setValue("requirementLevelOption", "");
        }
        setIsDisabledRequirementLevel(false);
      }
    }
  }, [operationType]);

  useEffect(() => {
    if (operationTypeOption && operationTypeOptions && operationTypeOptions.length > 0) {
      const defaultOperationTypeOption = operationTypeOptions.find((elm) => elm.value === operationTypeOption);

      setValue(
        "operationType",
        defaultOperationTypeOption.value,
      );
    }
  }, [operationTypeOption, operationTypeAllData]);

  useEffect(() => {
    if (requirementLevelOption && allRequirementLevelOptions && allRequirementLevelOptions.length > 0) {
      setValue(
        "requirementLevel",
        allRequirementLevelOptions.find((elm) => elm.value === requirementLevelOption).value,
      );
    }
  }, []);

  useEffect(() => {
    currentCustomer && fetchOperationTypes(currentCustomer);
  }, [currentCustomer, fetchOperationTypes]);

  const handleOptionClick = (selectedOption) => {
    setOption(selectedOption);
    setValue("rewardFormat", selectedOption.value); 
    setExpanded(false);
  };
  
  const renderInputField = (name, label, error, errorMessage) => (
    <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
      <Box sx={{ m: 2 }}>
        <Input
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          name={name}
          variant="outlined"
          fullWidth
          label={label}
          error={error}
        />
        <FormHelperText className={classes.textError} error={true}>
          {errorMessage}
        </FormHelperText>
      </Box>
    </Grid>
  );
  
  const renderFieldsBasedOnFormat = () => {
    return (
      <>
        {renderInputField(
          "targetMileage", 
          "Meta de km rodado por mês*", 
          errors.targetMileage && true, 
          errors.targetMileage?.message
        )}
  
        {renderInputField(
          "maxAmount", 
          "Premiação máx por usuário*", 
          errors.maxAmount && true, 
          errors.maxAmount?.message
        )}
  
        {rewardFormat === "scoreMinimunReward" && (
          <PermissionsGate scopes={['can_create_minscore', 'can_edit_minscore']}>
            {renderInputField(
              "minScore", 
              "Nota mínima de avaliação diária*", 
              errors.minScore && true, 
              errors.minScore?.message
            )}
          </PermissionsGate>
        )}
  
        {rewardFormat === "scoreMediumReward" && (
          <PermissionsGate scopes={['can_create_minscore', 'can_edit_minscore']}>
            {renderInputField(
              "minProportionalScore", 
              "Nota mínima proporcional*", 
              errors.minProportionalScore && true, 
              errors.minProportionalScore?.message
            )}
          </PermissionsGate>
        )}
  
        {rewardFormat === "range" && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <RangeComponent />
          </Grid>
        )}
      </>
    );
  };
  
  return (
    <Aux>
      <Paper elevation={2} className={classes.container}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.title} data-cy="pGroupData">Dados do grupo</Typography>
              <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid item xl={8} lg={8} md={8} sm={12} xs={12} data-cy="divGroupName">
                  <Box sx={{ m: 2 }}>
                    <Input
                      name="name"
                      variant="outlined"
                      fullWidth
                      label="Nome do grupo *"
                      error={errors.name && true}
                    />
                    <FormHelperText className={classes.textError} error={true}>
                      {errors.name?.message}
                    </FormHelperText>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title}>Selecione o formato de Premiação</Typography>
                <Box sx={{ m: 2 }}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} data-cy="divGroupName">
                    <Accordion className={classes.accordion} expanded={expanded} onChange={() => setExpanded(!expanded)}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.accordionSummary}
                      >
                        <Typography 
                          fontWeight={600} 
                          className={classes.heading}>{option.label}</Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDrop}>
                        {rewardFormatOptions.map((optionItem) => (
                          <Box key={optionItem.value} sx={{ mb: 2 }}>
                            <Typography 
                              onClick={() => handleOptionClick(optionItem)} 
                              fontWeight={500}
                              lineHeight={1} 
                              sx={{ 
                                wordWrap: 'break-word',
                                whiteSpace: 'normal',
                                textOverflow: 'ellipsis',
                                overflow: 'visible',
                                width: '100%',
                                mb: 1
                              }} 
                            >
                              {optionItem.label}
                            </Typography>
                          
                            <Typography 
                              onClick={() => handleOptionClick(optionItem)}
                              lineHeight={1} 
                              sx={{ 
                                wordWrap: 'break-word',
                                whiteSpace: 'normal',
                                overflow: 'visible', 
                                mt: 0
                              }} 
                            >
                              {optionItem.description}
                            </Typography>
                          </Box>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Box>
                <FormHelperText className={classes.textError} error={true}>
                  {errors.rewardFormat?.message}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.title}>Valores de meta e premiação</Typography>
              <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              {renderFieldsBasedOnFormat()}
              </Grid>
            </Grid>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.title} data-cy="pTypeRatingAwards">Tipo de avaliação para premiação</Typography>
              <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} data-cy ="divAwardGroup">
                  <Box sx={{ m: 2 }}>
                    <Select
                      name={"operationType"}
                      options={operationTypeOptions}
                      placeholder="Grupo de premiação *"
                      error={!!errors.operationType}
                    />
                    <FormHelperText className={classes.textError} error>
                      {errors.operationType?.message}
                    </FormHelperText>
                  </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} data-cy="divRequirement">
                  <Box sx={{ m: 2 }}>
                    <Select
                      options={allRequirementLevelOptions}
                      name="requirementLevel"
                      placeholder="Exigência *"
                      disabled={isDisabledRequirementLevel}
                      error={!!errors.requirementLevel}
                    />
                    <FormHelperText className={classes.textError} error>
                      {errors.requirementLevel?.message}
                    </FormHelperText>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Aux>
  );
};

export default FormVehicleGroupComponent;
