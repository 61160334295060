import React from "react";
import { Grid, Paper } from "@mui/material";
import makeAnimated from "react-select/animated";
import Select from "react-select";

// components
import Calendar from "../Calendar";
import OperationFilters from "../OperationFilters";
import SelectMonth from "../SelectMonth";
import MultiSelect from "../MultiSelect";
import { getYearsOptions } from "helpers/operations";
import { useWindowSize } from "hooks/useWindowsSize";

// styles
import useStyles from "./styles";
import DriverPerformanceDetailComponent from "components/DriverPerformanceDetailComponent";
import { colourStyles } from "./colourStyles";

const PageToolbar = React.memo(({
  calendarMaxRanges,
  calendarMonthValue,
  isCalendarMonthDisabled,
  currentYear,
  detailedSelect = false,
  isDetail,
  isEmission,
  isSingleMode,
  handleSelectDate,
  handleSelectedData,
  handleSelectedOperation,
  handleYear,
  listData,
  loading = false,
  menuCalendar,
  menuOperations,
  selectedData,
  selectedDates,
  vehiclesMenuButton,
  vehicleProps,
  isAllSelected = false
}) => {
  const classes = useStyles();
  const yearsOptions = getYearsOptions();
  const animatedComponents = makeAnimated();
  const size = useWindowSize();
  const handleCalendar = (startDate, endDate) => {
    handleSelectDate(startDate, endDate);
  };

  return (
    <div>
      <Grid
        container
        data-cy="divGrupoSelects"
        spacing={1}
        direction="row"
        justifyContent="space-around"
        className={classes.pageToolbarContainer}
      >
        {vehiclesMenuButton && (
          <Grid item xs={12} sm={6} className={classes.spacingContainer}>
            <Paper elevation={0} className={classes.multiselect}>
              <MultiSelect
                isDetail={isDetail}
                isSingleMode={isSingleMode}
                listData={listData}
                handleSelectedListData={handleSelectedData}
                selectedData={selectedData}
                vehicleProps={vehicleProps}
                detailedSelect={detailedSelect}
                placeholder={detailedSelect && 'Digite o id da unidade, nome ou placa da frota, ou nome do motorista'}
                loading={loading}
                data-cy="divSelectFrota"
                isAllSelected={isAllSelected}
              />
            </Paper>
          </Grid>
        )}
        {(isDetail && vehicleProps) && (
          <Grid item xs={12} sm={12} xl={12} lg={12} className={classes.spacingContainer}>
            <DriverPerformanceDetailComponent vehicleProps={vehicleProps} />
          </Grid>
        )}

        {menuOperations && (
          <Grid item xs={12} sm={12} className={classes.spacingContainer}>
            <OperationFilters
              handleCalendar={handleCalendar}
              handleSelectedOperation={handleSelectedOperation}
            />
          </Grid>
        )}
        {(menuCalendar && !isDetail) && (
          <Grid item xs={12} sm={6} className={classes.spacingContainer}>
            <Paper elevation={0} className={classes.multiselect}>
              <Calendar
                selectedDates={selectedDates}
                handleCalendar={handleCalendar}
                calendarMaxRanges={calendarMaxRanges}
              />
            </Paper>
          </Grid>
        )}

        {isEmission && (
          <>
            <Grid
              item
              xl={size.grid3}
              lg={size.grid3}
              md={size.grid3}
              sm={size.grid3}
              xs={size.grid3}
              style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
            >
              <Paper elevation={0} className={classes.paperSelect} data-cy="divSelectAno">
                <Select
                  name="year"
                  isSearchable={!size.mobile}
                  placeholder="Ano"
                  options={yearsOptions.reverse()}
                  styles={{ ...colourStyles, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  onChange={handleYear}
                  value={currentYear}
                  defaultValue={currentYear}
                  components={animatedComponents}
                />
              </Paper>
            </Grid>
            <Grid
              item
              xl={size.grid3}
              lg={size.grid3}
              md={size.grid3}
              sm={size.grid3}
              xs={size.grid3}
              style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
            >
              <SelectMonth
                data-cy="divSelectMes"
                title="Mês"
                value={calendarMonthValue}
                onSelectDate={handleSelectDate}
                disabled={isCalendarMonthDisabled}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
});

export default PageToolbar;
