import React from "react";
import Button from '@mui/material/Button';
import DialogContent from "@mui/material/DialogContent";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import useStyles from "./styles";

const ModalUnassignDriverToVehicle = ({ confirmOpen, data, currentDriver, handleConfirmClose, handleUnassignDriverToVehicle }) => {
  const classes = useStyles();

  const handleConfirm = () => {
    const { 
      // 0: vehicle,
      // 1: driverName,
      // 2: vehiclePlate,
      // 3: latestLinkDate,
      4: entryData 
    } = data;

    const model = {
      driverId: entryData?.driverId ?? 0,
      vehicleId: entryData?.vehicleId
    }

    handleUnassignDriverToVehicle(model);
  }

  return (
    <Dialog
      open={confirmOpen}
      onClose={handleConfirmClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Desvincular motorista?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Tem certeza que deseja desvincular o motorista <strong>{currentDriver && currentDriver.driverName}</strong> do veículo <strong>{data.identification && data.identification}</strong>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmClose} className={classes.btCancel}>
          Cancelar
        </Button>
        <Button onClick={() => handleConfirm()} className={classes.btConfirm} variant="contained" autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalUnassignDriverToVehicle;
