import React, { useEffect, useState, useCallback } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material"
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import dayjs from "dayjs";

// services
import { getDriversHistory } from "services/driver";

// helpers
import { getAssignType } from "helpers/drivers";

// components
import Aux from "hoc/auxiliar";
import LinkDriversSkeleton from "components/Skeletons/LinkDriversSkeleton";
import TableResponsive from "components/TableResponsive";
import Widget from "components/Widget/Widget";
import { makeColumns } from "./tableColumns";

// store
import { useAppDispatch, useAppSelector, store } from 'store';
import { setHistoricLinkDriversPaginationReducer, clearHistoricLinkDriversPagination } from "store/features/historicLinkDriversSlice";

import { useWindowSize } from "hooks/useWindowsSize";

//styles
import useStyles from "./styles";

function HistoricLinkDrivers() {
  const dispatch = useAppDispatch();
  const { pagination } = useAppSelector((state) => state.historicLinkDrivers);
  const classes = useStyles();
  const size = useWindowSize();
  const history = useHistory();
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState();
  const [selectedRowData, setSelectedRowData] = useState();
  const [driversHistory, setDriversHistory] = useState([]);
  const [download, setDownload] = useState({
    link: "",
    fileName: "",
    params: {
      vehicleId: null,
    }
  });

  const mergeFieldsAssignUnassign = (registers) => {
    let list = registers
    list.forEach((vehicle, index) => {
      vehicle.header = {
        init: vehicle.startDate,
        final: vehicle.endDate
      }
      vehicle.operations.forEach((item) => {
        if (!item.operation) {
          return false;
        }
        if (item.operation === 'ASSIGN') {
          list[index].assignBy = `${getAssignType(item.sourceId)} | ${item.userName}`;
        }
        if (item.operation === 'UNASSIGN') {
          list[index].unassignBy = `${getAssignType(item.sourceId)} | ${item.userName}`
        }
      })
    })
    return list
  };

  const fetchDriversHistory = useCallback(async (param, page, rowsPerPage) => {
    dispatch(setHistoricLinkDriversPaginationReducer({ isLoading: true }));
    try {
      setLoading(true)
      const response = await getDriversHistory(param, page, rowsPerPage);
      if (response.status !== 200) {
        throw new Error();
      }
      const { registers, maxNumberOfItems } = response.data
      if (registers) {
        const model = mergeFieldsAssignUnassign(registers)
        setDriversHistory(model)
        dispatch(
          setHistoricLinkDriversPaginationReducer({
            isLoading: false,
            count: maxNumberOfItems || 0
          })
        );
      }
      setDownload({
        link: `/vehicles/exportDriverHistory`,
        fileName: `historico-vinculos-motoristas_${dayjs().format("DD-MM-YYYY_HH-mm")}.xlsx`,
        params: {
          vehicleId: param,
        }
      });
    } catch (error) {
      toast.error("Erro ao buscar histórico de vínculos. Contate o suporte.")
    } finally {
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    const updatedPagination = store.getState().comparison.pagination;
    state && fetchDriversHistory(state.params[4]?.vehicleId, updatedPagination.page, updatedPagination.rowsPerPage)
  }, [state])

  const editHandler = (params) => {
    history.push({
      pathname: "/gofurther/maintenance/assignDriver",
      state: {
        ...state,
        isEdit: true,
        editData: {
          startDate: params[0] || null,
          endDate: params[1] || null,
          driverName: params[2] || '',
          driverId: params[6] || '',
        }
      }
    });
  };

  const columns = makeColumns({
    handleEdit: editHandler,
    size
  });

  const handleSelectRow = (data, data2) => {
    const rowIndex = data2.dataIndex;
    setId(rowIndex);
    const formatData = [
      driversHistory[rowIndex].startDate,
      driversHistory[rowIndex]?.endDate || null,
      driversHistory[rowIndex].driverName,
      data[3].props.children,
      data[4].props.children,
      driversHistory[rowIndex].status,
      driversHistory[rowIndex].driverId
    ]
    setSelectedRowData(formatData)
  }

  const handleTablePagination = (action, tableState) => {
    switch (action) {
      case 'changePage':
        dispatch(setHistoricLinkDriversPaginationReducer({ isLoading: true, page: tableState.page + 1 }));
        state && fetchDriversHistory(state.params[4]?.vehicleId, tableState.page + 1, tableState.rowsPerPage)

        break;

      case 'changeRowsPerPage':
        dispatch(setHistoricLinkDriversPaginationReducer({ isLoading: true, rowsPerPage: tableState.rowsPerPage }));
        state && fetchDriversHistory(state.params[4]?.vehicleId, tableState.page + 1, tableState.rowsPerPage)

        break;

      default:
        break;
    }
  }

  useEffect(() => {
    dispatch(clearHistoricLinkDriversPagination());
  }, [dispatch]);

  if (loading) return <LinkDriversSkeleton />

  return (
    <Aux>
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box>
            <Grid container item xl={12} xs={12} className={classes.table}>
              <Grid container item lg={10} md={12} sm={12} xs={12}>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Paper className={classes.paperText} data-cy="divSelectVehicle">
                    <Typography className={classes.titleText}>Veículo: </Typography>
                    <Typography>{state.params[0]}</Typography>
                  </Paper>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Paper className={classes.paperText} data-cy="divSelectPlate">
                    <Typography className={classes.titleText}>Placa: </Typography>
                    <Typography>{state.params[2]} </Typography>
                  </Paper>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Paper className={classes.paperText} data-cy="divSelectCurrentDriver">
                    {state.params[3]?.driverName ? (
                      <>
                        <Typography className={classes.titleText}>Motorista atual: </Typography>
                        <Typography>{state.params[3].driverName}</Typography>
                      </>
                    ) : <Typography>Sem motorista vinculado.</Typography>}
                  </Paper>
                </Grid>
              </Grid>
              <Grid container alignItems='center' item lg={2} md={3} sm={6} xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color={'secondary'}
                  style={{ marginTop: 5 }}
                  className={classes.buttonDetail}
                  data-cy="buttonNewLink"
                  onClick={() => {
                    history.push({
                      pathname: "/gofurther/maintenance/assignDriver",
                      state,
                    });
                  }}
                >
                  Novo vínculo
                </Button>
              </Grid>
            </Grid>
            <Widget disableWidgetMenu title="Histórico de vínculos de motorista">
              <Grid item xl={12} xs={12} className={classes.table}>
                <TableResponsive
                  pointer={true}
                  columns={columns}
                  data={driversHistory}
                  download={download}
                  tableName="historic-link-drivers"
                  options={{
                    onRowClick: (row, index) => { handleSelectRow(row, index) },
                    rowsPerPage: pagination.rowsPerPage,
                    setRowProps: (row, index) => { return index === id ? { style: { backgroundColor: '#FFF5CB', cursor: 'pointer', width: '100%' } } : null },
                    serverSide: true,
                    count: pagination.count,
                    onTableChange: handleTablePagination,
                    isLoading: pagination.isLoading,
                    page: pagination.page - 1,
                  }}
                />
              </Grid>
            </Widget>
          </Box>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box >
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.buttonDetail}
                    onClick={(e) => {
                      history.goBack()
                    }}
                  >
                    Voltar
                  </Button>
                </Box>
              </Grid>
              {size.mobile &&
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box >
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => editHandler(selectedRowData)}
                      disabled={!selectedRowData}
                      className={classes.buttonDetail}
                    >
                      Editar
                    </Button>
                  </Box>
                </Grid>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Aux>
  )
}

export default HistoricLinkDrivers;
