export function returnNA(value) {
  switch (value) {
    case "":
    case null:
    case undefined:
    case "undefined":
    case "null":
    case " ":
      return "Sem motorista vinculado.";
    default:
      return value;
  }
}
