import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDebouncedCallback } from "use-debounce";
import { Paper, Grid } from "@mui/material";
import { LocalShipping as TruckIcon } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";

// themes
import colors from "themes/gobrax";

// helpers
import { clearDuplicated } from "helpers/functions";

// dto
import MenuDTO from "dto/menuDTO";

// styles
import { colourStyles } from "./styles";
import { singleStyles } from "./singleStyles";
import useStyles from "./useStyles";

const MultiSelect = (props) => {
  const {
    listData,
    handleSelectedListData,
    selectedData,
    isSingleMode = false,
    customStyles,
    detailedSelect = false,
    placeholder,
    isAllSelected = false,
  } = props;

  const selectAllOption = {
    value: "select-all",
    label: "Toda minha frota",
    color: colors.palette.text.primary,
    id: "select-all",
    isChild: false,
    isHidden: false,
    isSelected: false,
  };

  const classes = useStyles();

  const hasListData = useMemo(() => {
    return Array.isArray(listData) && listData.length > 0;
  }, [listData]);

  const initialOptions = useRef([]);
  const childOptions = useRef([]);
  const parentOptions = useRef([]);

  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOption] = useState([]);
  const [selected, setSelected] = useState(
    isSingleMode ? [] : [selectAllOption],
  );

  const [selectedSingleMode, setSelectedSingleMode] = useState([]);

  const customLabel = (item) => {
    if (item.name !== "No operation") {
      return (
        <div className={classes.groupStyles}>
          <span className={classes.labelCustomStyles}>{item.name}</span>
          <span className={classes.groupBadgeStyles}>
            {item.vehicles.length}
          </span>
        </div>
      );
    } else {
      return (
        <div className={classes.groupStyles}>
          <span className={classes.labelCustomStyles}>
            SEM VÍNCULO DE GRUPO
          </span>
          <span className={classes.groupBadgeStyles}>
            {item.vehicles.length}
          </span>
        </div>
      );
    }
  };

  const formatData = useCallback((data = []) => {
    let parentFiltered = [];
    let childFiltered = [];
    let options = [];

    if (hasListData) {
      options.push(selectAllOption);
    }

    data.forEach((item) => {
      let uuid = uuidv4();

      options.push({
        ...item,
        value: item.name,
        label: customLabel(item),
        color: colors.palette.text.primary,
        id: uuid,
        isChild: false,
        isHidden: true,
        isSelected: false,
      });

      item.vehicles.forEach((child) => {
        const chassisLabel = child?.chassi ? `- ${child?.chassi}` : "";

        const driverLabel = child.currentDriver.driverName ? `- (Motorista atual: ${child.currentDriver.driverName})` : "";

        const localChild = {
          ...child,
          value: child?.currentDevice?.identification ?? '',
          label: `${detailedSelect ? `${child?.currentDevice?.identification} - ` : ''} ${child?.identification} - ${child.plate} ${chassisLabel} ${driverLabel}`,
          plate: child.plate,
          color: colors.palette.text.hint,
          isChild: true,
          parentId: uuid,
          isHidden: true,
          isSelected: isSingleMode ? false : true,
          id: child.id,
          operation: {
            id: item.id,
            name:
              item.name !== "No operation" ? item.name : "Sem vínculo de grupo",
          },
        };

        options.push(localChild);
      });
    });

    options.filter((isChild) => isChild.isChild && childFiltered.push(isChild));

    options.filter(
      (isChild) => !isChild.isChild && parentFiltered.push(isChild),
    );

    initialOptions.current = options;
    childOptions.current = childFiltered;
    parentOptions.current = parentFiltered;

    setOptions(childFiltered);

    if (!isSingleMode) {
      if (!isAllSelected) handleClearAll();
      setSelectedOption([]);
      debounced([]);
    }
    if (isSingleMode) {
      childOptions.current = MenuDTO.fromChild(childFiltered);
      setOptions(MenuDTO.fromChild(childFiltered));
    }
  }, [hasListData, isAllSelected, isSingleMode]);

  const debounced = useDebouncedCallback((value) => {
    if (isSingleMode) {
      const selected = [{ ...value }];

      handleSelectedListData(selected);
    } else {
      const selected = clearDuplicated(value);

      handleSelectedListData(selected);
    }
  }, 1000);

  const handleAddedOption = (element, list, options) => {
    let initialOptionsLocal = [...initialOptions.current];

    // Caso especial para "select-all"
    if (element.value === "select-all") {
      initialOptionsLocal.forEach((item, index) => {
        const updatedElement = { ...item, isSelected: true, isHidden: true };
        initialOptionsLocal[index] = updatedElement;

        if (item.isChild) {
          options.push(updatedElement);
        }
      });

      const localList = [element];

      initialOptions.current = initialOptionsLocal;
      return [localList, options];
    }

    // Caso para elementos que não são filhos
    if (!element.isChild) {
      const id = element.id;

      // Atualiza filhos do elemento pai selecionado
      initialOptionsLocal.forEach((item, index) => {
        if (item.parentId === id) {
          const updatedElement = { ...item, isHidden: true, isSelected: true };
          initialOptionsLocal[index] = updatedElement;

          // Adiciona filhos apenas ao options
          if (!options.some(option => option.id === item.id)) {
            options.push(updatedElement);
          }

          // Garante que os filhos não sejam exibidos na lista
          list = list.filter((i) => i.id !== item.id);
        }
      });

      // Adiciona o pai à lista
      list.push(element);

      initialOptions.current = initialOptionsLocal;

      return [list, options];
    }

    // Caso para elementos filhos
    const parentId = element.parentId;

    const id = element.id;

    let allSelected = true;

    initialOptionsLocal.forEach((item, index) => {
      if (item.id === id) {
        const updatedElement = { ...item, isSelected: true };

        initialOptionsLocal[index] = updatedElement;

        if (!options.some(option => option.id === item.id)) {
          options.push(updatedElement);
        }

        if (!list.some(listItem => listItem.id === item.id)) {
          list.push(updatedElement);
        }
      } else if (item.parentId === parentId && !item.isSelected) {
        allSelected = false;
      }
    });


    if (allSelected) {
      initialOptionsLocal.forEach((item, index) => {
        if (!item.isChild && item.id === parentId) {
          initialOptionsLocal[index] = { ...item, isHidden: false };

          if (!list.some(listItem => listItem.id === item.id)) {
            list.push(item);
          }
        }
        if (item.parentId === parentId) {
          initialOptionsLocal[index] = { ...item, isHidden: true };

          list = list.filter((i) => i.id !== item.id);
        }
      });
    }

    initialOptions.current = initialOptionsLocal;

    return [list, options];
  };

  const handleRemovedOption = (element, list, options) => {
    if (element.value === "select-all") {
      handleClearAll();
      setSelectedOption([]);
      debounced([]);
      return [[], []];
    }

    if (element.isChild) {
      list = list.filter((item) => item.id !== element.id);

      const updatedSelectedOptions = options.filter(
        (el) => element.id !== el.id,
      );

      return [list, updatedSelectedOptions];
    }

    let updatedSelectedOptions = options;

    let initialOptionsLocal = [...initialOptions.current];

    initialOptionsLocal.forEach((item, index) => {
      if (item.parentId === element.id || item.id === element.id) {
        list = list.filter((el) => el.id !== element.id);

        initialOptionsLocal[index] = {
          ...item,
          isHidden: false,
          isSelected: false,
        };

        updatedSelectedOptions = updatedSelectedOptions.filter(
          (el) => item.id !== el.parentId,
        );
      }
    });

    initialOptions.current = initialOptionsLocal;

    return [list, updatedSelectedOptions];
  };

  const handleClearAll = () => {
    let initialOptionsLocal = [...initialOptions.current];

    initialOptionsLocal.forEach((item, index) => {
      initialOptionsLocal[index] = {
        ...item,
        isHidden: false,
        isSelected: false,
      };
    });

    initialOptions.current = initialOptionsLocal;

    setSelected([]);
  };

  const handleChange = (list, element) => {
    switch (element.action) {
      case "select-option":
        let [addedItemList, selectOptions] = handleAddedOption(
          element.option,
          list,
          selectedOptions,
        );
        setSelected(addedItemList);
        setSelectedOption(selectOptions);
        debounced(selectOptions);

        break;

      case "remove-value":
        let [removedItemList, removeOptions] = handleRemovedOption(
          element.removedValue,
          list,
          selectedOptions,
        );
        setSelected(removedItemList);
        setSelectedOption(removeOptions);
        debounced(removeOptions);

        break;

      case "clear":
        handleClearAll();
        setSelectedOption([]);
        debounced([]);

        break;

      default:
        break;
    }
  };

  const animatedComponents = makeAnimated();

  const handleChangeSingleMode = (list) => {
    setSelectedSingleMode(list);
    debounced(list);
  };

  const formatGroupLabel = (data) => (
    <div className={classes.groupStyles}>
      <span className={classes.labelStyles}>{data.label}</span>
      <span className={classes.groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  useEffect(() => {
    if (selectedData && selectedData.length) {
      const op = selectedData.map((child) => {
        return {
          ...child,
          value: child.currentDevice?.identification,
          label: `${child?.identification} - ${child.plate}`,
          plate: child.plate,
          color: colors.palette.text.hint,
          isChild: true,
          isHidden: false,
          id: child.id,
        };
      });

      setSelectedSingleMode(op);
      debounced(op);
      formatData(listData);
      if (!isAllSelected) {
        setSelected(op);
      }
    } else {
      formatData(listData);
    }
  }, [formatData, listData, isAllSelected]);

  return (
    <>
      {!props.isDetail && (
        <Paper elevation={0} className={classes.paper} data-cy="divSelectFrota">
          <Grid container justifyContent="space-around" alignItems="center">
            <Grid item xs={1}>
              <TruckIcon />
            </Grid>
            <Grid item xs={11}>
              {isSingleMode ? (
                <Select
                  styles={singleStyles}
                  placeholder={props.loading ? "Carregando dados, aguarde..." : (placeholder || "Digite o nome da frota ou a placa")}
                  options={options}
                  onChange={(list) => handleChangeSingleMode(list)}
                  components={animatedComponents}
                  value={selectedSingleMode}
                  formatGroupLabel={formatGroupLabel}
                  isSearchable
                  isLoading={props.loading}
                  loadingMessage={() => "Carregando dados, aguarde..."}
                  noOptionsMessage={() => hasListData ? "Nenhum resultado encontrado" : "Nenhuma frota/placa cadastrada"}
                />
              ) : (
                <Select
                  id="select-vehicles"
                  placeholder={props.loading ? "Carregando dados, aguarde..." : (placeholder || "Digite o nome da frota ou a placa")}
                  closeMenuOnSelect={false}
                  isMulti
                  components={animatedComponents}
                  options={initialOptions.current}
                  styles={customStyles ?? colourStyles}
                  onChange={(list, index) => handleChange(list, index)}
                  value={selected}
                  noOptionsMessage={() => hasListData ? "Nenhum resultado encontrado" : "Nenhuma frota/placa cadastrada"}
                  isSearchable
                  isLoading={props.loading}
                  loadingMessage={() => "Carregando dados, aguarde..."}
                  style={{ borderColor: "transparent" }}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default MultiSelect;
