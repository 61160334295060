import * as yup from "yup";

export default yup.object().shape({
  document: yup.string().test("document", "CNPJ incompleto", function (value) {
    if (value) {
      const schema = yup.string().min(18, "CNPJ inválido");
      return schema.isValidSync(value);
    }
    return true;
  }).required("Campo obrigatório"),
  name: yup.string().required("Campo obrigatório"),
  note: yup.string().max(254, "Máximo de 254 caracteres"),
  operations: yup.array().of(yup.number()).required("Campo obrigatório").min(1, "Selecione ao menos uma opção"),
  period: yup.number().required("Campo obrigatório").integer().nullable(),
  startingDay: yup.number().required("Campo obrigatório").integer().nullable(),
  finishingDay: yup.number().required("Campo obrigatório").integer().nullable(),
});
