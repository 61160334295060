import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  itemContainer: {
    display: "flex",
  },
  title: {
    fontWeight: "bold",
    lineHeight: '19.2px',
    fontSize: '16px',
    textAlign: 'left',
  },
  icon: {
    color: theme.palette.text.main
  }
}));
