import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  driversRecalculations: {
    openRecalcIds: null
  },
};

export const recalculationSlice = createSlice({
  name: "recalculation",
  initialState,
  reducers: {
    clearDriverRecalculation: (state) => {
      state.driversRecalculations.openRecalcIds = initialState.driversRecalculations.openRecalcIds;
    },
    setDriverRecalculation: (state, action) => {
      if (!!state.driversRecalculations.openRecalcIds) {
        state.driversRecalculations.openRecalcIds.push(...action.payload);
      } else {
        state.driversRecalculations.openRecalcIds = action.payload;
      }
    },
  },
});

export const {
  setDriverRecalculation,
  clearDriverRecalculation
} = recalculationSlice.actions;

export default recalculationSlice.reducer;
