import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  table: {},
  textBold: {
    fontWeight: "bold",
  },
  textInfo: {
    fontStyle: "italic",
    color: theme.palette.text.secondary,
  },
  actionContent: {
    display: "flex",
    justifyContent: "space-around",
  },
  buttonDetail: {
    textTransform: "inherit",
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: 10,
    boxShadow: "none",
  },
  actionButton: {
    transition: "0.3s",
    "&:hover": {
      cursor: "pointer",
      fontSize: 28,
    },
  },
  btnNew: {
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    "&:not(:hover)": {
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.light
    }
  }
}));
