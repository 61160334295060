export function Dates({afterHour, afterMinute, afterSecond, beforeHour, beforeMinute, beforeSecond}){
  const dateNow = new Date()
  const dateAfter = new Date()
  const dateBefore = new Date()
  if(afterHour){
    dateAfter.setHours(dateAfter.getHours() + afterHour)
  }
  if(afterMinute){
    dateAfter.setMinutes(dateAfter.getMinutes() + afterMinute)
  }
  if(afterSecond){
    dateAfter.setSeconds(dateAfter.getSeconds() + afterSecond)
  }
  if(beforeHour){
    dateBefore.setHours(dateBefore.getHours() - beforeHour)
  }
  if(beforeMinute){
    dateBefore.setMinutes(dateBefore.getMinutes() - beforeMinute)
  }
  if(beforeSecond){
    dateBefore.setSeconds(dateBefore.getSeconds() - beforeSecond)
  }
  return{dateBefore,dateNow,dateAfter}
}

export const convertDateBr = (dateHour, seconds = false) => {
  const options = {
    timeZone: 'America/Sao_Paulo',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  if (seconds) {
    options.second = 'numeric';
  }
  return new Date(dateHour).toLocaleString('pt-BR', options).replace(',', '');
}

export const convertHourMinBr = (dateHour) => {
  const options = {
    timeZone: 'America/Sao_Paulo',
    hour: 'numeric',
    minute: 'numeric'
  };
  return new Date(dateHour).toLocaleString('pt-BR', options).replace(',', '');
}

export const convertDateStringToDB = (dateHour) => {
  const options = {
    timeZone: 'America/Sao_Paulo',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  };

  const date = new Date(dateHour).toLocaleString('pt-BR', options).split(" ");

  var time = date[1];
  var mdy = date[0];

  mdy = mdy.split('/');

  var day = mdy[0];
  var month = mdy[1];
  var year = mdy[2].replace(',', '');
  var formattedDate = year + '-' + month + '-' + day + "T" + time + '-0300';

  return formattedDate;
}