/* eslint-disable camelcase */
import React from 'react'
import abs from 'assets/icons/abs.svg'
import add_outlined from 'assets/icons/add_outlined.svg'
import add from 'assets/icons/add.svg'
import analysis_individual from 'assets/icons/analysis_individual.svg'
import arrow_left from 'assets/icons/arrow_left.svg'
import arrow_right from 'assets/icons/arrow_right.svg'
import arrow_right_blue from 'assets/icons/arrow_right_blue.svg'
import automatic_pilot from 'assets/icons/automatic_pilot.svg'
import award from 'assets/icons/award.svg'
import award_group from 'assets/icons/award_group.svg'
import brake_motor from 'assets/icons/brake_motor.svg'
import brake_warning from 'assets/icons/brake_warning.svg'
import close_small from 'assets/icons/close_small.svg'
import close from 'assets/icons/close.svg'
import co2 from 'assets/icons/co2.svg'
import column from 'assets/icons/column.svg'
import comparative from 'assets/icons/comparative.svg'
import communication from 'assets/icons/communication.svg'
import connecting from 'assets/icons/connecting.svg'
import config from 'assets/icons/config.svg'
import copy from 'assets/icons/copy.svg'
import danger from 'assets/icons/danger.svg'
import dangerous from 'assets/icons/dangerous.svg'
import dash_bars from 'assets/icons/dash_bars.svg'
import date_calendar from 'assets/icons/date_calendar.svg'
import calendar from 'assets/icons/calendar.svg'
import dislike from 'assets/icons/dislike.svg'
import diesel_control from 'assets/icons/diesel_control.svg'
import docs_check from 'assets/icons/docs_check.svg'
import down from 'assets/icons/down.svg'
import download from 'assets/icons/download.svg'
import driver_bond from 'assets/icons/driver_bond.svg'
import driver from 'assets/icons/driver.svg'
import electronic_unit from 'assets/icons/electronic_unit.svg'
import error from 'assets/icons/error.svg'
import factory from 'assets/icons/factory.svg'
import filters from 'assets/icons/filters.svg'
import find from 'assets/icons/find.svg'
import fleet_suggestion from 'assets/icons/fleet_suggestion.svg'
import fleet from 'assets/icons/fleet.svg'
import green_range from 'assets/icons/green_range.svg'
import history from 'assets/icons/history.svg'
import home from 'assets/icons/home.svg'
import info_help from 'assets/icons/info_help.svg'
import info from 'assets/icons/info.svg'
import insight_ia from 'assets/icons/insight_ia.svg'
import left from 'assets/icons/left.svg'
import less_outlined from 'assets/icons/less_outlined.svg'
import less from 'assets/icons/less.svg'
import like from 'assets/icons/like.svg'
import location from 'assets/icons/location.svg'
import lullaby from 'assets/icons/lullaby.svg'
import manage_post from 'assets/icons/manage_post.svg'
import manager from 'assets/icons/manager.svg'
import medium_tank from 'assets/icons/medium_tank.svg'
import menu_open from 'assets/icons/menu_open.svg'
import menu from 'assets/icons/menu.svg'
import navigation from 'assets/icons/navigation.svg'
import new_tab from 'assets/icons/new_tab.svg'
import notification_filled from 'assets/icons/notification_filled.svg'
import notification from 'assets/icons/notification.svg'
import odometro from 'assets/icons/odometro.svg'
import pageless from 'assets/icons/pageless.svg'
import pan_hand from 'assets/icons/pan_hand.svg'
import permissions from 'assets/icons/permissions.svg'
import person_filled from 'assets/icons/person_filled.svg'
import person from 'assets/icons/person.svg'
import print from 'assets/icons/print.svg'
import question_help from 'assets/icons/question_help.svg'
import right from 'assets/icons/right.svg'
import speed_velocity from 'assets/icons/speed_velocity.svg'
import speed_warning from 'assets/icons/speed_warning.svg'
import speeding from 'assets/icons/speeding.svg'
import stop_motor from 'assets/icons/stop_motor.svg'
import success_help from 'assets/icons/success_help.svg'
import success_outlined from 'assets/icons/success_outlined.svg'
import success from 'assets/icons/success.svg'
import supply from 'assets/icons/supply.svg'
import truck_full from 'assets/icons/truck.svg'
import truck_filled from 'assets/icons/truck_filled.svg'
import up from 'assets/icons/up.svg'
import users from 'assets/icons/users.svg'
import vehicle_group from 'assets/icons/vehicle_group.svg'
import vehicles from 'assets/icons/vehicles.svg'
import warning_help from 'assets/icons/warning_help.svg'
import warning_triangle from 'assets/icons/warning_triangle.svg'
import warning from 'assets/icons/warning.svg'
import { renderScale } from './render-scale'
import { Icon } from '@mui/material'

const svgMap = {
  abs,
  add_outlined,
  add,
  analysis_individual,
  arrow_left,
  arrow_right,
  arrow_right_blue,
  automatic_pilot,
  award,
  award_group,
  brake_motor,
  brake_warning,
  close_small,
  close,
  co2,
  column,
  comparative,
  communication,
  connecting,
  config,
  copy,
  danger,
  dangerous,
  dash_bars,
  date_calendar,
  calendar,
  dislike,
  diesel_control,
  docs_check,
  down,
  download,
  driver_bond,
  driver,
  electronic_unit,
  error,
  factory,
  filters,
  find,
  fleet_suggestion,
  fleet,
  green_range,
  history,
  home,
  info_help,
  info,
  insight_ia,
  left,
  less_outlined,
  less,
  like,
  location,
  lullaby,
  manage_post,
  manager,
  medium_tank,
  menu_open,
  menu,
  navigation,
  new_tab,
  notification_filled,
  notification,
  odometro,
  pageless,
  pan_hand,
  permissions,
  person_filled,
  person,
  print,
  question_help,
  right,
  speed_velocity,
  speed_warning,
  speeding,
  stop_motor,
  success_help,
  success_outlined,
  success,
  supply,
  truck_full,
  truck_filled,
  up,
  users,
  vehicle_group,
  vehicles,
  warning_help,
  warning_triangle,
  warning
};

const IconComponent = ({ name, size = 'l', onClick }) => {
  const svgSrc = svgMap[name];
  return (
    <Icon onClick={onClick} style={{ display: 'flex', alignItems: 'center', marginRight: 5, width: renderScale(size), height: renderScale(size) }}>
      <img src={svgSrc} style={{ margin: '0 auto', width: renderScale(size), height: renderScale(size) }} />
    </Icon>
  )
}

export default IconComponent;
