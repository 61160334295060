import React, { useState, useMemo } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

// helpers
import { convertDateBr, convertHourMinBr }  from "helpers/dates";
import { valueToStringFloat } from "helpers/functions";

// store
import { setStepAdd, setStepDeduct } from 'store/features/driversMonitoringSlice';
import { useAppDispatch } from 'store';

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

// components
import Aux from 'hoc/auxiliar';
import TableResponsive from "components/TableResponsive";
import Widget from 'components/Widget/Widget';
import { makeColumns } from './tableColumns';

// styles
import useStyles from './styles';

const DriversMonitoringTripList = () => {
  const dispatch = useAppDispatch()
  const { state } = useLocation()
  const history = useHistory()

  const size = useWindowSize()
  const columns = makeColumns({ size })
  const classes = useStyles(size.mobile)
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [id, setId] = useState();

  const tripListDetails = useMemo(() => state.tripList?.map((data) => ({ 
    ...data,
    origin: 
      size.mobile
        ? convertHourMinBr(data.origin.date) + ' - ' + data.origin.name
        : convertDateBr(data.origin.date) + ' - ' + data.origin.name,
    destination: 
      size.mobile 
        ? convertHourMinBr(data.destination.date) + ' - ' + data.destination.name
        : convertDateBr(data.destination.date) + ' - ' + data.destination.name,
    achievedMileage: valueToStringFloat(data.achievedMileage)
  })), [state.tripList]);

  const handleClickDetail = () => {
    dispatch(setStepAdd())
    history.push({
      pathname: "/gofurther/dashboard/general/driversMonitoring",
      state: {
        screen: 'driversMonitoring',
        startDate: selectedRowData.origin.date,
        finishDate: selectedRowData.destination.date,
        vehicleId: selectedRowData,
        detail: {
          ...state,
          data: [selectedRowData],
          endDate: selectedRowData.destination.date,
          driverId: state.driverId
        },
      },
    })
  };

  return (
    <Aux>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box className={classes.marginTop}>
            <Widget
              disableWidgetMenu
              title="Relatório de viagens detalhadas">
              <Grid item container xl={12} xs={12} justifyContent={'space-between'} data-cy="divMotoristaVeiculo">
                <Grid item component={Paper} className={classes.infoDriverContainer} xl={size.grid6} lg={size.grid6} md={size.grid6} sm={size.grid12} xs={size.grid12} data-cy="divMotorista">
                  <Typography className={classes.label}>Motorista: </Typography>
                  <strong className={classes.driverName}>{state.name}</strong>
                </Grid>
                <Grid component={Paper} className={classes.infoDriverContainer} item xl={size.grid6} lg={size.grid6} md={size.grid6} sm={size.grid12} xs={size.grid12} data-cy="divVeiculo">
                  <Typography className={classes.label}>Veículo: </Typography>
                  <strong className={classes.driverName}>{state.vehicle}</strong>
                </Grid>
              </Grid>
              <Grid item xl={12} xs={12} className={classes.marginTop} data-cy="tableViagens">
                {tripListDetails && <TableResponsive
                  tableBodyMaxHeight={size.mobile ? size.height - 450 + 'px' : size.height - 350 + 'px'}
                  data={tripListDetails}
                  columns={columns}
                  options={{
                    rowsPerPage: 100,
                    setRowProps: (row, index) => {
                      return index === id ? { style: { backgroundColor: '#FFF5CB', cursor: 'pointer', width: '100%' } } : null
                    },
                    onRowClick: (data, data2) => {
                      const newSelectedRowData = tripListDetails.find(
                        (_info, index) => index === data2.dataIndex,
                      );
                      setId(data2.dataIndex);
                      setSelectedRowData(newSelectedRowData);
                    },
                  }}
                  pointer={true}
                  tableName="drivers-monitoring-tripList"
                />}
              </Grid>
            </Widget>
            {/* botoes */}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box marginTop={size.mobile ? "25px" : 0}>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.buttonBack}
                      data-cy="buttonVoltar"
                      onClick={() => {
                        dispatch(setStepDeduct());
                        history.goBack();
                      }}
                    >
                      Voltar
                    </Button>
                  </Box>
                </Grid>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box >
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={handleClickDetail}
                      disabled={!selectedRowData}
                      className={classes.buttonDetail}
                      data-cy="buttonVerDetalhes"
                    >
                      Ver detalhes
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Aux>
  )
}

export default DriversMonitoringTripList
