import React from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";

import { optionsClusterGroup } from "./variables";

import "./clusterGroup.css";

const ClusterGroup = ({
  width = 100,
  height = 100,
  blue = 10,
  green = 53,
  yellow = 90,
  gray = 2,
  white = 100,
  red = 34,
  onClick = () => {},
}) => {
  const series = [blue,green,yellow,gray,white,red];

  return (
    <div className={"ClusterGroup-container"}>
      <ReactApexChart
        width={width}
        height={height}
        options={optionsClusterGroup}
        series={series}
        onClick={onClick}
        type="donut"
      />
    </div>
  );
};

export default ClusterGroup;

ClusterGroup.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  blue: PropTypes.number,
  green: PropTypes.number,
  yellow: PropTypes.number,
  gray: PropTypes.number,
  white: PropTypes.number,
  red: PropTypes.number,
  onClick: () => {},
};
