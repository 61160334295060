import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  card: {
    borderRadius: 28,
    height: '125px', 
    display: 'flex',
  },
  itemTitleSpacer: {
    position: 'relative',
    top: '-60px',
    textAlign: 'center',
    lineHeight: '16.8px',
    fontSize: '14px',
    marginTop: 16, 
    maxWidth: 116, 
  },
  cardBigger: {
    borderRadius: 28,
    maxHeight: '156px', 
    display: 'flex',
  },
  itemTitle: {
    position: 'relative',
    textAlign: 'center',
    lineHeight: '16.8px',
    fontSize: '14px',
    maxWidth: 116, 
    top: '-48px',
  }
}));
