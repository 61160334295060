export const timeConvert = (min) => {
  if (Math.sign(min) === -1 || isNaN(min)) {
    return '00:00:00';
  }
  var mins_num = parseFloat(min, 10); // don't forget the second param
  var hours = Math.floor(mins_num / 60);
  var minutes = Math.floor((mins_num - ((hours * 3600)) / 60));
  var seconds = Math.floor((mins_num * 60) - (hours * 3600) - (minutes * 60));

  // Appends 0 when unit is less than 10
  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes + ':' + seconds;
};

export const valueToPositiveFloat = (value, fixed) => {
  if (!value || Math.sign(value) === -1 || isNaN(value)) {
    return 0;
  }
  value = value.toFixed(fixed ?? 0);
  return parseFloat(value);
};

export function formatIdToFiveCharMinimum(id) {
  let idString = id.toString();
  let lengthId = idString.length;
  if (lengthId < 5) {
    idString = idString.padStart(5, '0');
  }
  return idString;
}

export const valueToFloat = (value, fixed) => {
  if (isNaN(value)) {
    return 0;
  }
  value = value.toFixed(fixed ?? 0);
  return parseFloat(value);
};

export const formatDate = (date) => {
  const splitedDate = date.split("/");
  const formatedDate = `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`;
  return formatedDate;
};

export const orderByName = (data) => {
  if (!data) {
    return;
  }
  return data.sort(function (a, b) {
    if (a.label > b.label) {
      return 1;
    }
    if (a.label < b.label) {
      return -1;
    }
    return 0;
  });
};

export const findObjectByKey = (array, value) => {
  for (var i = 0; i < array.length; i++) {
    if (array[i].value === value || array[i].label === value) {
      return array[i];
    }
  }
  return null;
};

//Função vai ser responsável por calcular a quantidade de estrelas que deve ser exibida.
//REGRA: CADA COMPONENTE DEVE TER 5 ESTRELAS. CADA ESTRELA EQUIVALE A 20PTS. 
export const makeValueStars = (score) => {
  let fixScore = +score;
  if (+score > 100) {
    fixScore = 100
  }
  const points = fixScore / 20
  return +points.toFixed(2)
}

//Verifica se um objeto é vazio
export function objIsEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export const getNextObjKey = (obj, currentKey, next) => {
  return Object.keys(obj)[Object.keys(obj).indexOf(currentKey) + next];
};

export const removeSpecialCharactersNumberMask = (e) => {
  if (e.key === "e" || e.key === "-" || e.key === "+") {
    e.preventDefault();
  }
};

export const clearDuplicated = (list) => {
  const unique = [];
  list.filter(o => {
    if (unique.find(i => i.id === o.id)) {
      return true
    }
    unique.push(o);
    return false;
  });
  return unique;
};

export const downloadFile = (base64, format, fileName) => {
  const linkSource = `data:application/${format};base64,${base64}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const getBase64 = file => {
  return new Promise(resolve => {
    let fileInfo;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');
      resolve(base64String);
    };
    return fileInfo;
  });
};

export const getRewardGroupSelecteds = (allRewardGroupTypeOptions, rewardGroupTypesById) => {
  let values = [];
  if (allRewardGroupTypeOptions?.length > 0 && rewardGroupTypesById?.length > 0) {
    rewardGroupTypesById.forEach((opType) => {
      let foundValue = allRewardGroupTypeOptions.find(
        (opTypeOption) => opTypeOption.value === opType.id,
      );
      values.push(foundValue);
    });
  }
  return values;
};

export const periodTypeOptions = [
  { label: 'Mesmo mês', value: 0 },
  { label: 'Mês seguinte', value: 1 }
];

export const getIdListUrl = (listArray = "") => {
  let urlList = "";
  if (listArray) {
    urlList = Array.isArray(listArray) ? listArray.join() : listArray;
  }
  return urlList;
};

export const orderArrayOfObjectsByDate = (array, index) => {
  return array.sort((a, b) => Date.parse(b[index]) - Date.parse(a[index]))
};

export const removeSpecialCharactersAndSpace = (string) => {
  return string.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').toLowerCase();
};

export const verifyDateInRange = (date, rangeStartDate, rangeEndDate) => {

  const startDate = new Date(rangeStartDate);
  const endDate = new Date(rangeEndDate);
  const dateVerified = new Date(date);

  return dateVerified >= startDate && dateVerified <= endDate;
};

export const verifyDatesInRange = (initialDate, finalDate, rangeStartDate, rangeEndDate) => {

  const rangeStartDateFormated = new Date(rangeStartDate);
  const rangeEndDateFormated = new Date(rangeEndDate);
  const initialDateFormated = new Date(initialDate);
  const finalDateFormated = new Date(finalDate);

  if (initialDateFormated <= rangeStartDateFormated && finalDateFormated >= rangeEndDateFormated) {
    return true;
  }

  if (initialDateFormated >= rangeStartDateFormated && initialDateFormated <= rangeEndDateFormated) {
    return true;
  }

  if (finalDateFormated >= rangeStartDateFormated && finalDateFormated <= rangeEndDateFormated) {
    return true;
  }

  return false;

};

export const clearText = (text) => {
  return text.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "");
}

export const convertStringDate = (startDate, endDate) => {
  if (!/[+-]\d{4}$/.test(startDate)) {
    startDate = startDate + '-0300'
  }
  if (!/[+-]\d{4}$/.test(endDate)) {
    endDate = endDate + '-0300'
  }
  startDate = startDate.replace(/[+-]\d{4}$/, '-0300')
  startDate = startDate.replace(/Z[+-]\d{4}$/, '-0300')
  endDate = endDate.replace(/[+-]\d{4}$/, '-0300')
  endDate = endDate.replace(/Z[+-]\d{4}$/, '-0300')
  return { startDate, endDate }
}

export const getCurrentMonth = (finishing_day) => {
  const today = new Date();
  const currentDay = today.getDate();
  const currentMonth = today.getMonth();
  const selectedMonth = currentDay > finishing_day && currentMonth < 12 ? currentMonth + 1 : currentMonth;
  return selectedMonth;
}

export const valueToStringFloat = (value, fixed) => {
  if (!value || Math.sign(value) === -1 || isNaN(value)) {
    return 0;
  }
  return value.toFixed(fixed ?? 2);
};

export const findValueByKey = (obj, key, shouldReturnFullObj = false, currentDepth = 0) => {
  const MAX_DEPTH = 5;

  if (!obj || currentDepth > MAX_DEPTH) return undefined;

  if (key in obj) {
    return shouldReturnFullObj ? obj : obj[key];
  }

  for (let value of Object.values(obj).filter(v => v && typeof v === 'object')) {
    const found = findValueByKey(value, key, shouldReturnFullObj, currentDepth + 1);
    if (found) return found;
  }
  return undefined;
};