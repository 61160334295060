import React from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";

import "./lottie.css";

const LottieFile = ({
  animationData,
  width,
  height,
  loop = true,
  autoplay = true,
  text,
}) => {
  const defaultOptions = {
    loop,
    autoplay,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className={"LottieFile-container"}>
      <Lottie
        options={defaultOptions}
        width={width || 80}
        height={height || 80}
        controls={false}
        isClickToPauseDisabled={true}
      />
      {text && <span className={"LottieFile-text"}>{text}</span>}
    </div>
  );
};

export default LottieFile;

LottieFile.propTypes = {
  /**
   * Lottie data
   */
  animationData: PropTypes.any,
  /**
   * Texto de apoio
   */
  text: PropTypes.string,
  /**
   * Largura
   */
  width: PropTypes.number,
  /**
   * Altura
   */
  height: PropTypes.number,
  /**
   * Repetição
   */
  loop: PropTypes.bool,
  /**
   * Inicia sozinho
   */
  autoplay: PropTypes.bool,
};
