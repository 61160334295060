import { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';

// store
import { useAppDispatch, useAppSelector } from "store";
import { setGlobalUser } from "store/globalSlice";

// services
import { getUserByEmail } from "services/user";
import { getUserPermissions } from "services/roles";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const user = useAppSelector(state => state.global.user);
  const isAuthenticated = !!user.token;

  useEffect(() => {
    const unlisten = history.listen(async (location, action) => {
      const response = await fetch(`${process.env.REACT_APP_LINK_API_LOGIN}/sessions/whoami`, {
        credentials: "include",
      });

      const authenticatedSession = await response.json();

      if(authenticatedSession.error) {
        history.push("/login");
      }
    });

    return () => unlisten();
  }, []);

  useEffect(() => {
    const authenticateUser = async () => {
      const response = await fetch(`${process.env.REACT_APP_LINK_API_LOGIN}/sessions/whoami`, {
        credentials: "include",
      });

      const authenticatedSession = await response.json();

      if(authenticatedSession.error) {
        history.push("/login");
        return false;
      }

      const encodedAuthenticatedSession = Buffer.from(JSON.stringify(authenticatedSession)).toString("base64");
      const authenticatedSessionUserName = authenticatedSession.identity.traits.name?.first;
      const authenticatedSessionUserEmail = authenticatedSession.identity.traits.email;

      dispatch(setGlobalUser({ token: encodedAuthenticatedSession }));

      const userResponse = await getUserByEmail(authenticatedSessionUserEmail);

      if (userResponse.status === 200 && userResponse?.data) {
        const userId = userResponse.data.data.userid;
        const credentials = userResponse.data.data?.token || '';

        localStorage.setItem("userId", userId);

        dispatch(setGlobalUser({ userId, credentials }));
      };

      const permissionsResponse = await getUserPermissions(authenticatedSessionUserEmail);

      if (permissionsResponse.status === 200 && permissionsResponse.data && permissionsResponse.data.data) {
        const loginRedirect = permissionsResponse.data.data[0].login_redirect || "";

        const permissions = permissionsResponse.data.data;
        const encodedPermissions = Buffer.from(JSON.stringify(permissions)).toString("base64");

        localStorage.setItem("login_redirect", loginRedirect);
        localStorage.setItem("roles", encodedPermissions);

        dispatch(setGlobalUser({ roles: encodedPermissions }));
      };

      const globalUser = {
        name: authenticatedSessionUserName,
        email: authenticatedSessionUserEmail,
        token: encodedAuthenticatedSession
      };

      dispatch(setGlobalUser(globalUser));
    };

    authenticateUser();
  }, []);

  return (
    <Route {...rest} render={(props) => (
      isAuthenticated ? <Component {...props} /> : <></>
    )} />
  );
}