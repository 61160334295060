import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Grid } from "@mui/material";

import { trunkSVG } from "./variables";

import { colorMaps } from "componentsNew/atoms/clusterSingle/clusterSingle";

import "./filterStatus.css";

const FilterStatus = ({ props = [
  {
    icon: 1,
    iconColor: "blue",
    label: "Motor ligado parado",
    total: 4,
    checkbox: true,
    defaultChecked: true,
    onClick: () => {},
  },
  {
    icon: 1,
    iconColor: "green",
    label: "Risco de pane seca",
    total: 6,
    checkbox: true,
    onClick: () => {},
  },
  {
    icon: 1,
    iconColor: "yellow",
    label: "Risco de pane seca",
    total: 10,
    checkbox: true,
    onClick: () => {},
  },
] }) => {
  return (
    <div className={"FilterStatus-container"}>
      <Grid className={"FilterStatus-grid-container"}>
        {props.map((op, i) => (
          <Grid
            key={op.label + op.i}
            className={"FilterStatus-item-container"}
          >
            {/* checkbox */}
            {op.checkbox && (
              <Checkbox
                color="default"
                className={"FilterStatus-item-checkbox"}
                onChange={op.onClick}
                defaultChecked={op.defaultChecked}
              />
            )}
            {/* icon */}
            {op.icon && (
              <span
                className={"FilterStatus-item-icon"}
                style={{backgroundColor: colorMaps[op.iconColor]}}
              >
                {trunkSVG}
              </span>
            )}
            {/* label */}
            <span className={"FilterStatus-item-typography"}>
              {op.total ? `(${op.total}) ${op.label}` : `(0) ${op.label}`}
            </span>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default FilterStatus;

FilterStatus.propTypes = {
  /**
   * Arranjo com todos os itens que deseja
   */
  props: PropTypes.array,
};