import React from "react";
import PropTypes from "prop-types";
import { OverlayViewF, OverlayView } from "@react-google-maps/api";

import { getPixelPositionOffset } from "../../../helpers/getPixelPosition";

import "./marker.css";

function Marker({ children = null, position = { lat: 0, lng: 0 } }) {
  return (
    <OverlayViewF
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      {children}
    </OverlayViewF>
  );
}

export default Marker;

Marker.propTypes = {
  /**
   * Posição no mapa (lat,lng)
   */
  position: PropTypes.object,
  /**
   * Componente filho
   */
  children: PropTypes.any,
};