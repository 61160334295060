import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import Icon from '@mdi/react';
import { mdiMagnify, mdiBellBadgeOutline, mdiChartDonut, mdiNewspaperVariantOutline } from '@mdi/js';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Box, Button, Grid, Typography, Input, Paper } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EventIcon from '@mui/icons-material/TodayOutlined';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/pt-br';

import { daysOfWeek, monthNames } from "constants/calendar";

// store
import { useAppSelector } from 'store';

// hoc
import Aux from 'hoc/auxiliar';

// services
import { getFilterNotification, getNotificationsManager } from 'services/notifications';

// components
import PermissionsGate from 'components/PermissionsGate';
import { calendarLanguage } from 'components/Calendar/calendarLanguage';
import NotificationCard from './components/notification-card';
import MultiSelectFilters from './components/multi-selector-filter';

import { statusFilterHelper, typeFilterHelper, channelFilterHelper } from './options'

// styles
import useStyles from './styles'

dayjs.extend(customParseFormat);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);

export default function NotificationHistory() {
  const classes = useStyles();
  const history = useHistory();
  const { email } = useAppSelector((state) => state.global.user);

  dayjs.updateLocale('pt-br', calendarLanguage);
  dayjs.locale('pt-br');

  const formatDate = 'DD/MM/YYYY HH:mm';
  const futureDate = new Date();

  const [dates, setDates] = useState({
    initialDate: dayjs().subtract(1, 'month').format('DD/MM/YYYY 00:00'),
    finalDate: dayjs().format('DD/MM/YYYY 23:59')
  });
  
  const [notificationsToShow, setNotificationsToShow] = useState([]);
  const [filters, setFilters] = useState({
    status: [],
    notificationType: [],
    platforms: [],
    words: '',
  });

  futureDate.setMonth(futureDate.getMonth() + 1);

  const searchDebounced = useDebouncedCallback(async ({ filterBy = '', users = '', startDate = '', endDate = '', status = '', platforms = '', notificationType = '', words = '' }) => {
    const model = {
      filterBy,
      users,
      startDate,
      endDate,
      status,
      platforms,
      notificationType,
      words
    };
    
    const response = await getFilterNotification(model);

    if (response.data?.data) {
      const orderList = response.data.data.sort((a, b) => {
        return new Date(b.publish_at) - new Date(a.publish_at);
      })
      setNotificationsToShow(orderList);
    }
  }, 1000);

  // notifications services/utils
  const getNotifications = async (email) => {
    try {
      const response = await getNotificationsManager(email);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  const handleNotifications = async () => {
    try {
      const response = await getNotifications(email);
      if (response.status === 200) {
        setNotificationsToShow(response.data.data);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSelectedData = async (data, prefix) => {
    let filtersToUpdate = {};

    if (!data.length) {
      filtersToUpdate[prefix] = [];
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...filtersToUpdate,
      }));


      const parseInitialDate = dayjs(dates.initialDate, formatDate).toDate();
      const parseFinalDate = dayjs(dates.finalDate, 'DD/MM/YYYY HH:mm').toDate();

      const { startDate } = formatDates(parseInitialDate, 'startDate');
      const { endDate } = formatDates(parseFinalDate, 'endDate');

      searchDebounced({ ...filters, ...filtersToUpdate, startDate, endDate });
      return;
    }

    data.forEach((item) => {
      const { id } = item;

      if (!filtersToUpdate[prefix]) {
        filtersToUpdate[prefix] = [];
      }

      filtersToUpdate[prefix].push(id);
    });

    setFilters((prevFilters) => ({
      ...prevFilters,
      ...filtersToUpdate,
    }));

    const ids = data.map((item) => item.id);

    const parseInitialDate = dayjs(dates.initialDate, formatDate).toDate();
    const parseFinalDate = dayjs(dates.finalDate, 'DD/MM/YYYY HH:mm').toDate();

    const { startDate } = formatDates(parseInitialDate, 'startDate');
    const { endDate } = formatDates(parseFinalDate, 'endDate');

    searchDebounced({ ...filters, ...filtersToUpdate, ids, startDate, endDate });
  };

  const formatDates = (date, prefix) => {
    return {
      [prefix]: dayjs(date).format("YYYY-MM-DDTHH:mm:ss-0300")
    }
  }

  const handleSelectedDate = (initialDate, finalDate) => {
    setDates({
      initialDate: dayjs(initialDate).format(formatDate),
      finalDate: dayjs(finalDate).format('DD/MM/YYYY HH:mm'),
    });

    const { startDate } = formatDates(initialDate, 'startDate');
    const { endDate } = formatDates(finalDate, 'endDate');

    searchDebounced({ ...filters, startDate, endDate });
  };

  useEffect(() => {
    const parseInitialDate = dayjs(dates.initialDate, 'DD/MM/YYYY HH:mm').toDate();
    const parseFinalDate = dayjs(dates.finalDate, 'DD/MM/YYYY HH:mm').toDate();

    const { startDate } = formatDates(parseInitialDate, 'startDate');
    const { endDate } = formatDates(parseFinalDate, 'endDate');

    searchDebounced({ ...filters, startDate, endDate });
  }, []);

  const searchWord = (words) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      words,
    }));

    const parseInitialDate = dayjs(dates.initialDate, formatDate).toDate();
    const parseFinalDate = dayjs(dates.finalDate, 'DD/MM/YYYY HH:mm').toDate();

    const { startDate } = formatDates(parseInitialDate, 'startDate');
    const { endDate } = formatDates(parseFinalDate, 'endDate');

    searchDebounced({ ...filters, words, startDate, endDate });
  }

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_notifications']}>
        <form>
          <Grid container item xs={12} xl={12} style={{ flexDirection: 'column', marginBottom: 24, paddingTop: 16 }}>
            <Grid container item style={{ justifyContent: 'space-between' }}>
              <Grid container item xs={8} xl={8} style={{ flexDirection: 'column' }}>
                <Typography variant="h3" className={classes.title}>Histórico de notificações</Typography>
                <Typography className={classes.subTitle}>Crie, edite e gerencie as notificações enviadas para os usuários da plataforma.</Typography>
              </Grid>
              <PermissionsGate scopes={['can_create_notification']}>
                <Box>
                  <Button
                    variant='contained'
                    className={classes.buttonNew}
                    onClick={() => {
                      history.push("/gofurther/managenotification")
                    }}
                  >
                    Nova notificação
                  </Button>
                </Box>
              </PermissionsGate>
            </Grid>
            <Grid container item xs={12} xl={12} spacing={1} style={{ justifyContent: 'space-between', marginBottom: 24 }}>
              <Grid container item xl={3} lg={3} md={3} sm={6} xs={12} className={classes.boxInput}>
                <DateRangePicker
                  initialSettings={{
                    startDate: dates.initialDate,
                    endDate: dates.finalDate,
                    maxSpan: { days: 30 },
                    locale: {
                      format: "DD/MM/YYYY HH:mm:ss",
                      daysOfWeek,
                      monthNames,
                      applyLabel: "Confirmar",
                      cancelLabel: "Cancelar",
                      customRangeLabel: "Personalizado",
                    },
                    ranges: {
                      'Hoje': [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()],
                      'Ontem': [dayjs().subtract(1, 'days').startOf('day').toDate(), dayjs().subtract(1, 'days').endOf('day').toDate()],
                      '7 Dias': [dayjs().subtract(6, 'days').startOf('day').toDate(), dayjs().endOf('day').toDate()],
                      '1 Mês': [dayjs().subtract(29, 'days').startOf('day').toDate(), dayjs().endOf('day').toDate()],
                    },
                    alwaysShowCalendars: true,
                    showCustomRangeLabel: true,
                    opens: "right",
                  }}
                  placeholderText="Período"
                  onApply={(_, picker) => {
                    handleSelectedDate(picker.startDate.toDate(), picker.endDate.toDate());
                  }}
                  autoApply={true}
                >
                  <Grid className={classes.divInput}>
                    <EventIcon className={classes.calendarIcon} />
                    <Input data-cy="inputCalendar"
                      value={`${dates.initialDate} - ${dates.finalDate}`}
                      onChange={(e, picker) => {
                        handleSelectedDate(picker.startDate._d, picker.endDate._d);
                      }}
                      className={classes.inputCalendar}
                    />
                    <Grid className={classes.arrowIcon}>
                      <hr />
                      <KeyboardArrowDownIcon />
                    </Grid>
                  </Grid>
                </DateRangePicker>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12} className={classes.boxInput}>
                <MultiSelectFilters
                  isSingleMode={false}
                  listData={statusFilterHelper}
                  handleSelectedListData={(selected) => handleSelectedData(selected, 'status')}
                  icon={mdiChartDonut}
                  placeholder={"Status"}
                  prefix={"status"}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12} className={classes.boxInput}>
                <MultiSelectFilters
                  isSingleMode={false}
                  listData={typeFilterHelper}
                  handleSelectedListData={(selected) => handleSelectedData(selected, 'notificationType')}
                  icon={mdiBellBadgeOutline}
                  placeholder={"Tipo"}
                  prefix={"notificationType"}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12} className={classes.boxInput}>
                <MultiSelectFilters
                  isSingleMode={false}
                  listData={channelFilterHelper}
                  handleSelectedListData={(selected) => handleSelectedData(selected, 'platforms')}
                  icon={mdiNewspaperVariantOutline}
                  placeholder={"Canais"}
                  prefix={"platforms"}
                />
              </Grid>
            </Grid>
            <Paper elevation={0} className={classes.searchBox}>
              <Grid container style={{ justifyContent: 'space-around', alignItems: 'center', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                <Icon path={mdiMagnify} size={1} />
                <Input
                  fullWidth
                  style={{ marginLeft: 4 }}
                  type='text'
                  placeholder='Pesquise pelo título...'
                  disableUnderline
                  onChange={(e) => searchWord(e.target.value)}
                />
              </Grid>
            </Paper>
          </Grid>
        </form>
        {notificationsToShow.length > 0 &&
          <Grid container item xs={12} xl={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', gap: 4 }}>
            {notificationsToShow.map((notification) => (
              <NotificationCard
                getNotifications={handleNotifications}
                id={notification.id}
                key={notification.id}
                type={notification.type}
                title={notification.title}
                subtitle={notification.subtitle}
                body={notification.body}
                publish_at={notification.publish_at}
                fromGobrax={notification.from_gobrax}
                allNotifications={notificationsToShow}
                created_at={notification.created_at}
                created_by={notification.user}
                username={notification.username}
                updated_at={notification.updated_at}
                status={notification.status}
                statusType={notification.status_type}
                platform={notification.platform}
                has_history={notification.has_history}
              />
            ))}
          </Grid>}
        {!notificationsToShow.length > 0 &&
          <Grid style={{ paDDingTop: 104, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box style={{ maxWidth: 518 }}>
              <Typography style={{
                fontSize: 32, fontWeight: 500, color: '#595959', textAlign: 'center', marginBottom: 16
              }}>
                Não há notificações para mostrar
              </Typography>
              <Typography style={{
                fontSize: 18, textAlign: 'center', marginBottom: 32
              }}>
                Nenhuma notificação para mostrar no momento, verifique os filtros ou crie uma notificação.
              </Typography>
              <PermissionsGate scopes={['can_create_notification']}>
                <Button
                  variant='outlined'
                  className={classes.btCreateNotification}
                  onClick={() => history.push('/gofurther/managenotification')}
                >
                  Criar notificação
                </Button>
              </PermissionsGate>
            </Box>
          </Grid>
        }
      </PermissionsGate>
    </Aux>
  )
}
