import { TypeStatus } from "helpers/statusVehicle";
import { normalizeData } from "helpers/fleetChart";

const formatDataForLiveMapDetails = (vehicleHistory) => {
  const hasVehicleHistory = vehicleHistory?.length > 0;

  const points = vehicleHistory?.map((vehicle, i) => {
    const location = vehicle.point;
    return location?.lat !== 0 && location?.lon !== 0 && vehicle?.date !== "0001-01-01T00:00:00Z" ? {
      date: vehicle?.date,
      color: TypeStatus(vehicle?.status),
      title: vehicle?.plate ?? `Sem identificação ${i}`,
      lng: location?.lon,
      lat: location?.lat,
      uuid: vehicle?.vehicleId,
      time: vehicle?.date,
    } : null;
  }).filter(point => point !== null);

  const map = normalizeData(points);

  const locationCenter = hasVehicleHistory ? {
    lat: vehicleHistory[0].point?.lat,
    lng: vehicleHistory[0].point?.lon
  } : null;

  return {
    map,
    locationInfo: locationCenter
  };
};

export default formatDataForLiveMapDetails;
