import React from "react";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

// components
import Layout from "components/Layout";
import { PrivateRoute } from 'components/PrivateRoute';

// pages
import Error from "pages/error";
import Login from "pages/login";
import DashboardPrint from "pages/dashboard/print";
import PassRecovery from "pages/autoService/passRecovery/passRecovery";

// styles
import 'react-quill/dist/quill.snow.css';
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./custom.css";

export default function App() {
  return (
    <Router>
      <ToastContainer theme="colored" />
      <Switch>
        <Route exact path="/">
          <Redirect to="gofurther/bonus/follow" />
        </Route>
        <Route exact path="/login" component={Login} />
        <PrivateRoute
          exact
          path="/autoservice/passrecovery"
          component={PassRecovery}
        />
        <Route path="/gofurther" component={Layout} />
        <PrivateRoute
          exact
          path="/dashboard/print"
          component={DashboardPrint}
        />
        <Route path="/fleet">
          <Redirect to="/" />
        </Route>
        <PrivateRoute component={Error} />
      </Switch>
    </Router>
  );
}
