import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  textBold: {
    fontWeight: 700
  },
  success: {
    color: theme.palette.success.main
  },
  danger: {
    color: theme.palette.error.main
  },
  btAction: {
    textTransform: "none",
    color: theme.palette.primary.main
  },
}));
