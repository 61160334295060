import { useEffect, useState, useCallback } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { Skeleton } from "@material-ui/lab";

import { getNameIndicator } from 'helpers/indicators';
import useStyles from './styles';
import colors from '../../../../themes/gobrax';
import PerformanceIndicator from '../PerformanceIndicator';
import HalfMoonChart from '../HalfMoonChart';

const ConsolidatedIndicators = ({ indicators, smaller, loading, generalScore }) => {
  const classes = useStyles();

  const [data, setData] = useState([]);

  const getColor = (value) => {
    let color = '';
    if (value <= 40) {
      color = colors.palette.error.main;
    } else if (value <= 80) {
      color = colors.palette.warning.main;
    } else {
      color = colors.palette.success.main;
    }
    return color;
  };

  const ordernateIndicators = (indicatorList) => {
    let ordenateArray = [];
    const ordenateIndicators = [
      "Início faixa verde",
      "Aproveitamento de embalo",
      "Pressão do acelerador",
      "Motor ligado parado",
      "Acelerando acima do verde",
      "Freio motor",
      "Piloto automático",
      "Excesso de velocidade",
    ];

    for (const label of ordenateIndicators) {
      for (const indicator of indicatorList) {
        if (indicator.label === label || (label === "Pressão do acelerador" && indicator.key === "throttlePressure") || (label === "Excesso de velocidade" && indicator.key === 'defensiveDriving')) {
          indicator.label = label;
          ordenateArray.push(indicator);
        }
      }
    }
    return ordenateArray;
  };

  const handleData = useCallback(() => {
    const formatedData = Object.keys(indicators).map(key => {
      return {
        key: key,
        label: getNameIndicator(key),
        color: getColor(indicators[key]?.score?.score),
        value: indicators[key]?.score?.score,
        evaluated: indicators[key]?.score?.isEvaluated
      };
    });

    const orderedList = ordernateIndicators(formatedData);

    setData(orderedList.filter(item => item?.evaluated ));
  }, [indicators]);

  useEffect(() => {
    if (indicators && !loading) {
      handleData();
    }
  }, [handleData, indicators, loading]);

  return (
    <Box 
      display="flex" 
      flexWrap="wrap" 
      justifyContent="space-evenly"  
      alignItems="flex-start"
      style={{ width: '100%' }}
    >
      <Box mb={2} style={{ flexBasis: `calc(100% / ${data.length ? data.length + 1 : 9} - 16px)` }}> 
        {loading ? (
          <Skeleton variant="rect" width="100%" height={156} style={{ borderRadius: 28 }} />
        ) : (
          <PerformanceIndicator data={{ globalScore: generalScore }} />
        )}
      </Box>
      
      {(data.length > 0 && indicators) && !loading ? (
        data.map((item, index) => {
          return (
            <Box 
              key={item.key} 
              mb={2} 
              style={{ flexBasis: `calc(100% / ${data.length + 1 || 9} - 16px)` }} 
            >
              <Paper 
                elevation={1} 
                className={smaller ? classes.cardBigger : classes.card}
                style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  borderRadius: 28,
                  boxSizing: 'border-box',
                  width: '100%', 
                  height: 156,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  paddingTop: 36
                }}
              >
                <Box 
                  display="flex" 
                  flexDirection="column" 
                  alignItems="center" 
                  justifyContent="space-between"
                  style={{ height: '100%', width: '100%' }} 
                >
                  <HalfMoonChart data={item} />
                  <Typography 
                    variant='h6' 
                    className={item.value ? classes.itemTitleSpacer : classes.itemTitle} 
                  >
                    {item.label}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          );
        })
      ) : loading ? (
        Array.from(new Array(8)).map((_, index) => (
          <Box 
            key={index} 
            mb={2} 
            style={{ flexBasis: `calc(100% / ${data.length || 9} - 16px)` }} 
          >
            <Skeleton variant="rect" width="100%" height={156} style={{ borderRadius: 28 }} />
          </Box>
        )) 
      ) : null}
    </Box>
  );
}

export default ConsolidatedIndicators;
