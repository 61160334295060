import React, { useEffect, useState } from "react"
import { Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import dayjs from "dayjs";

// services
import { getVehicleHistory } from "services/vehicle";
import { getCustomers } from "services/customers"
import { getOperations } from "services/operations";

// helpers
import { findValueByKey } from "helpers/functions";

// components
import Aux from "hoc/auxiliar";
import BackButton from "components/BackButton";
import HistoricVehiclesSkeleton from "components/Skeletons/HistoricVehicleSkeleton";
import TableResponsive from "components/TableResponsive/Table";
import PermissionsGate, { hasPermission } from "components/PermissionsGate";
import Widget from "components/Widget/Widget";
import { makeColumns } from "./tableColumns";

// hooks
import { useWindowSize } from "hooks/useWindowsSize";

import { odomTypeOptions } from "domain/selectOptions";

// styles
import useStyles from "./styles";


const HistoricVehicles = () => {
  const { vehicleId } = useParams()
  const classes = useStyles()
  const size = useWindowSize()
  const columns = makeColumns({size});
  const [ groupHistories, setGroupHistories ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ customers, setCustomers ] = useState(null);
  const [ operations, setOperations ] = useState(null);



  const fetchCustomers = async () => {
    try {
      const responseCustomers = await getCustomers();
      if (responseCustomers.status !== 200) {
        throw new Error(responseCustomers);
      }
      const dataCustomers = findValueByKey(responseCustomers, 'customers');
      setCustomers(dataCustomers);
    } catch (error) {
      toast.error(
        "Erro ao carregar lista de clientes. Entre em contato com o suporte!",
      );
    }
  };

  
  const fetchOperations = async (customerIds) => {
    try {
      const responseOperations = await getOperations(customerIds);
      if (responseOperations.status !== 200) {
        throw new Error(responseOperations);
      }
      const dataOperations = findValueByKey(responseOperations.data.data.operation, 'operations');
      setOperations(responseOperations.data.data.operation, dataOperations);
    } catch (error) {
      toast.error(
        "Erro ao carregar lista de operações. Entre em contato com o suporte!",
      );
    }
  };
  
  const fetchVehicleHistory = async () => {
    try {
      setLoading(true);
      const response = await getVehicleHistory(vehicleId);
      if (response.status !== 200) {
        throw new Error(response);
      }
      const vehiclesHistories = response.data?.data?.vehiclesHistories;
      const vehicleLabel = {
        fuelType: 'Tipo de Combustível: ',
        timeRangeLimit: 'Limite de Tempo:',
        plate: 'Placa:',
        breakdownRiskLimit: 'Limite de Risco de Quebra:',
        maxFuelCapacity: 'Limite Tanque:',
        maxAcceleration: 'Aceleração máx.:',
        identification: 'Frota:',
        odomType:'Tipo do odometro:',
        maxSpeedAllowed: 'Velocidade máx. permitida com chuva: ',
        maxSpeedAllowedWithRain:'Velocidade máx. permitida sem chuva:',
        leveragePeak: 'Pico de aprov. de Embalo:',
        observation:'Observação:',
        motor:'Motorização:',
        truckModel:'Modelo do caminhão:',
        fabricationYear:'Ano de Fabricação:',
        distanceCalcMethod:' Cálculo de distância:',
        fuelCalcMethod:'Cálculo de consumo:',
        accelerationIdentificationMethod:'Cálculo de aceleração:',
        model: 'Modelo:',
        brand:'Marca:',
        initialKM: 'Km Inicial:',
        wheelDriveType: 'Tração da roda:',
        chassi: 'Chassi:',
        operationName: 'Grupo de veículos:',
        distanceCalcMethod: 'Cálculo de distância por:',
        fuelCalcMethod:'Cálculo de Consumo por:',
        accelerationIdentificationMethod: 'Cálculo de Aceleração por:',
        hasDafEcoRoll: 'DAF Eco-Roll:',
        operation_name:'Grupo de veículo:'
      };
      
      const getOdomTypeLabel = (value) => {
        const option = odomTypeOptions.find(option => option.value === value);
        return option ? option.label : value;
      };

      const booleanToYesNo = (value) => {
        return value === true ? 'Sim' : value === false ? 'Não' : value;
      };

      const onMapHistoriesData = vehiclesHistories?.map((history) => {
        if (history?.historyType === 'CREATION') {
          return {
            modification_type: 'Criação de veículo',
            modificationData: '',
            user: `${history?.execName} ${history?.execEmail ? `- ${history?.execEmail}` : ''}`,
            datetime: dayjs(history.createdAt).format('DD/MM/YYYY HH:mm'),
          };
        }
      
        return history?.changes?.filter(change => change.from !== change.to).map((change) => {
          const excludedItems = ["modelId", "brandId", "fuelTypeId", "wheelDriveTypeId","operationId"];
          if (excludedItems.includes(change.field)) {
            return null;
          }
      
          change.from = change.from === "" ? " - " : change.from;
          change.to = change.to === "" ? " - " : change.to;
          change.from = change.from === 0 ? "0" : change.from;
          change.to = change.to === 0 ? "0" : change.to;
          change.from = booleanToYesNo(change.from);
          change.to = booleanToYesNo(change.to);
          
          if (change.field === "odomType") {
            return {
              modification_type: 'Tipo do odômetro modificado',
              modification: `${getOdomTypeLabel(change.from)} | Alterado para: ${getOdomTypeLabel(change.to)}`,
              user: `${history?.execName} ${history?.execEmail ? `- ${history?.execEmail}` : ''}`,
              datetime: dayjs(history.createdAt).format('DD/MM/YYYY HH:mm'),
            };
          }

          if (change.field === "customerId") {
            const fromCustomer = customers.find(item => item.id === change.from);
            const toCustomer = customers.find(item => item.id === change.to);
            return {
              modification_type: 'Cliente modificado',
              modification: `${fromCustomer?.name || ''} | Alterado para: ${toCustomer?.name || ''}`,
              user: `${history?.execName} ${history?.execEmail ? `- ${history?.execEmail}` : ''}`,
              datetime: dayjs(history.createdAt).format('DD/MM/YYYY HH:mm'),
            };
          }

          return {
            modification_type: 'Veículo modificado',
            modification: `${vehicleLabel[change.field] || ''} ${change?.from || ''} | Alterado para: ${change?.to || ''}`,
            user: `${history?.execName} ${history?.execEmail ? `- ${history?.execEmail}` : ''}`,
            datetime: dayjs(history.createdAt).format('DD/MM/YYYY HH:mm'),
          };
        });
      }).flat().filter(item => item);
      
      setGroupHistories(onMapHistoriesData);
      

    } catch (err) {
      console.log('error', err)
      toast.error(
        "Erro ao carregar histórico."
      );
    } finally {
      setLoading(false);
    }
  };  
 
  
  useEffect(() => {
    if (vehicleId && hasPermission({ scopes: ['can_view_history_vehicle'] })) {
      fetchCustomers();
    }
  }, [vehicleId]);

  useEffect(() => {
    if (customers) {
      const customersIds = customers.map(item => item.id);
      fetchOperations(customersIds);
    }
  }, [customers]);

  useEffect(() => {
    if (operations) {
      fetchVehicleHistory();
    }
  }, [operations]);

  

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_history_vehicle']}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.tableContent}>
          <Widget disableWidgetMenu title="Histórico de edição de veículos">
              {loading ? (
                // <FormRoleSkeleton />
                <HistoricVehiclesSkeleton />
              ) : (
                <TableResponsive
                  columns={columns}
                  data={groupHistories}
                  tableName="historic-vehicles"
                />
              )}
            </Widget>
            <Grid container item justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <Box sx={{ m: 2 }}>
                  <BackButton className={classes.backButton} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  )
}

export default HistoricVehicles