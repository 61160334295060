import * as yup from "yup";

const operationValidation = yup.object().shape({
  maxAmount: yup
    .string()
    .max(14, "Limite máximo excedido. 14 caracteres.")
    .required("Campo obrigatório"),
  name: yup
    .string()
    .max(50, "Máximo de 50 caracteres excedido.")
    .required("Campo obrigatório"),
  operationType: yup
    .number()
    .typeError('Campo obrigatório')
    .nullable()
    .required("Campo obrigatório"),
  requirementLevel: yup
    .number()
    .typeError('Campo obrigatório')
    .nullable()
    .required("Campo obrigatório"),
  targetMileage: yup
    .string()
    .max(14, "Kilometragem máxima excedida.")
    .required("Campo obrigatório"),

  rewardFormat: yup.string().required("Campo obrigatório"),

  minProportionalScore: yup
    .string()
    .when('rewardFormat', {
      is: 'scoreMediumReward',
      then: yup.string().required('Campo obrigatório para scoreMediumReward').max(14, "Limite máximo excedido."),
      otherwise: yup.string().notRequired()
    }),

  minScore: yup
    .string()
    .when('rewardFormat', {
      is: 'scoreMinimunReward',
      then: yup.string().required('Campo obrigatório para scoreMinimunReward').max(14, "Limite máximo excedido."),
      otherwise: yup.string().notRequired()
    }),

  rewardRange: yup
    .array()
    .of(
      yup.object().shape({
        range: yup
          .string()
          .matches(/^\d+-\d+$/, "A faixa deve conter dois números separados por um hífen (Ex: 0-69).")
          .required("A faixa é obrigatória."),
        reward: yup
          .number()
          .typeError("O valor deve ser numérico.")
          .positive("O valor deve ser positivo.")
          .required("O valor é obrigatório."),
      })
    )
    .when('rewardFormat', {
      is: 'range',
      then: yup.array().min(1, "Deve haver pelo menos uma faixa de premiação").required('Deve haver pelo menos uma faixa de premiação'),
      otherwise: yup.array().notRequired(),
    }),
});

export default operationValidation;
