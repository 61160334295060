import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    textBold: {
        fontWeight: "bold",
        fontSize: 24,
        color: 'black',
        marginBottom: theme.spacing(1),
        lineHeight: 1,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
    },
    dialogBase: {
        overflow: 'clip',
        borderRadius: theme.spacing(4),
        height: 488,
        [theme.breakpoints.down('sm')]: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            bottom: 0,
            position: 'absolute',
            height: '100%',
        },
    },
    description: {
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            fontSize: 16,
        },
    },
    closeButton: {
        color: theme.palette.grey[500],
    },
    contentBox: {
        overflow: 'clip',
        height: '100%',
        padding: theme.spacing(1.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(4),
        },
    },
    upperButtons: {
        padding: theme.spacing(0.5),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    backButton: {
        textTransform: 'none',
        fontSize: 18,
        color: 'black',
        marginTop: theme.spacing(0.5),
    },
    option: {
        marginBottom: theme.spacing(1),
        fontSize: 16,
    },
    optionTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: theme.spacing(0.5),
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    actionButtons: {
        display: 'flex',
        width: '100%',
        gap: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: theme.spacing(4),
        },
    },
    recalcOptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    recalcTextContainer: {
        marginBottom: 'auto',
    },
    actionButton: {
        borderRadius: 32,
        color: 'black',
        textTransform: 'none',
        fontWeight: 'medium',
        fontSize: 18,
    },
}));
