import { useState, useEffect } from "react";
import { store } from "store";

const URL = process.env.REACT_APP_LINK_WSS + "/web/v2/location/ws/group/vehicle?refresh=5&session_id=";

export const useWebSocketLocationGroupVehicleIdOnly = (id) => {
  const [endpoint, setEndpoint] = useState("");
  const [fullData, setFullData] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [currentSocket, setCurrentSocket] = useState(null);
  const { token } = store.getState().global.user;
  const { vehicleHistory } = store.getState().liveMap;

  const closeConnection = () => {
    currentSocket.close(1000, 'Fechando conexão normalmente')
  }

  //carregar o sessionID
  useEffect(() => {
    setEndpoint(URL + token);
  }, [token, id]);

  //Chamar WS sempre atualizando a lista
  useEffect(() => {
    if (!!token && !!endpoint && refresh && vehicleHistory?.length > 0) {
      const websocketVehicle = new WebSocket(endpoint + "&vehicle_ids=" + id.toString());
      websocketVehicle.onmessage = (event) => {
        const response = JSON.parse(event.data);
        !currentSocket && setCurrentSocket(websocketVehicle);
        if (response?.data) {
          const newLastUpdate = new Date(response?.data?.[0]?.location?.Date);

          setFullData(prevFullData => {
            const currentLastUpdate = prevFullData?.[0]?.location?.Date ? new Date(prevFullData[0].location.Date) : null;
            if (!currentLastUpdate || currentLastUpdate < newLastUpdate) {
              return response?.data;
            }
            return prevFullData;
          });
        }
      };
      websocketVehicle.onerror = (error) => {
        setRefresh(false)
        setTimeout(() => {
          setRefresh(true)
        }, 2000)
      };
      return () => {
        websocketVehicle.close(1000, 'Fechando conexão normalmente');
        websocketVehicle.onmessage = null;
      };
    }
  }, [refresh, endpoint, vehicleHistory]);

  return {
    closeConnection,
    fullData,
  };
};