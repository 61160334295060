import React from "react";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Grid, Button, Box, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

// domain
import fields from "domain/forms/addDriverInitialValues";
import schemaValidation from "domain/validation/yup/addDriverValidation";

// services
import { createDriver } from "services/driver";

// helpers
import { errorsMessages } from "helpers/errors";
import makeDefaultValues from "helpers/makeDefaultValues";
import { onlyNumberMask, removeAllSpecialCharacters } from "helpers/masks";

// models
import DriverModel from "models/Driver";

// components
import Aux from "hoc/auxiliar";
import DriverForm from "components/DriverForm";
import BackButton from "components/BackButton";
import PermissionsGate from "components/PermissionsGate";
import HelpButton from "components/HelpButton";

// styles
import useStyles from "./styles";

const AddDriver = () => {
  const defaultValues = makeDefaultValues({ fields });
  const classes = useStyles();
  const history = useHistory();

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });

  const onSubmit = async (data) => {
    try {
      const cpfNumbers = removeAllSpecialCharacters(data?.documentNumber);

      const driverModel = new DriverModel({
        birthDate: data.birthDate || null,
        customerId: data.customer.value,
        documentNumber: onlyNumberMask(data.documentNumber),
        email: data.email || `${cpfNumbers}@gobrax.com.br`,
        name: data.name.trim(),
        note: data.obs || null,
        phoneNumber: onlyNumberMask(data.phone) || null,
        role: data?.role?.value
      });

      delete driverModel.id;

      const response = await createDriver(driverModel);

      if (response.status !== 200) {
        toast.error("Erro ao criar motorista!");
      } else if (response.data.response && response.data.response[0].errors) {
        response.data.response[0].errors.forEach((error) => {
          methods.setError(errorsMessages[error.field].type, {
            type: "custom",
            message: errorsMessages[error.field].message,
          });
        });
      } else {
        toast.success("Motorista criado com sucesso!");
        methods.reset(defaultValues);
        history.push("/gofurther/driver/all-drivers");
      }
    } catch (err) {
      toast.error("Error ao criar motorista. Entre em contato com o suporte.");
    }
  };

  return (
    <Aux>
      <PermissionsGate scopes={["can_create_drivers"]}>
        <Grid container>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className={classes.titleBox}>
                <Typography variant="h1" className={classes.title}>
                  Cadastro de motorista
                </Typography>
                <HelpButton />
              </div>
              <DriverForm />
              <Grid item display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent="flex-end" gap={2} mt={4}>
                <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                  <BackButton className={classes.backButton} />
                </Grid>
                <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    className={classes.btConfirm}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
};

export default AddDriver;
