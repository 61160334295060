import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, Polyline, useJsApiLoader } from "@react-google-maps/api";
import PropTypes from "prop-types";
import Lottie from "componentsNew/atoms/lottie/lottie";
import ClusterSingleDetail from "componentsNew/atoms/clusterSingleDetail/clusterSingleDetail";
import Marker from "componentsNew/atoms/marker/marker";
import { useWindowSize } from "hooks/useWindowsSize";
import LoadingLottie from "assets/lottieFiles/mapLoading.json";
import "./mapDetails.css";

const MapDetails = ({
  initZoom,
  initLocationCenter,
  points = [],
  loading,
  height,
  width,
}) => {
  //Carregando a API do mapa
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyApnbkideYXxPEwwRnWPEZoIciQynUABJI",
    language: "pt-BR",
  });
  const [userMove, setUserMove] = useState(null);
  const [line, setLine] = useState([]);
  const size = useWindowSize();
  const mapRef = useRef();

  useEffect(()=>{
    if (points?.length > 0) {
      setLine([])
      setTimeout(() => {
        const lineData = points.map(item => {
          return {
            lat: item.lat,
            lng: item.lng
          }
        })
        setLine(lineData)
      }, 1);
    }
  },[points])

  return (
    <div className={"MapDetails-container"}>
      {!loading && isLoaded ? (
        <div
          className={"MapDetails-container-item"}
          style={{
            height: height || "50vh",
            width: width || "auto",
          }}
        >
          <GoogleMap
            zoom={initZoom}
            onLoad={(map)=>{
              mapRef.current = map
            }}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            center={userMove ?? initLocationCenter}
            onDrag={()=>{
              if(userMove === null){
                setUserMove({lat:mapRef.current.center.lat(),lng:mapRef.current.center.lng()})}
              }
            }
            onMouseOut={()=>{setUserMove(null)}}
          >
            {/* ponto atual */}
            {points && points.length > 1 && (
              <Marker position={{ lat: points[0].lat, lng: points[0].lng }}>
                <ClusterSingleDetail
                  key={points[0].title + "initRouter"}
                  title={points[0].title + "(Ponto inicial)"}
                  color={points[0].color}
                  uuid={1}
                  noClick={true}
                />
              </Marker>
            )}
            {/* rota */}
            <Polyline
              path={line}
              geodesic={true}
              options={{
                strokeColor: "#000",
                strokeOpacity: 0.75,
                strokeWeight: 4,
              }}
            />
            {/* ponto inicial */}
            {points && points.length > 0 && (
              <Marker
                position={{
                  lat: points[points.length - 1].lat,
                  lng: points[points.length - 1].lng,
                }}
              >
                <ClusterSingleDetail
                  key={points[points.length - 1].title + "actualRouter"}
                  title={points[points.length - 1].title + "(Ponto atual)"}
                  color={points[points.length - 1].color}
                  uuid={2}
                  noClick={true}
                />
              </Marker>
            )}
          </GoogleMap>
        </div>
      ) : (
        <Lottie
          animationData={LoadingLottie}
          height={size.mobile ? 80 : 300}
          width={size.mobile ? 80 : 300}
        />
      )}
    </div>
  );
};

export default MapDetails;