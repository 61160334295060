import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { 
  Autocomplete,
  Input,
  Box,
  Button,
  DialogContent,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  TextField
} from "@mui/material";
import { Close as CloseIcon, CalendarToday as CalendarIcon } from "@mui/icons-material";
import { useDebouncedCallback } from "use-debounce";

import { daysOfWeek, monthNames } from "constants/calendar";

//services
import { LinkDeviceToVehicle, unLinkDevice } from "services/device";

// helpers
import makeDefaultValues from "helpers/makeDefaultValues";

import fields from "domain/forms/formLinkDeviceInitialvalues";

// components
import { hasPermission } from "components/PermissionsGate";

// styles
import useStyles from "./styles";

const StyledAutocomplete = styled(Autocomplete)(() => ({
  '.MuiInputBase-root': {
    borderRadius: 20,
  }
}));

const LinkDeviceToVehicleModal = ({
  isUnlink,
  deviceId,
  handleLinkDevices,
  fetchDevices,
  searchDevices,
  vehicleId,
  devices,
  handleClose,
  open,
  loadingDevice,
}) => {
  const classes = useStyles();
  const [isConflict, setIsConflict] = useState(null)
  const [msgConflict, setMsgConflict] = useState(null)
  const defaultValues = makeDefaultValues({ fields });
  const methods = useForm({
    defaultValues: { ...defaultValues, date: dayjs().toDate() },
  });

  const formatDate = 'DD/MM/YYYY HH:mm';

  const [dates, setDates] = useState({
    initialDate: dayjs().format(formatDate),
  });

  const handleSearchDevice = useDebouncedCallback((value) => {
    if (value.length >= 8 && !loadingDevice) {
      searchDevices(value);
    }
  }, 1000);

  const handleConfirmConflict = async () => {
    try {
      const responseBody = {
        deviceId: isConflict,
        accepted: true
      }
      const response = await LinkDeviceToVehicle(vehicleId, responseBody);
      if (response.status === 200 || response.status === 201) {
        toast.success("Vínculo realizado com sucesso!");
        setIsConflict(null)
        setMsgConflict(null)
        handleClose()
        handleLinkDevices()
      }
    } catch (error) {
      toast.error("Algo inexperado aconteceu, contate o suporte!");
    }
  }

  const handleUnlinkDevice = async () => {

    try {
      const response = await unLinkDevice(vehicleId, deviceId);
      if (response.status === 200 || response.status === 201) {
        toast.success("Vínculo realizado com sucesso!");
        setIsConflict(null)
        setMsgConflict(null)
        handleClose()
        handleLinkDevices()
      }
    } catch (error) {
      toast.error("Algo inexperado aconteceu, contate o suporte!");
    }
  }

  const handleInitialDate = (startDate) => {
    const finalDateFormatted = dayjs(dates.finalDate, 'DD/MM/YYYY HH:mm').toDate();

    if (startDate) {
      if (startDate > finalDateFormatted) {
        setDates({
          finalDate: dayjs(startDate).format(formatDate),
          initialDate: dayjs(startDate).format(formatDate)
        });
      } else {
        setDates({
          ...dates,
          initialDate: dayjs(startDate).format(formatDate)
        });
      }
    }
  };

  const onSubmit = async ({ date, devices }) => {
    try {
      if (!devices) {
        return toast.warning("Selecione uma unidade eletrônica para vincular.");
      }

      const deviceId = {
        deviceId: devices.value,
        date: date,
        accepted: true
      }

      const response = await LinkDeviceToVehicle(vehicleId, deviceId);

      if (response.status === 200 || response.status === 201) {
        toast.success("Vínculo realizado com sucesso!");
        handleClose();
        handleLinkDevices()
      }
      if (response.status === 500) {
        setIsConflict(data.devices.value)
        setMsgConflict(response.data.error_description)
      } 
    } catch (error) {
      toast.error(
        "Não foi possível vincular unidade eletrônica. Contate o suporte",
      );
    }
  };

  const onClose = () => {
    handleClose()
    setIsConflict(null)
  }

  const renderLinkDevice = () => {

    if (!!isConflict) {
      return (
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.titleUnlink}>{msgConflict}</Typography>
            <DialogActions>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <Box sx={{ m: 2 }}>
                      <Button
                        fullWidth
                        onClick={onClose}
                        className={classes.buttonClose}
                      >
                        Cancelar
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <Box sx={{ m: 2 }}>
                      <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        className={classes.btConfirm}
                        onClick={() => handleConfirmConflict()}
                      >
                        Confirmar
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={2} mt={1}>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Controller
                      control={methods.control}
                      name="date"
                      render={({
                        field: { onChange },
                      }) => (
                        <>
                          <DateRangePicker
                            initialSettings={{
                              startDate: dayjs(),
                              singleDatePicker: true,
                              timePicker24Hour: true,
                              timePicker: true,
                              minDate: hasPermission({ scopes: ["can_retroactive_link"] }) ? false : dayjs(),
                              maxDate: dayjs(),
                              locale: {
                                format: 'DD/MM/YYYY HH:mm',
                                daysOfWeek,
                                monthNames,
                                applyLabel: 'Aplicar',
                                cancelLabel: 'Cancelar',
                              },
                              opens: 'center',
                            }}
                            autoApply={true}
                            onApply={(_, picker) => {
                              handleInitialDate(picker.startDate._d);
                              onChange(picker.startDate._d)
                            }}
                          >
                            <div className={classes.containerInputCalendar}>
                              <Input data-cy='inputCalendar'
                                className={classes.inputCalendar}
                                value={dates.initialDate}
                              />
                              <CalendarIcon
                                fontSize="medium"
                                data-cy="svgButtonCalendar"
                              />
                            </div>
                          </DateRangePicker>
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Controller control={methods.control} name="devices" render={({ field: { onChange, ...rest } }) => (
                      <StyledAutocomplete
                        {...rest}
                        options={devices}
                        onInputChange={(_, value, reason) => {
                          if(reason === 'input') {
                            handleSearchDevice(value);
                          }                          
                        }}
                        onChange={(_, value) => onChange(value)}
                        noOptionsText="Sem dados"
                        isOptionEqualToValue={(option, value) => option.value === value?.value || value === ''}
                        getOptionLabel={(option) => option?.label ?? ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={loadingDevice ? "Buscando unidades eletrônicas..." : "Buscar unidades eletrônicas"}
                          />
                        )}
                        clearOnEscape
                        clearOnBlur
                      />
                    )} />
                  </Grid>
                  <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} justifyContent="flex-end">
                    <Grid item container xl={6} lg={6} md={6} sm={6} xs={12} spacing={2}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Button variant="outlined" className={classes.btSearch} onClick={() => fetchDevices()} disabled={loadingDevice}>
                          Buscar todas
                        </Button>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.legend}>
                        <Typography variant="caption">* A busca será feita a partir de 8 caracteres.</Typography>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.legend}>
                        <Typography variant="caption">* Atenção! Buscar todas as unidades eletrônicas pode demorar.</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Box sx={{ m: 2 }}>
                          <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            className={classes.btnBack}
                            onClick={() => {
                              handleClose();
                              methods.reset()
                            }}
                          >
                            Voltar
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                        <Box sx={{ m: 2 }}>
                          <Button
                            fullWidth
                            variant="contained"
                            type="submit"
                            className={classes.btConfirm}
                          >
                            Vincular
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogActions>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      )
    }
  }

  const renderUnlinkDevice = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography className={classes.titleUnlink}>Deseja realmente desvincular a unidade eletrônica?</Typography>
          <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item container justifyContent="flex-end" xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Button
                      fullWidth
                      onClick={handleClose}
                      className={classes.backButton}
                    >
                      Voltar
                    </Button>
                  </Box>
                </Grid>
                <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                  <Box sx={{ m: 2 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      className={classes.btConfirm}
                      onClick={() => handleUnlinkDevice()}
                    >
                      Confirmar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      onClose={() => onClose()}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: { borderRadius: 22, maxHeight: 500 }
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={() => onClose()}>
        {isUnlink ? (
          <Typography className={classes.title}>Desvincular unidade eletrônica</Typography>
        ) : (
          <Typography className={classes.title}>Vincular unidade eletrônica</Typography>
        )}

        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => onClose()}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {!isUnlink &&
        <DialogContent className={classes.wrapperContent}>
          {renderLinkDevice()}
        </DialogContent >
      }
      {isUnlink && (
        <DialogContent>
          {renderUnlinkDevice()}
        </DialogContent >
      )}
    </Dialog >
  );
};

export default LinkDeviceToVehicleModal;
