import { useEffect, useState } from 'react';
import Radar from 'radar-sdk-js';
import polyline from '@mapbox/polyline';

import 'radar-sdk-js/dist/radar.css'

export const StaticMap2 = ({ routes, singlePoint = false }) => {
  const [points, setPoints] = useState([]);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if(!points.length || !markers.length) return;

    Radar.initialize('prj_test_pk_e52eeed607e048144ca60c1162a8822857ae3aeb');

    const map = Radar.ui.map({
      container: 'map',
      style: 'radar-default-v1',
      center: markers[0],
      zoom: 14,
    });

    markers.map((marker) => {
      Radar.ui.marker({ text: marker.label })
        .setLngLat([marker.lng, marker.lat])
        .addTo(map);
    });

    const pointsPolyline = polyline.encode(points.map(({ lat, lng }) => [lat, lng]), 6);
    map.addPolyline(pointsPolyline, { 
      id: 'polyline',
      precision: 6,
      properties: {
        name: 'Polyline Feature',
      },
      paint: {
        'line-color': 'blue'
      }
    });

    map.fitToFeatures({ padding: 40 });
  }, [points, markers]);

  useEffect(() => {
    if(routes.length) {
      const routesPoints = routes.reduce((acc, route) => [...acc, ...route.locations.map((location) => ({ lat: location.lat, lng: location.lon }))], []);

      const routesMarkers = routes.reduce((acc, route) => {
        // Gets only first and last array items
        const filteredLocations = route.locations.filter((_, index) => !index || index === route.locations.length - 1);

        const locations = filteredLocations.map(({ lat, lon }, index) => ({
          content: route.device,
          label: index ? 'B' : 'A',
          lat,
          lng: lon
        }));

        const result = singlePoint ? locations.slice(0, 1) : locations;

        return [...acc, ...result];
      }, []);

      setPoints(routesPoints);
      setMarkers(routesMarkers);
    }
  }, [routes]);

  return (points.length) ? (
    <div>
      <h2>
        <a href="https://radar.com/" target="_blank" rel="noreferrer">Radar</a>{' '}
        <span>(Free up to 100k montlhy api calls then $0.50 per 1k)</span>
      </h2>
      <div id="map-container">
        <div id="map" style={{ width: '100%', height: '400px' }} />
      </div>
    </div>
  ) : <></>;
}
