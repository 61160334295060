import { timeConvert } from "./functions";
import { indicatorsOptions, penalizingOptions } from "domain/selectOptions";

/*
Faixa extra econômica = Início faixa verde
Potência = Acelerando acima do verde,
Aproveitamento de embalo = Aproveitamento de embalo
Direção defensiva = Direção defensiva
Piloto automático =  Piloto automático
Motor ligado parado =  Motor Ligado Parado
Faixa verde = Total faixa verde
Faixa econômica = Final faixa verde
Freio motor = Freio motor
Eco-Roll/I-Roll = Eco-roll/I-roll
Em movimento(Tempo em movimento) */

export const getNameIndicator = (key) => {
  switch (key) {
    case "greenRange":
      return "Total faixa verde";

    case "extraEconomicRange":
      return "Início faixa verde";

    case "economicRange":
      return "Final faixa verde";

    case "leverage":
      return "Aproveitamento de embalo";

    case "ecoRoll":
      return "Eco-Roll/I-Roll";

    case "cruiseControl":
      return "Piloto automático";

    case "engineBrake":
      return "Freio motor";

    case "powerRange":
      return "Acelerando acima do verde";

    case "speeding":
      return "Excesso de velocidade";

    case "idle":
      return "Total motor ligado parado";

    case "lowIdle":
      return "Motor ligado parado";

    case "hightRpmIdle":
      return "Motor ligado parado em alta rotação";

    case "movement":
      return "Em movimento";

    case "throttlePressureScore":
      return "Pressão do acelerador";
    default:
      return "";
  }
};

export const getIndicatorDuration = (indicators, nameIndicator) => {
  if (indicators.length > 0) {
    let indicator = indicators.find(function (indicator) {
      return indicator.name === nameIndicator;
    });
    return timeConvert(indicator?.duration);
  }
  return null;
};

export const getIndicatorFilterType = (indicators) => {

  let highControll = [];
  let mediumControll = [];
  let lowControll = [];
  let penalizingHighControll = [];
  let penalizingMediumControll = [];
  let penalizingLowControll = [];
  let penalizingCustomControll = [];
  let customControll = [];
  indicators.forEach((ind) => {
    let id = ind.indicator_id;
    let conduction = !ind.penalizing
      ? indicatorsOptions.find((indOpt) => indOpt.value === ind.indicator_id)
      : penalizingOptions.find((indOpt) => indOpt.value === ind.indicator_id);
    let acceptablePercentage = ind.lower_limit;
    let idealPercentage = ind.upper_limit;
    if (ind.requirement_level === "high" && !ind.penalizing) {
      highControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "high" && ind.penalizing) {
      penalizingHighControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "medium" && !ind.penalizing) {
      mediumControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "medium" && ind.penalizing) {
      penalizingMediumControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "low" && !ind.penalizing) {
      lowControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "low" && ind.penalizing) {
      penalizingLowControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else if (ind.requirement_level === "custom" && !ind.penalizing) {
      customControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    } else {
      penalizingCustomControll.push({
        ...ind,
        id,
        conduction,
        idealPercentage,
        acceptablePercentage,
      });
    }
  });
  return {
    highControll,
    mediumControll,
    lowControll,
    penalizingHighControll,
    penalizingMediumControll,
    penalizingLowControll,
    customControll,
    penalizingCustomControll,
  };
};

